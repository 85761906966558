// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

// :::::::::  :::    :::  :::::::: ::::::::::: ::::    ::: :::::::::: ::::::::   ::::::::
// :+:    :+: :+:    :+: :+:    :+:    :+:     :+:+:   :+: :+:       :+:    :+: :+:    :+:
// +:+    +:+ +:+    +:+ +:+           +:+     :+:+:+  +:+ +:+       +:+        +:+
// +#++:++#+  +#+    +:+ +#++:++#++    +#+     +#+ +:+ +#+ +#++:++#  +#++:++#++ +#++:++#++
// +#+    +#+ +#+    +#+        +#+    +#+     +#+  +#+#+# +#+              +#+        +#+
// #+#    #+# #+#    #+# #+#    #+#    #+#     #+#   #+#+# #+#       #+#    #+# #+#    #+#
// #########   ########   ######## ########### ###    #### ########## ########   ########




.business_top {
    .category {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        .cat_name {
            flex-shrink: 1;
            width: 290px;
        }//.cat_name
        .list {
            width: 825px;
            .item {
                margin-bottom: 24px;
                padding-bottom: 24px;
                border-bottom: solid 1px #f2f3f8;

                a {
                    display: flex;
                    flex-direction: row;
                    flex-flow: row;
                    color: #202020;
                    text-decoration: none;
                    &:hover {
                        opacity: .6;
                    }
                }// a
                .body {
                    flex-shrink: 1;
                }//.body
                .name {
                    margin-bottom: 25px;
                    font-weight: bold;
                    font-size: 24px;
                }//.name
                p {
                    line-height: 1.9;
                    font-size: 16px;
                }
                .image {
                    margin-left: 60px;
                    flex-shrink: 1;
                }//.image
            }//.item
        }//.list
    }//.category
}//.business_top
