// :::::::::  :::::::::: ::::::::  :::::::::: :::::::::::
// :+:    :+: :+:       :+:    :+: :+:            :+:
// +:+    +:+ +:+       +:+        +:+            +:+
// +#++:++#:  +#++:++#  +#++:++#++ +#++:++#       +#+
// +#+    +#+ +#+              +#+ +#+            +#+
// #+#    #+# #+#       #+#    #+# #+#            #+#
// ###    ### ########## ########  ##########     ###
// reset
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-style: normal;
    font-size: 100%;
    outline: 0;
    outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ul,
nav ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after {
    content: '';
    content: none;
}

q:before,
q:after {
    content: '';
    content: none;
}

a {
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    background: transparent;
    font-size: 100%;
}

em {
    font-style: normal;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input,
select {
    vertical-align: middle;
    outline: none;
}

/* change colour to suit your needs */
ins {
    color: #000;
    background-color: #eee;
    text-decoration: none;
}

mark {
    color: #000;
    background-color: #eee;
    font-style: italic;
    font-weight: bold;
}

hr {
    margin: 1em 0;
    padding: 0;
    border: 0;
    border-top: 1px solid #ccc;
    display: block;
    height: 1px;
}

/* append */
img {
    vertical-align: middle;
}



/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/

#colorbox,
#cboxOverlay,
#cboxWrapper,
#cboxLoadingOverlay,
#cboxLoadingGraphic {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
}

#colorbox,
#cboxOverlay,
#cboxWrapper,
#cboxContent {
    z-index: 9999;
    //overflow: hidden;

    -webkit-transform: translate3d(0,0,0);
}
#cboxWrapper {
    max-width: none;
}

#cboxMiddleLeft,
#cboxBottomLeft {
    clear: left;
}
#cboxContent {
    //position: fixed;
}
#cboxLoadedContent {
    overflow: auto;

    -webkit-overflow-scrolling: touch;
}
#cboxTitle {
    margin: 0;
}
#cboxLoadingOverlay,
#cboxLoadingGraphic {
    //position: absolute;
}
#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
    cursor: pointer;
}
.cboxPhoto {
    margin: auto;
    border: 0;
    display: block;
    float: left;
    max-width: none;

    -ms-interpolation-mode: bicubic;
}
.cboxIframe {
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
    width: 100%;
    height: 100%;
}
#colorbox,
#cboxContent,
#cboxLoadedContent {
            box-sizing: content-box;

       -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.8);

    opacity: .9;
    filter: alpha(opacity = 90);
}
#colorbox {
    position: fixed;
    top: 0;
    left: 0;
    outline: 0;
}
#cboxTopLeft {
    width: 21px;
    height: 21px;
}
#cboxTopRight {
    width: 21px;
    height: 21px;
}
#cboxBottomLeft {
    width: 21px;
    height: 21px;
}
#cboxBottomRight {
    width: 21px;
    height: 21px;
}
#cboxMiddleLeft {
    width: 21px;
}
#cboxMiddleRight {
    width: 21px;
}
#cboxTopCenter {
    height: 21px;
}
#cboxBottomCenter {
    height: 21px;
}
#cboxContent {
    //overflow: hidden;
}

#cboxError {
    padding: 50px;
    border: 1px solid #ccc;
}
#cboxLoadedContent {
    margin-bottom: 28px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
#cboxTitle {
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 100%;
    color: #949494;
    text-align: center;
}
#cboxCurrent {
    position: absolute;
    bottom: 4px;
    left: 58px;
    color: #949494;
}
#cboxLoadingOverlay {
    // background: url(images/loading_background.png) no-repeat center center;
}
#cboxLoadingGraphic {
    // background: url(images/loading.gif) no-repeat center center;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
    margin: 0;
    padding: 0;
    border: 0;
    width: auto;
    overflow: visible;
    background: none;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
    outline: 0;
}

#cboxSlideshow {
    position: absolute;
    right: 30px;
    bottom: 4px;
    color: #0092ef;
}
// #cboxPrevious {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 25px;
//     height: 25px;
//     text-indent: -9999px;
// }
// #cboxPrevious:hover {
//     background-position: -75px -25px;
// }
// #cboxNext {
//     position: absolute;
//     bottom: 0;
//     left: 27px;
//     width: 25px;
//     height: 25px;
//     text-indent: -9999px;
// }
// #cboxNext:hover {
//     background-position: -50px -25px;
// }
// #cboxClose {
//     position: absolute;
//     right: 0;
//     bottom: 0;
//     width: 25px;
//     height: 25px;
//     text-indent: -9999px;
// }
// #cboxClose:hover {
//     background-position: -25px -25px;
// }

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
}



//  ::::::::  :::        ::::::::::     :::     :::::::::  :::::::::: ::::::::::: :::    :::
// :+:    :+: :+:        :+:          :+: :+:   :+:    :+: :+:            :+:     :+:    :+:
// +:+        +:+        +:+         +:+   +:+  +:+    +:+ +:+            +:+      +:+  +:+
// +#+        +#+        +#++:++#   +#++:++#++: +#++:++#:  :#::+::#       +#+       +#++:+
// +#+        +#+        +#+        +#+     +#+ +#+    +#+ +#+            +#+      +#+  +#+
// #+#    #+# #+#        #+#        #+#     #+# #+#    #+# #+#            #+#     #+#    #+#
//  ########  ########## ########## ###     ### ###    ### ###        ########### ###    ###
.cl {
    display: inline-block;
}

.cl:after {
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
    content: '.';
}

* html .cl {
    height: 1%;
}

.cl {
    display: block;
}



// EOF
