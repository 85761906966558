//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

//  ::::::::  :::::::::: :::::::::: ::::::::::: ::::::::  ::::::::::
// :+:    :+: :+:        :+:            :+:    :+:    :+: :+:
// +:+    +:+ +:+        +:+            +:+    +:+        +:+
// +#+    +:+ :#::+::#   :#::+::#       +#+    +#+        +#++:++#
// +#+    +#+ +#+        +#+            +#+    +#+        +#+
// #+#    #+# #+#        #+#            #+#    #+#    #+# #+#
//  ########  ###        ###        ########### ########  ##########




.mod_pickup {
    margin-bottom: 60px;
    overflow: hidden;
    .inner {
        margin: 0 20px;
        width: auto;
        //padding: 25px 0;
        //position: relative;
        &::after {
            //box-sizing: border-box;
            //border-left: solid 24px #003894;
            //display: block;
            //position: absolute;
            //bottom: 0;
            //left: 0;
            //width: 100%;
            //height: 1px;
            //content: '';
            //background: #ddd;
        }
    }
    .btn_office_list {
        //margin-top: 24px;
        a {
            //border-radius: 4px;
            //display: block;
            //line-height: 48px;
            //color: #fff;
            //background: #06173c;
            //font-weight: bold;
            //font-size: 14px;
            //text-align: center;
            //text-decoration: none;
        }
    }
}//.mod_pickup

.mod_office_concept {
    //box-sizing: border-box;
    margin-bottom: 60px;
    padding: 20px 0;
    border-right: 0;
    //background: linear-gradient(to left, #f7f7f7 0%, #f2f3f8 100%) !important;
    .inner {
        //display: flex;
        //flex-direction: row;
        //flex-wrap: wrap;
        //justify-content: space-between;
    }
    .map {
        margin-bottom: 24px;
        //flex-shrink: 1;
        width: 100%;
        img {
            width: 100%;
        }
    }
    .body {
        //flex-shrink: 1;
        width: 100%;
        .heading02 {
            margin-bottom: 25px;
        }
        .details {
            display: block;
            //flex-direction: row;
            //justify-content: space-between;
            .item {
                margin-bottom: 30px;
                padding-top: 16px;
                //position: relative;
                width: 100%;
                &::before {
                    //box-sizing: border-box;
                    //border-left: solid 24px #003894;
                    //display: block;
                    //position: absolute;
                    //top: 0;
                    //left: 0;
                    //width: 100%;
                    //height: 1px;
                    //content: '';
                    //background: #ddd;
                }
                p {
                    //font-size: 14px;
                    em {
                        margin-bottom: 15px;
                        //display: block;
                        //font-weight: bold;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}//.mod_office_concept

.estate-list.mod_building {
    margin-bottom: 60px;
    .heading02 {
        margin-bottom: 24px;
    }
    ul {
        li {
            margin-right: 5%;
            width: 47.5%;
            &:nth-child(5n) {
                margin-right: 5%;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        }// li
    }// ul
}//.estate-list.mod_overseas

.office_data {
    //margin-bottom: 60px;
    display: block;
    //flex-direction: row;
    //flex-wrap: wrap;
    //justify-content: space-between;
    .item {
        //padding-top: 15px;
        border-top: 0;
        width: 100%;
        .location,
        .station {
            //margin-bottom: 15px;
            //padding-left: 2em;
            //text-indent: -2em;
            font-size: 12px;
            img {
                //display: block;
            }
        }
    }//.item
    dl {
        dt {
            //color: #003894;
            //font-weight: normal;
            //font-size: 10px;
        }// dt
        dd {
            //margin-bottom: 15px;
            font-size: 12px;
        }// dd
    }// dl
}//.office_data

.vr_image {
    //margin: 0 auto 60px;
    width: 100%;
    height: 100vw;
    .ricoh-theta-tour-image,
    iframe {
        width: 100% !important;
        height: 100vw !important;
    }
}//.vr_image




//  ::::::::  ::::::::::     :::     :::::::::   ::::::::  :::    :::
// :+:    :+: :+:          :+: :+:   :+:    :+: :+:    :+: :+:    :+:
// +:+        +:+         +:+   +:+  +:+    +:+ +:+        +:+    +:+
// +#++:++#++ +#++:++#   +#++:++#++: +#++:++#:  +#+        +#++:++#++
//        +#+ +#+        +#+     +#+ +#+    +#+ +#+        +#+    +#+
// #+#    #+# #+#        #+#     #+# #+#    #+# #+#    #+# #+#    #+#
//  ########  ########## ###     ### ###    ###  ########  ###    ###
.search_lead {
    // box-sizing: border-box;
    margin: -40px auto 20px;
    padding: 20px;
    display: block;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    // background: #f2f3f8;
    p {
        margin-bottom: 10px;
        width: 100%;
        // font-weight: bold;
        font-size: 18px;
        text-align: center;
    }
    img {
        width: 100%;
    }
}//.search_lead
.search_localmenu {
    //margin-bottom: 80px;
    ul {
        //display: flex;
        //flex-direction: row;
        //flex-wrap: wrap;
        //justify-content: space-between;
        li {
            margin-bottom: 15px;
            width: 100%;
            height: 60px;
            line-height: 60px;
            //font-weight: bold;
            //font-size: 18px;
            a {
                //box-sizing: border-box;
                //padding: 0 25px;
                //border-radius: 4px;
                //display: block;
                //position: relative;
                //width: 100%;
                //height: 100%;
                //height: 100%;
                //color: #fff;
                //background: #06173c;
                //text-decoration: none;
                .mark_link {
                    //position: absolute;
                    top: 13px;
                    //right: 10px;
                }//.mark_link
            }// a
        }// li
    }// ul
}//.search_localmenu

.area_list {
    //padding: 24px 0 0;
    display: block;
    //position: relative;
    //justify-content: space-between;
    &::before {
        //box-sizing: border-box;
        //border-left: solid 24px #003894;
        //display: block;
        //position: absolute;
        //top: 0;
        //left: 0;
        //width: 100%;
        //height: 1px;
        //content: '';
        //background: #ddd;
    }
    .area_heading {
        margin-bottom: 20px;
        width: auto;
        //font-weight: bold;
        font-size: 22px;
    }
    .search_list {
        width: 100%;
        .item {
            padding-bottom: 40px;
            //margin-bottom: 24px;
            //padding-top: 24px;
            //border-top: solid 1px #b8bfce;
            display: block;
            position: relative;
            //justify-content: space-between;
            &:first-child {
                //padding-top: 0;
                //border: 0;
            }
            .image {
                width: 100%;
                img {
                    margin: 0 auto 15px;
                    display: block;
                    width: 60%;
                }
            }
            .body {
                width: 100%;
                .name {
                    //color: #003894;
                    //font-weight: bold;
                    font-size: 18px;
                }
                .lead {
                    //color: #003894;
                    font-size: 10px;
                }
                .data {
                    //padding-top: 20px;
                    //display: flex;
                    //justify-content: space-between;
                    dl {
                        width: 45%;
                    }
                    dt {
                        //color: #003894;
                        //font-size: 10px;
                    }
                    dd {
                        //margin-bottom: 10px;
                        font-size: 12px;
                    }
                }//.data
            }//.body
            .menu {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                ul {
                    //text-align: right;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    li {
                        margin-left: 5px;
                        //display: inline-block;

                        a {
                            //color: #003894;
                            //text-decoration: none;
                            &:hover {
                                //text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }//.item
    }//.search_list
}//.area_list
