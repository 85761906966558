// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

.organization_map {
    margin: 0 auto;
    width: 841px;
    height: 664px;
    background: center no-repeat url(../img/company/organization-organigram_img.png);
    ul {
        display: none;
    }
}//.organization_map
