/* =========================
  採用情報 詳細
========================= */
.recruit-detail {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    &-side {
        width: 248px;
    }
    &-main {
        width: 815px;
    }
    &-badge {
        padding: 4px 28px;
        border-radius: 4px;
        display: inline-block;
        color: #003894;
        background: #b1cfff;
        font-weight: bold;
        font-size: 14px;
        &.small {
            padding: 4px 34px;
            font-size: 10px;
        }
    }
    &-heading {
        margin: 20px 0 60px;
        font-size: 30px;
        font-size: bold;
    }
    &-heading02 {
        margin: 10px 0 0;
        line-height: 1.5;
        font-size: 16px;
        font-size: bold;
    }
    .tags {
        margin-bottom: 10px;
        li {
            margin-right: 2px;
            padding: 0 5px;
            border: solid 1px #f2f3f8;
            border-radius: 4px;
            display: inline-block;
            list-style: none;
            font-size: 10px;
            text-indent: 0;
            &::before {
                display: none;
            }
        }// li
    }//.tags
    h2 {
        margin: 60px 0 15px;
        font-size: 24px;
    }
    h3 {
        margin: 40px 0 15px;
        font-size: 18px;
    }
    p,
    ul,
    ol {
        line-height: 2.0;
        font-size: 16px;
    }
    ul {
        list-style: none;
        li {
            position: relative;
            list-style: none;
            /* padding-left: 1em; */
            text-indent: 1em;
            &:before {
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                top: .9em;
                left: .4em;
                width: 4px;
                height: 4px;
                content: '';
                background: #202020;
            }
        }
    }
    ol {
        list-style-position: inside;
    }

    table {
        margin-bottom: 20px;
        border-top: solid 1px #b8bfce;
        border-left: none;
        width: 100%;
        line-height: 2.0;
        table-layout: fixed;
    }

    th,
    td {
        padding: 15px 15px 15px 0;
        border-right: none;
        border-bottom: solid 1px #b8bfce;
        vertical-align: top;
        text-align: left;
    }

    th {
        width: 150px;
        font-weight: normal;
    }

    td {
        padding-right: 0;
    }
    &-btn {
        margin-top: 60px;
        text-align: center;
        a {
            padding: 16px 42px;
            border-radius: 4px;
            display: inline-block;
            color: #fff;
            background: #003894;
            font-weight: bold;
            font-size: 16px;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
            span {
                padding-left: 21px;
                display: inline-block;
                position: relative;
                &:after {
                    margin: 1px 0 0;
                    border-right: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 5px;
                    height: 5px;
                    content: '';
                    transform: translateY(-60%) rotate(-45deg);
                }
            }
        }
        &.small {
            margin-top: 25px;
            width: 100%;
        }
    }
    &-img-sp {
        display: none;
    }
    &-img-pc {
        margin-bottom: 40px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 153px;
        overflow: hidden;
        img {
            display: block;
            width: auto;
            height: 100%;
        }
    }
    &-fix {
        position: fixed;
        top: 315px;
        left: 0;
        z-index: 10;
        width: 100%;
        min-width: 1280px;
        &-wrap {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            width: 1120px;
        }
        &-inner {
            box-sizing: border-box;
            padding: 15px;
            border-radius: 4px;
            width: 249px;
            overflow: hidden;
            background: #f2f3f8;
        }
        &-link {
            display: none;
        }
    }
}
