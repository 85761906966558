//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

// :::::::::  :::    :::  :::::::: ::::::::::: ::::    ::: :::::::::: ::::::::   ::::::::
// :+:    :+: :+:    :+: :+:    :+:    :+:     :+:+:   :+: :+:       :+:    :+: :+:    :+:
// +:+    +:+ +:+    +:+ +:+           +:+     :+:+:+  +:+ +:+       +:+        +:+
// +#++:++#+  +#+    +:+ +#++:++#++    +#+     +#+ +:+ +#+ +#++:++#  +#++:++#++ +#++:++#++
// +#+    +#+ +#+    +#+        +#+    +#+     +#+  +#+#+# +#+              +#+        +#+
// #+#    #+# #+#    #+# #+#    #+#    #+#     #+#   #+#+# #+#       #+#    #+# #+#    #+#
// #########   ########   ######## ########### ###    #### ########## ########   ########




.business_top {
    .category {
        display: block;
        //flex-direction: row;
        //flex-wrap: wrap;
        //align-items: flex-start;
        .cat_name {
            margin-bottom: 27px;
            //flex-shrink: 1;
            width: 100%;
            br {
                display: none;
            }
        }//.cat_name
        .list {
            width: 100%;
            .item {
                //margin-bottom: 24px;
                //padding-bottom: 24px;
                //border-bottom: solid 1px #f2f3f8;

                a {
                    display: block;
                    //flex-direction: row;
                    //flex-flow: row;
                    //color: #202020;
                    //text-decoration: none;
                    &:hover {
                        //opacity: .6;
                    }
                }// a
                .body {
                    //flex-shrink: 1;
                }//.body
                .name {
                    margin-bottom: 8px;
                    //font-weight: bold;
                    font-size: 18px;
                }//.name
                p {
                    margin-bottom: 22px;
                    line-height: 1.7;
                    font-size: 14px;
                }
                .image {
                    margin-left: 0;
                    //flex-shrink: 1;
                    img {
                        width: 100%;
                    }
                }//.image
            }//.item
        }//.list
    }//.category
}//.business_top
