//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

// ::::    ::::  :::::::::: ::::::::: :::::::::::     ::: ::::::::::: ::::::::::: ::::::::  ::::    :::
// +:+:+: :+:+:+ :+:        :+:    :+:    :+:       :+: :+:   :+:         :+:    :+:    :+: :+:+:   :+:
// +:+ +:+:+ +:+ +:+        +:+    +:+    +:+      +:+   +:+  +:+         +:+    +:+    +:+ :+:+:+  +:+
// +#+  +:+  +#+ +#++:++#   +#+    +:+    +#+     +#++:++#++: +#+         +#+    +#+    +:+ +#+ +:+ +#+
// +#+       +#+ +#+        +#+    +#+    +#+     +#+     +#+ +#+         +#+    +#+    +#+ +#+  +#+#+#
// #+#       #+# #+#        #+#    #+#    #+#     #+#     #+# #+#         #+#    #+#    #+# #+#   #+#+#
// ###       ### ########## ######### ########### ###     ### ###     ########### ########  ###    ####




.mediation_lnav {
    margin-bottom: 60px;
    .lead {
        margin-bottom: 40px;
        line-height: 1.5;
        font-size: 14px;
        text-align: left;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            width: 24%;
            a {
                box-sizing: border-box;
                padding: 15px 0 60px 7px;
                border-radius: 4px;
                display: block;
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                color: #fff;
                background: #06173c;
                font-weight: bold;
                text-decoration: none;
                &:after {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 8px;
                    content: '';
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00012c+0,001697+36,0065d7+81,0060d1+90,4d54c2+100 */
                    background: #00012c; /* Old browsers */
                    background:    -moz-linear-gradient(left,  #00012c 0%, #001697 36%, #0065d7 81%, #0060d1 90%, #4d54c2 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left,  #00012c 0%,#001697 36%,#0065d7 81%,#0060d1 90%,#4d54c2 100%); /* Chrome10-25,Safari5.1-6 */
                    background:         linear-gradient(to right,  #00012c 0%,#001697 36%,#0065d7 81%,#0060d1 90%,#4d54c2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00012c', endColorstr='#4d54c2',GradientType=1 ); /* IE6-9 */
                }
                em {
                    display: block;
                    font-size: 16px;
                }
                small {
                    display: block;
                    color: #b8bfce;
                    font-family: $cgaramond;
                    font-size: 12px;
                }
                i.mark_link {
                    position: absolute;
                    bottom: 15px;
                    left: 50%;
                    transform: translateX(-50%);
                    &:after {
                        top: 11px;
                        left: 13px;
                        transform: rotate(45deg);
                    }
                }
            }// a
        }// li
    }//ul
}//.mediation_lnav

.mediation_sell {
    .sell_image {
        margin-bottom: 20px;
        img {
            margin: 0 auto;
            display: block;
            width: 100%;
        }
    }//.sell_image
}//.mediation_sell

.mediation_lease {
    .cat_label {
        margin-bottom: 10px;
        padding-left: 8px;
        border-left: solid 4px #003894;
        display: block;
        font-weight: normal;
        font-size: 12px;
    }
    .lease_info {
        box-sizing: border-box;
        margin-bottom: 40px;
        padding: 30px 0 0;
        border-top: solid 1px #b8bfce;
        border-bottom: solid 1px #b8bfce;
        .heading {
            h3 {
                margin-bottom: 20px;
                color: #003894;
                font-size: 24px;
            }
            .description {
                margin-bottom: 40px;
            }
        }//.heading
        //.body
        .lease_case_list {
            li {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                .image {
                    margin-right: 15px;
                    flex-shrink: 0;
                    width: 110px;
                    img {
                        width: 100%;
                    }
                }//.image
                .text {
                    width: calc(100vw - 165px);
                    em {
                        font-weight: bold;
                        font-size: 16px;
                    }
                }//.text
            }// li
        }//.lease_case_list
    }//.lease_info
    .review_list {
        margin-bottom: 40px;
        .item {
            box-sizing: border-box;
            margin-bottom: 25px;
            padding: 24px 20px;
            background-color: #f7f7f7;
            .cat_label {
                font-size: 14px;
            }
            em {
                margin-bottom: 10px;
                display: block;
                line-height: 1.3em;
                font-weight: bold;
                font-size: 18px;
            }
            .detail {
                font-weight: normal;
                font-size: 14px;
            }
        }
    }//.review_list
}//.mediation_lease

.mod_mediation {
    margin-bottom: 40px;
    .mediation_cat_head {
        margin-bottom: 40px;
    }//.mediation_cat_head
    .heading02 {
        margin-bottom: 25px;
        padding-left: 15px;
        small {
            margin-top: 3px;
            font-family: Noto Sans JP,Hiragino Kaku Gothic ProN,'ヒラギノ角ゴ ProN W3',Meiryo,'メイリオ','游ゴシック体',YuGothic,'游ゴシック',Yu Gothic,sans-serif,-apple-system;
        }
    }//.heading
    .note {
        color: #202020;
        font-weight: bold;
        font-size: 18px;
    }//.note
    .to_list {
        display: block;
        font-size: 16px;
        &.space-tl {
            margin: 25px 0 0 20px;
        }
        a {
            color: #003894;
            font-weight: bold;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}//.mod_mediation

.btn_cv_satei {
    p {
        margin-bottom: 24px;
        color: #003894;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
    }
    .btn_detail {
        margin: 0 auto;
        width: 100%;
        height: 72px;
        a {
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #fff;
            background: #003894;
            text-decoration: none;
        }
    }//.btn_detail
}//.btn_cv_satei

.contract_records {
    margin-bottom: -120px;
    padding: 60px 0;
    background: rgba(184,191,206,1);
    .inner {
        overflow: hidden;
    }
    .mediation_cat_head {
        .heading02 {
            border-left: solid 1px #fff;
            width: 100%;
            color: #fff;
            small {
                color: #fff;
            }
        }
        .note {
            font-size: 18px;
        }
    }
    .estate_slider {
        //padding-bottom: 70px;
        position: relative;
        overflow: visible;
        background: rgba(184,191,206,1);
        &:after {
            position: absolute;
            top: 0;
            right: -20px;
            z-index: 50;
            width: 20px;
            height: 100%;
            content: '';
            background: linear-gradient(to right, rgba(184,191,206,0) 0%,rgba(184,191,206,.5) 50%,rgba(184,191,206,1) 100%) !important;
        }
        .item {
            margin: 0 5px;
            padding: 0;
            figure {
                margin-bottom: 5px;
            }
            .item_title {
                em {
                    font-size: 14px;
                }
            }
        }
        .slick-prev {
            z-index: 50;
            background: linear-gradient(to left, rgba(184,191,206,0) 0%,rgba(184,191,206,1) 30%,rgba(184,191,206,1) 100%) !important;
        }
        .slick-next {
            z-index: 50;
            background: linear-gradient(to right, rgba(184,191,206,0) 0%,rgba(184,191,206,1) 30%,rgba(184,191,206,1) 100%) !important;
        }
    }//.estate_slider
}//.contract_records


.mediation {
    .description {
        font-size: 14px;
    }
    .slick-dots {
        //margin-left: 10%;
        li {
            margin: 0 2px;
            // width: 16px;
            button {
                // padding: 5px 0;
                // width: 16px;
                &:before {
                    // border-radius: 50%;
                    // position: absolute;
                    // top: 50%;
                    // left: 50%;
                    // width: 8px;
                    // height: 8px;
                    // background: #003894;
                    // transform: translateX(-50%) translateY(-50%);
                }
            }
            &.slick-active {
                button {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .js_slider {
        position: relative;
        &:after {
            position: absolute;
            top: 0;
            right: calc(100vw - 124vw);
            z-index: 50;
            width: 20px;
            height: 100%;
            content: '';
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,1) 100%) !important;
        }
        .slide_item {
            margin: 0 5px;
            border-radius: 0;
        }
        .photo {
            img {
                border-radius: 0;
            }
        }
        .detail {
            .name {
                em {
                    font-size: 16px;
                }
            }
            .data {
                dd {
                    font-size: 12px;
                }
            }
            .to_detail {
                font-size: 14px;
            }
        }
    }
    .estate_slider {
        .item {
            figure {
                img {
                    width: 100%;
                    height: 59vw;
                }
            }
        }
        .slick-list {
            // margin-right: -6px !important; /* ガター分ネガティブマージン */
            margin-left: -5px !important;
            // padding: 5px 0 8px !important;
        }
        .slick-dots {
            li {
                border-radius: 100%;
                width: 8px;
                height: 8px;
                background-color: #fff;
                &.slick-active {
                    background-color: #003894;
                }
            }
        }//.slick-dots
    }
}
