//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

.organization_map {
    padding-bottom: 79%;
    //margin: 0 auto;
    width: 100%;
    height: 0;
    //background: center no-repeat url(../img/company/organization-organigram_img.png);
    background-size: contain;
    ul {
        //display: none;
    }
}//.organization_map
