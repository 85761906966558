// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

//  ::::::::   ::::::::  ::::    ::::  ::::    ::::   ::::::::  ::::    :::
// :+:    :+: :+:    :+: +:+:+: :+:+:+ +:+:+: :+:+:+ :+:    :+: :+:+:   :+:
// +:+        +:+    +:+ +:+ +:+:+ +:+ +:+ +:+:+ +:+ +:+    +:+ :+:+:+  +:+
// +#+        +#+    +:+ +#+  +:+  +#+ +#+  +:+  +#+ +#+    +:+ +#+ +:+ +#+
// +#+        +#+    +#+ +#+       +#+ +#+       +#+ +#+    +#+ +#+  +#+#+#
// #+#    #+# #+#    #+# #+#       #+# #+#       #+# #+#    #+# #+#   #+#+#
//  ########   ########  ###       ### ###       ###  ########  ###    ####




// :::::::::      :::      ::::::::  ::::::::::
// :+:    :+:   :+: :+:   :+:    :+: :+:
// +:+    +:+  +:+   +:+  +:+        +:+
// +#++:++#+  +#++:++#++: +#++:++#++ +#++:++#
// +#+    +#+ +#+     +#+        +#+ +#+
// #+#    #+# #+#     #+# #+#    #+# #+#
// #########  ###     ###  ########  ##########
body {
    overflow-x: hidden;
}
.box {
    min-width: 1280px;
}
.inner {
    margin: 0 auto;
    position: relative;
    width: 1120px;
}//.inner
.mod_ofxh {
    overflow-x: hidden;
}//.mod_ofxh
a:hover {
    opacity: .9;
}



// :::    ::: ::::::::::     :::     ::::::::: ::::::::::: ::::    :::  ::::::::
// :+:    :+: :+:          :+: :+:   :+:    :+:    :+:     :+:+:   :+: :+:    :+:
// +:+    +:+ +:+         +:+   +:+  +:+    +:+    +:+     :+:+:+  +:+ +:+
// +#++:++#++ +#++:++#   +#++:++#++: +#+    +:+    +#+     +#+ +:+ +#+ :#:
// +#+    +#+ +#+        +#+     +#+ +#+    +#+    +#+     +#+  +#+#+# +#+   +#+#
// #+#    #+# #+#        #+#     #+# #+#    #+#    #+#     #+#   #+#+# #+#    #+#
// ###    ### ########## ###     ### ######### ########### ###    ####  ########
.heading01 {
    color: #fff;
    font-size: 36px;
    small {
        display: block;
        font-family: $cgaramond;
        font-size: 16px;
    }
}//.heading01
.heading02 {
    box-sizing: border-box;
    padding-left: 25px;
    border-left: solid 1px #003894;
    color: #003894;
    font-family: $cgaramond;
    font-weight: normal;
    font-size: 36px;
    small {
        display: block;
        color: #202020;
        font-size: 14px;
    }
}//.heading02
.heading03 {
    color: #202020;
    font-weight: bold;
    font-size: 24px;
    small {
        display: block;
        color: #b8bfce;
        font-family: $cgaramond;
        font-weight: normal;
        font-size: 16px;
    }
}//.heading03
.list_heading {
    margin-bottom: 25px;
    padding-bottom: 25px;
    position: relative;
    color: #003894;
    font-weight: bold;
    font-size: 24px;
    &::after {
        box-sizing: border-box;
        border-left: solid 24px #003894;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background: #ddd;
    }
}



// ::::::::::: ::::::::   ::::::::  ::::    :::  ::::::::
//     :+:    :+:    :+: :+:    :+: :+:+:   :+: :+:    :+:
//     +:+    +:+        +:+    +:+ :+:+:+  +:+ +:+
//     +#+    +#+        +#+    +:+ +#+ +:+ +#+ +#++:++#++
//     +#+    +#+        +#+    +#+ +#+  +#+#+#        +#+
//     #+#    #+#    #+# #+#    #+# #+#   #+#+# #+#    #+#
// ########### ########   ########  ###    ####  ########
.mark_link {
    margin-right: 8px;
    border: solid 1px #fff;
    border-radius: 32px;
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    background: transparent;
    &::after {
        border-right: solid 2px #fff;
        border-bottom: solid 2px #fff;
        display: block;
        position: absolute;
        top: 12.5px;
        left: 11px;
        width: 5px;
        height: 5px;
        content: '';
        transform: rotate(-45deg);
    }
    &.mod_blue {
        border: solid 1px #003894;
        &::after {
            border-right: solid 2px #003894;
            border-bottom: solid 2px #003894;
        }
    }//&.mod_blue
    &.mod_down {
        transform: rotate(90deg);
    }//&.mod_down
}//.mark_link

.mark_outbound {
    margin-left: 5px;
    display: inline-block;
    position: relative;
    width: 14px;
    height: 10px;
    &::before,
    &::after {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 12px;
        height: 8px;
        content: '';
    }
    &::before {
        border-bottom: solid 1px #003894;
        border-left: solid 1px #003894;
        bottom: 0;
        left: 0;
    }
    &::after {
        border: solid 1px #003894;
        top: 0;
        right: 0;
    }
    &.mod_white {
        &::before {
            border-bottom: solid 1px #fff;
            border-left: solid 1px #fff;
        }
        &::after {
            border: solid 1px #fff;
        }
    }
}//.mark_outbound

.mark_location {
    display: inline-block;
    width: 24px;
    height: 24px;
    img {
        height: 100%;

        filter: invert(8%) sepia(99%) saturate(7044%) hue-rotate(200deg) brightness(95%) contrast(130%);
    }
}//.mark_location
.mark_station {
    display: inline-block;
    width: 24px;
    height: 24px;
    img {
        height: 100%;

        filter: invert(8%) sepia(99%) saturate(7044%) hue-rotate(200deg) brightness(95%) contrast(130%);
    }
}//.mark_station
.mark_pdf {
    margin-right: 8px;
    display: inline-block;
    width: 24px;
    height: 32px;
    vertical-align: -33%;
    background: center no-repeat url(../img/common/icon-pdf.svg);

    filter: invert(8%) sepia(99%) saturate(7044%) hue-rotate(200deg) brightness(95%) contrast(130%);
}//.mark_pdf




// :::    ::: ::::::::::     :::     :::::::::  :::::::::: :::::::::
// :+:    :+: :+:          :+: :+:   :+:    :+: :+:        :+:    :+:
// +:+    +:+ +:+         +:+   +:+  +:+    +:+ +:+        +:+    +:+
// +#++:++#++ +#++:++#   +#++:++#++: +#+    +:+ +#++:++#   +#++:++#:
// +#+    +#+ +#+        +#+     +#+ +#+    +#+ +#+        +#+    +#+
// #+#    #+# #+#        #+#     #+# #+#    #+# #+#        #+#    #+#
// ###    ### ########## ###     ### #########  ########## ###    ###
.global_header {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    width: 100%;
    .header_inner {
        margin: 0 auto;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1120px;
    }
}//.global_header
.page-logo {
    padding: 50px 0 0 0;
    width: 128px;
    height: 50px;
    img {
        width: 100%;
        height: 100%;

        filter: invert(100%);
    }
}//.page-logo


.page-head {
    box-sizing: border-box;
    // alternative styles at sub-pages
    margin: 0 auto 120px;
    padding: 0 0 40px;
    border-right: solid 60px #fff;
    border-left: solid 60px #fff;
    width: 100%;
    min-width: 1280px;
    background: center no-repeat url(../img/common/subpage-visual.jpg);
    background-size: cover;
    .inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 180px;
    }
    .head_lead {
        margin-top: 30px;
        color: #fff;
    }
    &.mod_smallhead {
        margin-bottom: 70px;
        .inner {
            min-height: 90px;
        }
        .heading01 {
            font-size: 24px;
        }
    }
}//.page-head




//  ::::::::  ::::    :::     :::     :::     :::
// :+:    :+: :+:+:   :+:   :+: :+:   :+:     :+:
// +:+        :+:+:+  +:+  +:+   +:+  +:+     +:+
// :#:        +#+ +:+ +#+ +#++:++#++: +#+     +:+
// +#+   +#+# +#+  +#+#+# +#+     +#+  +#+   +#+
// #+#    #+# #+#   #+#+# #+#     #+#   #+#+#+#
//  ########  ###    #### ###     ###     ###
.global_nav {
    padding: 55px 0 0;
    width: 640px;
    > ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 640px;
        > li {
            > a {
                padding: 16px 5px;
                border-bottom: solid 4px rgba(255,255,255,0);
                display: block;
                position: relative;
                z-index: 50;
                color: #fff;
                font-weight: bold;
                font-size: 16px;
                text-decoration: none;
                transition-duration: .1s;
                &:hover {
                    border-bottom: solid 4px rgba(255,255,255,1);
                }
            }//> a
            &.trig_sub {
                > a {
                    &:hover,
                    &.is_active {
                        border-bottom: solid 4px rgba(0,56,148,1);
                    }
                }
            }//&.trig_sub
        }//> li
    }//> ul
    .sub_nav {
        box-sizing: border-box;
        padding: 24px;
        display: none;
        position: absolute;
        top: 111px;
        left: 0;
        z-index: 49;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        background: #fff;
        li {
            box-sizing: border-box;
            margin-right: 10px;
            margin-bottom: 16px;
            padding-right: 10px;
            border-right: solid 1px #ddd;
            width: 24%;
            &:first-child {
                border: 0;
                width: 100%;
                font-weight: bold;
                font-size: 24px;
                a {
                    color: #202020;
                    font-size: 24px;
                }
            }
            &:nth-child(4n+1) {
                margin-right: 0;
                padding-right: 0;
                border: 0;
            }
            a {
                display: block;
                color: #003894;
                font-weight: bold;
                font-size: 14px;
                text-decoration: none;
                transition-duration: .4s;
                &:hover {
                    opacity: .8;
                }
                .image {
                    margin-bottom: 10px;
                    display: block;
                }
                .label {
                    &::before {
                        box-sizing: border-box;
                        margin-right: 10px;
                        border-top: solid 2px #003894;
                        border-right: solid 2px #003894;
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        vertical-align: 2px;
                        content: '';
                        transform: rotate(45deg);
                    }
                }//.label
            }// a
        }// li
    }//.sub_nav
    .trig_sub > a.is_active + .sub_nav {
        display: flex;
    }
    .btn_sitemap {
        padding-top: 16px;
        .trigger {
            display: block;
            position: relative;
            width: 40px;
            height: 18px;
            line-height: 16px;
            cursor: pointer;
            i {
                display: block;
                position: absolute;
                width: 40px;
                height: 2px;
                background: #fff;
                &:nth-of-type(1) {
                    top: 0;
                }
                &:nth-of-type(2) {
                    top: 8px;
                }
                &:nth-of-type(3) {
                    bottom: 0;
                }
            }
        }
    }
}//.global_nav

.nav_sitemap {
    box-sizing: border-box;
    padding: 80px;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    background: rgba(242,243,248,.95);
    .btn_close {
        position: absolute;
        top: 40px;
        right: 80px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        i {
            display: block;
            position: absolute;
            top: 19px;
            left: 0;
            width: 40px;
            height: 2px;
            background: #202020;
            &:nth-of-type(1) {
                transform: rotate(30deg);
            }
            &:nth-of-type(2) {
                transform: rotate(-30deg);
            }
        }
    }//.btn_close
}//.nav_sitemap



//  ::::::::  :::        ::::::::::: :::::::::  :::::::::: :::::::::
// :+:    :+: :+:            :+:     :+:    :+: :+:        :+:    :+:
// +:+        +:+            +:+     +:+    +:+ +:+        +:+    +:+
// +#++:++#++ +#+            +#+     +#+    +:+ +#++:++#   +#++:++#:
//        +#+ +#+            +#+     +#+    +#+ +#+        +#+    +#+
// #+#    #+# #+#            #+#     #+#    #+# #+#        #+#    #+#
//  ########  ########## ########### #########  ########## ###    ###
.js_slider {
    position: relative;
    background: rgb(255,255,255);
    .slick-list {
        overflow: visible;
    }
    .slick-prev {
        z-index: 50;
        background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,1) 100%) !important;
    }
    .slick-next {
        z-index: 50;
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,1) 100%) !important;
    }
    .slick-arrow {
        width: 50vw;
        height: 100%;
        background: rgba(255,255,255,.3);
    }
    .slick-prev,
    .slick-next {
        &::before {
            border-radius: 80px;
            width: 80px;
            height: 80px;
            background: rgba(255,255,255,1);
        }
        &::after {
            width: 10px;
            height: 10px;
            transition-duration: .25s;
        }
    }
    .slick-prev {
        left: -46.5vw;
        &::after {
            border-right: 2px solid #003894;
            border-bottom: 2px solid #003894;
            right: 40px;
        }
        &.slick-disabled::after {
            border-right: 2px solid #999;
            border-bottom: 2px solid #999;
        }
    }
    .slick-next {
        right: -46.5vw;
        &::after {
            border-right: 2px solid #003894;
            border-bottom: 2px solid #003894;
            left: 40px;
        }
        &.slick-disabled::after {
            border-right: 2px solid #999;
            border-bottom: 2px solid #999;
        }
    }

    .slide_item {
        box-sizing: border-box;
        margin: 0 12px;
        padding: 16px;
        border-radius: 4px;
        width: 272px;
        background: #f7f7f7;
    }
    .photo {
        margin-bottom: 20px;
        width: 100%;
        img {
            border-radius: 4px;
            width: 100%;
        }
    }
    .detail {
        .name {
            margin-bottom: 10px;
            em {
                display: block;
                color: #003894;
                font-weight: bold;
                font-size: 18px;
            }
            small {
                display: block;
                font-size: 10px;
            }
        }//.name
        .data {
            margin-bottom: 10px;
            dt {
                color: #003894;
                font-size: 10px;
            }
            dd {
                margin-bottom: 10px;
                font-size: 14px;
            }
        }//.data
        .to_detail {
            font-weight: bold;
            font-size: 16px;
            a {
                color: #003894;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }//.detail
}//.js_slider
.slick-slider.slick-dotted {
    margin-bottom: 45px;
}//.slick-slider.slick-dotted
.slick-dots {
    li {
        border-radius: 100%;
        width: 8px;
        height: 8px;
        background-color: #b8bfce;
        &.slick-active {
            background-color: #003894;
        }
    }
}//.slick-dots



// :::::::::: :::::::: ::::::::::: ::: ::::::::::: ::::::::::       ::::::::  :::        ::::::::::: :::::::::  :::::::::: :::::::::
// :+:       :+:    :+:    :+:   :+: :+:   :+:     :+:             :+:    :+: :+:            :+:     :+:    :+: :+:        :+:    :+:
// +:+       +:+           +:+  +:+   +:+  +:+     +:+             +:+        +:+            +:+     +:+    +:+ +:+        +:+    +:+
// +#++:++#  +#++:++#++    +#+ +#++:++#++: +#+     +#++:++#        +#++:++#++ +#+            +#+     +#+    +:+ +#++:++#   +#++:++#:
// +#+              +#+    +#+ +#+     +#+ +#+     +#+                    +#+ +#+            +#+     +#+    +#+ +#+        +#+    +#+
// #+#       #+#    #+#    #+# #+#     #+# #+#     #+#             #+#    #+# #+#            #+#     #+#    #+# #+#        #+#    #+#
// ########## ########     ### ###     ### ###     ##########       ########  ########## ########### #########  ########## ###    ###
.estate_slider {
    position: relative;
    background: rgb(255,255,255);
    .slick-list {
        overflow: visible;
    }
    .slick-prev {
        z-index: 50;
        background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,1) 100%) !important;
    }
    .slick-next {
        z-index: 50;
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,1) 100%) !important;
    }
    .slick-arrow {
        width: 50vw;
        height: 100%;
        background: rgba(255,255,255,.3);
    }
    .slick-prev,
    .slick-next {
        &::before {
            border-radius: 80px;
            width: 80px;
            height: 80px;
            background: rgba(255,255,255,1);
        }
        &::after {
            width: 10px;
            height: 10px;
            transition-duration: .25s;
        }
    }
    .slick-prev {
        left: -46.5vw;
        &::after {
            border-right: 2px solid #003894;
            border-bottom: 2px solid #003894;
            right: 40px;
        }
        &.slick-disabled::after {
            border-right: 2px solid #999;
            border-bottom: 2px solid #999;
        }
    }
    .slick-next {
        right: -46.5vw;
        &::after {
            border-right: 2px solid #003894;
            border-bottom: 2px solid #003894;
            left: 40px;
        }
        &.slick-disabled::after {
            border-right: 2px solid #999;
            border-bottom: 2px solid #999;
        }
    }
    &.mod_reverse {
        .slick-prev {
            right: auto;
            left: -46.5vw;
            &::after {
                border-right: 2px solid #003894;
                border-bottom: 2px solid #003894;
                right: 40px;
            }
            &.slick-disabled::after {
                border-right: 2px solid #999;
                border-bottom: 2px solid #999;
            }
        }
        .slick-next {
            right: -46.5vw;
            &::after {
                border-right: 2px solid #003894;
                border-bottom: 2px solid #003894;
                left: 40px;
            }
            &.slick-disabled::after {
                border-right: 2px solid #999;
                border-bottom: 2px solid #999;
            }
        }
    }//&.mod_reverse

    .item {
        margin-bottom: 25px;
        padding: 2px 10px 0;
        a {
            display: block;
            color: #202020;
            text-align: left;
            text-decoration: none;
        }// a
        figure {
            margin-bottom: 10px;
            background: #f2f3f8;
            img {
                width: 204px;
                height: 280px;
                object-fit: contain;
            }
        }// figure
        .item_title {
            em {
                display: block;
                font-weight: bold;
                font-size: 16px;
            }
            small {
                display: block;
                font-size: 10px;
            }
        }//.item_title
    }
}//.estate_slider




// :::::::::: :::::::: ::::::::::: ::: ::::::::::: ::::::::::      :::        ::::::::::: :::::::: :::::::::::
// :+:       :+:    :+:    :+:   :+: :+:   :+:     :+:             :+:            :+:    :+:    :+:    :+:
// +:+       +:+           +:+  +:+   +:+  +:+     +:+             +:+            +:+    +:+           +:+
// +#++:++#  +#++:++#++    +#+ +#++:++#++: +#+     +#++:++#        +#+            +#+    +#++:++#++    +#+
// +#+              +#+    +#+ +#+     +#+ +#+     +#+             +#+            +#+           +#+    +#+
// #+#       #+#    #+#    #+# #+#     #+# #+#     #+#             #+#            #+#    #+#    #+#    #+#
// ########## ########     ### ###     ### ###     ##########      ########## ########### ########     ###
.estate-list {
    .heading02 {
        margin-bottom: 60px;
    }
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        li {
            margin-bottom: 25px;
            a {
                display: block;
                color: #202020;
                text-decoration: none;
            }// a
            figure {
                margin-bottom: 10px;
                img {
                    width: 100%;
                }
            }// figure
            .item_title {
                em {
                    display: block;
                    font-weight: bold;
                    font-size: 16px;
                }
                small {
                    display: block;
                    font-size: 10px;
                }
            }//.item_title
        }// li
    }// ul
}//.estate-list



// :::::::::  :::    :::  :::::::: ::::::::::: ::::::::       :::     ::: ::::::::::: :::::::::: :::       :::
// :+:    :+: :+:    :+: :+:    :+:    :+:    :+:    :+:      :+:     :+:     :+:     :+:        :+:       :+:
// +:+    +:+ +:+    +:+ +:+    +:+    +:+    +:+    +:+      +:+     +:+     +:+     +:+        +:+       +:+
// +#++:++#+  +#++:++#++ +#+    +:+    +#+    +#+    +:+      +#+     +:+     +#+     +#++:++#   +#+  +:+  +#+
// +#+        +#+    +#+ +#+    +#+    +#+    +#+    +#+       +#+   +#+      +#+     +#+        +#+ +#+#+ +#+
// #+#        #+#    #+# #+#    #+#    #+#    #+#    #+#        #+#+#+#       #+#     #+#         #+#+# #+#+#
// ###        ###    ###  ########     ###     ########           ###     ########### ##########   ###   ###
.photo_view {
    width: 550px;
    .slide {
        margin-bottom: 5px;
        width: 550px;
        height: 450px;
        background: #f2f3f8;
    }
    .slide .slide-view {
        width: 100%;
    }
    .slide .slide-view li {
        width: 100%;
    }
    .slide .slide-view li img {
        margin: 0 auto;
        width: 550px;
        height: 450px;
        object-fit: contain;
    }

    .thumbs {
        margin: 0 auto;
        position: relative;
        width: 470px;
        .slide-nav {
            width: 100%;
            li {
                box-sizing: border-box;
                padding: 0 2px;
                padding: 0 0;
                width: 60px;
                height: 60px;
                overflow: hidden;
                img {
                    box-sizing: border-box;
                    margin: 0;
                    border-radius: 4px;
                    width: 60px;
                    height: 60px;
                    object-fit: cover;
                }
            }// li
            .slick-slide {
                opacity: .5;
                &.slick-current {
                    opacity: 1;
                    img {
                        border: solid 2px #003894;
                    }
                }
            }//.slick-slide
        }//.slide-nav
    }//.thumbs

    .slick-prev,
    .slick-next {
        &::before {
            box-sizing: border-box;
            border: solid 1px #003894;
            border-radius: 32px;
            width: 32px;
            height: 32px;
            background: #fff;
        }
        &::after {
            width: 7px;
            height: 7px;
        }
    }
    .slick-prev {
        z-index: 50;
        background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,1) 100%) !important;
        &::after {
            border-right: 2px solid #003894;
            border-bottom: 2px solid #003894;
            right: 16px;
        }
    }
    .slick-next {
        z-index: 50;
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,1) 100%) !important;
        &::after {
            border-right: 2px solid #003894;
            border-bottom: 2px solid #003894;
            left: 16px;
        }
    }
    .slick-list {
        overflow: hidden !important;
    }
}//.photo_view



//     :::      ::::::::   ::::::::  :::::::::: :::::::::::      :::::::::  :::::::::: ::::::::::: :::     ::::::::::: :::
//   :+: :+:   :+:    :+: :+:    :+: :+:            :+:          :+:    :+: :+:            :+:   :+: :+:       :+:     :+:
//  +:+   +:+  +:+        +:+        +:+            +:+          +:+    +:+ +:+            +:+  +:+   +:+      +:+     +:+
// +#++:++#++: +#++:++#++ +#++:++#++ +#++:++#       +#+          +#+    +:+ +#++:++#       +#+ +#++:++#++:     +#+     +#+
// +#+     +#+        +#+        +#+ +#+            +#+          +#+    +#+ +#+            +#+ +#+     +#+     +#+     +#+
// #+#     #+# #+#    #+# #+#    #+# #+#            #+#          #+#    #+# #+#            #+# #+#     #+#     #+#     #+#
// ###     ###  ########   ########  ##########     ###          #########  ##########     ### ###     ### ########### ##########
.mod_asset_detail {
    .assets_head {
        margin-bottom: 40px;
        h2 {
            margin-bottom: 10px;
            font-size: 36px;
        }
        p {
            font-size: 16px;
        }
        .tags {
            margin-top: 15px;
            li {
                margin-right: 5px;
                padding: 2px 5px;
                border-radius: 4px;
                display: inline-block;
                color: #003894;
                background: #b1cfff;
                font-size: 10px;
            }
        }
        .sub_menu {
            margin-top: 40px;
            ul {
                border-radius: 4px;
                display: flex;
                flex-direction: row;
                overflow: hidden;

                li {
                    box-sizing: border-box;
                    border-left: solid 1px #fff;
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    font-weight: bold;
                    font-size: 14px;
                    text-align: center;
                    &:first-child {
                        border-left: 0;
                    }
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        background: #06173c;
                        text-decoration: none;
                    }
                }
            }
        }//.sub_menu
    }//.assets_head
    .asset_detail {
        margin-bottom: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .photo_view {
            width: 550px;
        }
        .asset_data {
            width: 490px;
            .location {
                margin-bottom: 10px;
            }
            .station {
                margin-bottom: 30px;
            }
            dl {
                dt {
                    color: #003894;
                    font-weight: normal;
                    font-size: 10px;
                }// dt
                dd {
                    font-size: 16px;
                }// dd
            }// dl
            .basic_data {
                margin-bottom: 10px;
                dd {
                    margin-bottom: 15px;
                }
            }//.basic_data
            .alternative_data {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                dl {
                    margin-bottom: 15px;
                    width: 48%;
                }// dl
            }//.alternative_data
            &.mod_office_asset {
                box-sizing: border-box;
                border: solid 1px #b8bfce;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 492px;
                height: 452px;
                background: #e4e4e4;
                img {
                    width: 460px;
                    height: 450px;
                    object-fit: contain;
                }
            }//&.mod_office_asset
        }//.asset_data
    }//.asset_detail
}//.mod_asset_detail

.office_data {
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
        padding-top: 15px;
        border-top: solid 1px #b8bfce;
        width: 32%;
        .location,
        .station {
            margin-bottom: 15px;
            padding-left: 2em;
            text-indent: -2em;
            img {
                display: block;
            }
        }
    }//.item
    dl {
        dt {
            color: #003894;
            font-weight: normal;
            font-size: 10px;
        }// dt
        dd {
            margin-bottom: 15px;
            font-size: 16px;
        }// dd
    }// dl
}//.office_data




// ::::::::::: :::::::::: :::    ::: :::::::::::      :::::::::     :::      ::::::::  ::::::::::
//     :+:     :+:        :+:    :+:     :+:          :+:    :+:  :+: :+:   :+:    :+: :+:
//     +:+     +:+         +:+  +:+      +:+          +:+    +:+ +:+   +:+  +:+        +:+
//     +#+     +#++:++#     +#++:+       +#+          +#++:++#+ +#++:++#++: :#:        +#++:++#
//     +#+     +#+         +#+  +#+      +#+          +#+       +#+     +#+ +#+   +#+# +#+
//     #+#     #+#        #+#    #+#     #+#          #+#       #+#     #+# #+#    #+# #+#
//     ###     ########## ###    ###     ###          ###       ###     ###  ########  ##########
.text_content {
    margin: 0 auto;
    width: 812px;
    .item {
        margin-bottom: 60px;
    }//.item
    h2 {
        margin-bottom: 60px;
        font-weight: bold;
        font-size: 30px;
    }
    h3 {
        margin-bottom: 36px;
        font-weight: bold;
        font-size: 24px;
    }
    h4 {
        margin-bottom: 31px;
        font-weight: bold;
        font-size: 16px;
    }
    p {
        margin-bottom: 32px;
        &.update_info {
            text-align: right;
            small {
                font-size: 10px;
            }
        }
    }
    a {
        color: #202020;
    }
    ol.mod_digit {
        li {
            margin-bottom: 10px;
            list-style-position: inside;
        }
    }
    ol.mod_bracket {
        counter-reset: count 0;
        li {
            margin-bottom: 10px;
            padding-left: 1.8em;
            list-style: none;
            text-indent: -1.8em;
            &::before {
                content: '(' counter(count) ') ';
                counter-increment: count 1;
            }
        }
    }
}//.text_content




// :::::::::     :::      ::::::::  :::::::::: :::::::::
// :+:    :+:  :+: :+:   :+:    :+: :+:        :+:    :+:
// +:+    +:+ +:+   +:+  +:+        +:+        +:+    +:+
// +#++:++#+ +#++:++#++: :#:        +#++:++#   +#++:++#:
// +#+       +#+     +#+ +#+   +#+# +#+        +#+    +#+
// #+#       #+#     #+# #+#    #+# #+#        #+#    #+#
// ###       ###     ###  ########  ########## ###    ###
.pagenation {
    text-align: center;
    span,
    a {
        box-sizing: border-box;
        margin: 0 3px;
        border: solid 1px #f2f3f8;
        border-radius: 4px;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: #003894;
        background-color: #fff;
        font-size: 16px;
        text-align: center;
        text-decoration: none;
    }
    .current {
        border: solid 1px #003894;
        color: #fff;
        background: #003894;
    }
    .previouspostslink,
    .nextpostslink {
        border: solid 1px #003894;
        border-radius: 40px;
        position: relative;
        &::after {
            border-right: solid 2px #003894;
            border-bottom: solid 2px #003894;
            display: block;
            position: absolute;
            top: 14.5px;
            width: 5px;
            height: 5px;
            content: '';
        }
    }
    .previouspostslink::after {
        left: 16px;
        transform: rotate(-225deg);
    }
    .nextpostslink::after {
        right: 16px;
        transform: rotate(-45deg);
    }
    ul {
        text-align: center;
        li {
            a {
                &:hover {
                    opacity: .5;
                }
            }// a
            &.prev,
            &.next {
                a {
                    border: solid 1px #fff;
                }
                .mark_link {
                    vertical-align: -10px;
                }
            }
            &.prev {
                transform: rotate(180deg);
            }
            &.is_current {
                a {
                    border: solid 1px #003894;
                    color: #fff;
                    background: #003894;
                }
            }//&.is_current
        }// li
    }// ul
}//.pagenation




// ::::    ::::   ::::::::  :::     ::: ::::::::::: ::::::::::
// +:+:+: :+:+:+ :+:    :+: :+:     :+:     :+:     :+:
// +:+ +:+:+ +:+ +:+    +:+ +:+     +:+     +:+     +:+
// +#+  +:+  +#+ +#+    +:+ +#+     +:+     +#+     +#++:++#
// +#+       +#+ +#+    +#+  +#+   +#+      +#+     +#+
// #+#       #+# #+#    #+#   #+#+#+#       #+#     #+#
// ###       ###  ########      ###     ########### ##########
.mod_movie {
    margin-bottom: -120px;
    background: center no-repeat url(../img/company/company-video_bg.jpg);
    background-size: cover;
    .inner {
        padding: 40px 0;
    }
    iframe {
        margin: 0 auto;
        display: block;
    }
}//.mod_movie




// :::::::::: :::    ::: :::        :::        ::::    ::::      :::     :::::::::
// :+:        :+:    :+: :+:        :+:        +:+:+: :+:+:+   :+: :+:   :+:    :+:
// +:+        +:+    +:+ +:+        +:+        +:+ +:+:+ +:+  +:+   +:+  +:+    +:+
// :#::+::#   +#+    +:+ +#+        +#+        +#+  +:+  +#+ +#++:++#++: +#++:++#+
// +#+        +#+    +#+ +#+        +#+        +#+       +#+ +#+     +#+ +#+
// #+#        #+#    #+# #+#        #+#        #+#       #+# #+#     #+# #+#
// ###         ########  ########## ########## ###       ### ###     ### ###
.fullmap {
    width: 100%;
    iframe {
        width: 100% !important;
    }
}//.fullmap




//  ::::::::   ::::::::  ::::    ::: :::::::::::           :::::::::     ::: ::::::::::: :::    :::
// :+:    :+: :+:    :+: :+:+:   :+:     :+:               :+:    :+:  :+: :+:   :+:     :+:    :+:
// +:+        +:+    +:+ :+:+:+  +:+     +:+               +:+    +:+ +:+   +:+  +:+     +:+    +:+
// +#+        +#+    +:+ +#+ +:+ +#+     +#+               +#++:++#+ +#++:++#++: +#+     +#++:++#++
// +#+        +#+    +#+ +#+  +#+#+#     +#+               +#+       +#+     +#+ +#+     +#+    +#+
// #+#    #+# #+#    #+# #+#   #+#+#     #+#               #+#       #+#     #+# #+#     #+#    #+#
//  ########   ########  ###    ####     ###    ########## ###       ###     ### ###     ###    ###
.content_path {
    padding-top: 120px;
    .contract_records + &,
    .mod_movie + & {
        padding-top: 240px;
    }
    ul {
        margin: 0 auto;
        width: 1120px;
        li {
            margin-left: 1em;
            display: inline-block;
            position: relative;
            font-size: 14px;
            text-align: center;
            &::before {
                display: inline-block;
                position: absolute;
                top: 30%;
                left: -1.5em;
                content: '▶';
                color: #b8bfce;
                font-size: 10px;
            }
            &:first-child::before {
                display: none;
            }
            a {
                padding: 5px 10px;
                border: solid 1px #f2f3f8;
                border-radius: 4px;
                display: block;
                color: #003894;
                text-decoration: none;
            }// a
            .current {
                padding: 5px 10px;
                border: solid 1px #f2f3f8;
                border-radius: 4px;
                display: block;
                background: #f7f7f7;
            }//.current
        }// li
    }// ul
}//.content_path




// ::::::::::           ::::::::   ::::::::  ::::    ::: ::::::::::: :::      :::::::: :::::::::::
// :+:                 :+:    :+: :+:    :+: :+:+:   :+:     :+:   :+: :+:   :+:    :+:    :+:
// +:+                 +:+        +:+    +:+ :+:+:+  +:+     +:+  +:+   +:+  +:+           +:+
// :#::+::#            +#+        +#+    +:+ +#+ +:+ +#+     +#+ +#++:++#++: +#+           +#+
// +#+                 +#+        +#+    +#+ +#+  +#+#+#     +#+ +#+     +#+ +#+           +#+
// #+#                 #+#    #+# #+#    #+# #+#   #+#+#     #+# #+#     #+# #+#    #+#    #+#
// ###       ########## ########   ########  ###    ####     ### ###     ###  ########     ###
.footer-contact {
    margin-top: 120px;
    background: #f2f3f8;
    .inner {
        padding: 90px 0 120px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }//.inner
    .contact_heading {
        width: 540px;
        h2 {
            line-height: 90px;
            color: #b8bfce;
            font-family: $cgaramond;
            font-weight: normal;
            font-size: 120px;
        }
        p {
            margin-top: 20px;
            font-size: 16px;
        }
    }//.contact_heading
    .contact_body {
        width: 470px;
        .btn_contact {
            margin-bottom: 15px;
            width: 100%;
            height: 72px;
            a {
                display: block;
                width: 100%;
                height: 100%;
                line-height: 72px;
                color: #fff;
                background: #003894;
                font-weight: bold;
                font-size: 16px;
                text-align: center;
                text-decoration: none;
            }
        }//.btn_contact
        .btn_tel {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            a {
                color: #003894;
                font-size: 32px;
                text-decoration: none;

                pointer-events: none;
                img {
                    margin-right: 5px;
                }
            }
            .business_time {
                padding-top: 7px;
                font-size: 14px;
                small {
                    display: block;
                    font-size: 10px;
                }
            }
        }//.btn_tel
    }//.contact_body
}//.footer-contact




//  ::::::::            :::::::::: ::::::::   :::::::: ::::::::::: :::::::::: :::::::::
// :+:    :+:           :+:       :+:    :+: :+:    :+:    :+:     :+:        :+:    :+:
// +:+                  +:+       +:+    +:+ +:+    +:+    +:+     +:+        +:+    +:+
// :#:                  :#::+::#  +#+    +:+ +#+    +:+    +#+     +#++:++#   +#++:++#:
// +#+   +#+#           +#+       +#+    +#+ +#+    +#+    +#+     +#+        +#+    +#+
// #+#    #+#           #+#       #+#    #+# #+#    #+#    #+#     #+#        #+#    #+#
//  ######## ########## ###        ########   ########     ###     ########## ###    ###
.global_footer {
    color: #fff;
    background: #06173c;
    .inner {
        padding-top: 120px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }//.inner
    .footer_menu {
        display: flex;
        flex-direction: row;
        flex-shrink: 1;
        width: 600px;
        .category {
            width: 200px;
            vertical-align: top;
            h3 {
                margin-bottom: 24px;
                font-size: 24px;
                small {
                    display: block;
                    color: #b8bfce;
                    font-family: $cgaramond;
                    font-size: 16px;
                }
            }// h3
            ul {
                li {
                    margin-bottom: 10px;
                    a {
                        color: #fff;
                        font-size: 14px;
                        text-decoration: none;
                    }
                }// li
            }// ul
        }//.category
    }//.footer_menu
    .footer_recruit {
        flex-shrink: 1;
        width: 504px;
        .recruit_heading {
            padding: 25px;
            display: flex;
            flex-direction: row;
            flex-flow: wrap;
            h3 {
                flex-shrink: 1;
                width: 25%;
                font-size: 24px;
                small {
                    display: block;
                    color: #b8bfce;
                    font-family: $cgaramond;
                    font-size: 16px;
                }
            }
            .leadtext {
                flex-shrink: 1;
                width: 75%;
                font-size: 14px;
            }
            .btn_recruit {
                padding-top: 25px;
                flex-shrink: 1;
                width: 100%;
                line-height: 32px;
                font-weight: bold;
                font-size: 16px;
                a {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }//.recruit_heading
    }//.footer_recruit
}//.global_footer




//  ::::::::   ::::::::  :::::::::  :::   ::: :::::::::  ::::::::::: ::::::::  :::    ::: :::::::::::
// :+:    :+: :+:    :+: :+:    :+: :+:   :+: :+:    :+:     :+:    :+:    :+: :+:    :+:     :+:
// +:+        +:+    +:+ +:+    +:+  +:+ +:+  +:+    +:+     +:+    +:+        +:+    +:+     +:+
// +#+        +#+    +:+ +#++:++#+    +#++:   +#++:++#:      +#+    :#:        +#++:++#++     +#+
// +#+        +#+    +#+ +#+           +#+    +#+    +#+     +#+    +#+   +#+# +#+    +#+     +#+
// #+#    #+# #+#    #+# #+#           #+#    #+#    #+#     #+#    #+#    #+# #+#    #+#     #+#
//  ########   ########  ###           ###    ###    ### ########### ########  ###    ###     ###
.footer-copyright {
    margin-top: 120px;
    padding: 60px 0;
    border-top: solid 1px #003894;
    display: flex;
    position: relative;
    flex-shrink: 1;
    justify-content: space-between;
    width: 100%;
    .btn_backtop {
        display: block;
        position: absolute;
        top: -50px;
        right: 3px;
        width: 180px;
        height: 32px;
        a {
            border: solid 1px #003894;
            border-radius: 4px;
            display: block;
            width: 100%;
            height: 100%;
            line-height: 32px;
            color: #fff;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            &::before {
                box-sizing: border-box;
                margin-right: 10px;
                border-top: solid 2px #fff;
                border-left: solid 2px #fff;
                display: inline-block;
                width: 8px;
                height: 8px;
                content: '';
                transform: rotate(45deg);
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }//.btn_backtop
    .footer-submenu {
        ul {
            li {
                margin-left: 5px;
                padding-left: 10px;
                border-left: solid 1px #fff;
                display: inline-block;
                line-height: 12px;
                &:first-child {
                    margin-left: 0;
                    padding-left: 0;
                    border: 0;
                }
                a {
                    color: #fff;
                    font-size: 12px;
                    text-decoration: none;
                }
            }
        }
    }
    .copyright {
        font-size: 12px;
    }
}//.footer-copyright
