// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

// ::::    ::::  :::::::::: ::::::::   ::::::::      :::      ::::::::  ::::::::::
// +:+:+: :+:+:+ :+:       :+:    :+: :+:    :+:   :+: :+:   :+:    :+: :+:
// +:+ +:+:+ +:+ +:+       +:+        +:+         +:+   +:+  +:+        +:+
// +#+  +:+  +#+ +#++:++#  +#++:++#++ +#++:++#++ +#++:++#++: :#:        +#++:++#
// +#+       +#+ +#+              +#+        +#+ +#+     +#+ +#+   +#+# +#+
// #+#       #+# #+#       #+#    #+# #+#    #+# #+#     #+# #+#    #+# #+#
// ###       ### ########## ########   ########  ###     ###  ########  ##########




.page-head.mod_message {
    margin-bottom: 20px;
}

.message_image {
    margin: 0 -80px 60px;
    overflow: hidden;
    img {
        margin: 0 auto;
    }
}//.message_image

.top_message {
    margin: 0 auto;
    width: 890px;
    h2 {
        margin-bottom: 45px;
        font-weight: bold;
        font-size: 30px;
    }
    p {
        margin-bottom: 40px;
        em {
            img {
                margin-top: 10px;
            }
        }
    }
    .signature {
        margin-top: 15px;
        text-align: right;
    }//.signature
}//.top_message
