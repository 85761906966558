//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

// ::::    ::::  :::::::::: ::::::::   ::::::::      :::      ::::::::  ::::::::::
// +:+:+: :+:+:+ :+:       :+:    :+: :+:    :+:   :+: :+:   :+:    :+: :+:
// +:+ +:+:+ +:+ +:+       +:+        +:+         +:+   +:+  +:+        +:+
// +#+  +:+  +#+ +#++:++#  +#++:++#++ +#++:++#++ +#++:++#++: :#:        +#++:++#
// +#+       +#+ +#+              +#+        +#+ +#+     +#+ +#+   +#+# +#+
// #+#       #+# #+#       #+#    #+# #+#    #+# #+#     #+# #+#    #+# #+#
// ###       ### ########## ########   ########  ###     ###  ########  ##########




.page-head.mod_message {
    margin-bottom: 0;
}

.message_image {
    margin: 0 -20px 20px;
    //overflow: hidden;
    img {
        margin: 0 -50%;
        width: 200%;
    }
}//.message_image

.top_message {
    //margin: 0 auto;
    width: 100%;
    h2 {
        margin-bottom: 20px;
        //font-weight: bold;
        font-size: 16px;
        br {
            display: none;
        }
    }
    p {
        margin-bottom: 15px;
        font-size: 14px;
        em {
            img {
                //margin-top: 10px;
            }
        }
    }
    .signature {
        //margin-top: 15px;
        //text-align: right;
    }//.signature
}//.top_message
