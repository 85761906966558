//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

//  ::::::::   ::::::::  ::::    ::: ::::::::::: :::      :::::::: :::::::::::
// :+:    :+: :+:    :+: :+:+:   :+:     :+:   :+: :+:   :+:    :+:    :+:
// +:+        +:+    +:+ :+:+:+  +:+     +:+  +:+   +:+  +:+           +:+
// +#+        +#+    +:+ +#+ +:+ +#+     +#+ +#++:++#++: +#+           +#+
// +#+        +#+    +#+ +#+  +#+#+#     +#+ +#+     +#+ +#+           +#+
// #+#    #+# #+#    #+# #+#   #+#+#     #+# #+#     #+# #+#    #+#    #+#
//  ########   ########  ###    ####     ### ###     ###  ########     ###




// :::::::::      :::      ::::::::  ::::::::::
// :+:    :+:   :+: :+:   :+:    :+: :+:
// +:+    +:+  +:+   +:+  +:+        +:+
// +#++:++#+  +#++:++#++: +#++:++#++ +#++:++#
// +#+    +#+ +#+     +#+        +#+ +#+
// #+#    #+# #+#     #+# #+#    #+# #+#
// #########  ###     ###  ########  ##########
.page-head {
    &.mod_contacthead {
        //margin-bottom: 0;
        //border-right: 0;
        //border-left: 0;
        //background: #f2f3f8;
        .page-logo {
            img {
                //filter: invert(8%) sepia(99%) saturate(7044%) hue-rotate(200deg) brightness(95%) contrast(130%);
            }
        }//.page-logo
        .global_nav {
            ul {
                //justify-content: flex-end;
                li {
                    //display: none;
                    &.btn_sitemap {
                        //display: block;
                    }
                }// li
            }// ul
        }//.global_nav
        .heading01 {
            //display: none;
        }
    }//&.mod_contacthead
}//.page-head

.mod_contacthead {
    .global_nav {
        .btn_sitemap {
            .trigger {
                i {
                    //background: #202020;
                }// i
            }//.trigger
        }//.btn_sitemap
    }//.global_nav
}//.mod_contact

.mod_contactpage {
    padding-bottom: 60px;
    //background: #f2f3f8;
    & + .footer-contact {
        //display: none;
    }
}//.mod_contactpage




// :::::::::: ::::::::  :::::::::  ::::    ::::       :::::::::   ::::::::  :::    :::
// :+:       :+:    :+: :+:    :+: +:+:+: :+:+:+      :+:    :+: :+:    :+: :+:    :+:
// +:+       +:+    +:+ +:+    +:+ +:+ +:+:+ +:+      +:+    +:+ +:+    +:+  +:+  +:+
// :#::+::#  +#+    +:+ +#++:++#:  +#+  +:+  +#+      +#++:++#+  +#+    +:+   +#++:+
// +#+       +#+    +#+ +#+    +#+ +#+       +#+      +#+    +#+ +#+    +#+  +#+  +#+
// #+#       #+#    #+# #+#    #+# #+#       #+#      #+#    #+# #+#    #+# #+#    #+#
// ###        ########  ###    ### ###       ###      #########   ########  ###    ###
.formarea {
    //margin: 0 auto;
    display: block;
    //flex-direction: row;
    //justify-content: space-between;
    width: 100%;
    .form_header {
        margin-bottom: 60px;
        width: 100%;
        h2 {
            font-size: 24px;
            small {
                //display: block;
                //color: #b8bfce;
                //font-family: $cgaramond;
                //font-size: 16px;
            }
        }
        .progress {
            margin-bottom: 20px;
            //position: relative;
            //width: 250px;
            //height: 90px;
            &::before {
                //display: block;
                //position: absolute;
                //top: 21px;
                //left: 2px;
                //width: 244px;
                //height: 2px;
                //content: '';
                //background: #b8bfce;
            }
            li {
                //position: absolute;
                //top: 0;
                //list-style: none;
                //text-align: center;
                i {
                    //border: solid 1px #b8bfce;
                    //border-radius: 44px;
                    //display: block;
                    //width: 44px;
                    //height: 44px;
                    //line-height: 44px;
                    //color: #b8bfce;
                    //background: #f2f3f8;
                    //font-size: 10px;
                }
                span {
                    //margin-top: 10px;
                    //display: block;
                    //color: #b8bfce;
                    //font-weight: bold;
                    //font-size: 16px;
                }
                &.is_input {
                    //left: 0;
                }
                &.is_confirm {
                    //left: 104px;
                }
                &.is_complete {
                    //right: 0;
                }
            }// li
        }//.progress
        .by_phone {
            h3 {
                margin-bottom: 8px;
                font-size: 18px;
            }
            .division {
                //color: #003894;
            }
            .phonenum {
                a {
                    //color: #003894;
                    //font-size: 32px;
                    //text-decoration: none;

                    //pointer-events: none;
                }
                small {
                    //display: block;
                    //font-size: 10px;
                }
            }
        }//.by_phone
    }//.form_header
    .form_body {
        width: 100%;
    }//.form_body
}//.formarea

.mw_wp_form_input li.is_input,
.mw_wp_form_confirm li.is_confirm,
.mw_wp_form_complete li.is_complete {
    i {
        //color: #fff !important;
        //background: #003894 !important;
    }
    span {
        //color: #003894 !important;
    }
}




// :::::::::: ::::::::  :::::::::  ::::    ::::       ::::::::::: ::::::::::: :::::::::: ::::    ::::
// :+:       :+:    :+: :+:    :+: +:+:+: :+:+:+          :+:         :+:     :+:        +:+:+: :+:+:+
// +:+       +:+    +:+ +:+    +:+ +:+ +:+:+ +:+          +:+         +:+     +:+        +:+ +:+:+ +:+
// :#::+::#  +#+    +:+ +#++:++#:  +#+  +:+  +#+          +#+         +#+     +#++:++#   +#+  +:+  +#+
// +#+       +#+    +#+ +#+    +#+ +#+       +#+          +#+         +#+     +#+        +#+       +#+
// #+#       #+#    #+# #+#    #+# #+#       #+#          #+#         #+#     #+#        #+#       #+#
// ###        ########  ###    ### ###       ###      ###########     ###     ########## ###       ###
.form_item {
    //margin-bottom: 20px;
    //padding-bottom: 16px;
    //border-bottom: solid 1px #fff;
    .field_title {
        //margin-bottom: 5px;
        //font-weight: bold;
        //font-size: 16px;
        &.mod_complete {
            //font-size: 24px;
        }
        .req {
            //margin-left: 8px;
            //border: solid 1px #b40000;
            //border-radius: 4px;
            //display: inline-block;
            //vertical-align: 2px;
            //color: #b40000;
            //font-weight: normal;
            //font-size: 10px;
        }
    }
    select,
    textarea,
    input[type='text'],
    input[type='email'] {
        //box-sizing: border-box;
        //padding: 12px;
        //border: solid 1px #b8bfce;
        //border-radius: 4px;
        //width: 100%;
        //background: #fff;
        //font-size: 16px;
        &.is_error {
            //border: solid 1px #b40000;
        }
    }
    .policy {
        //box-sizing: border-box;
        //padding: 16px;
        //border: solid 1px #b8bfce;
        //height: 170px;
        //overflow: scroll;
        //background: #fff;
        //font-size: 14px;
    }
}//.form_item
.form_control {
    button {
        //box-sizing: border-box;
        //margin: 0 auto;
        //padding: 16px;
        //border-radius: 4px;
        //display: block;
        //width: 70%;
        //color: #fff;
        //background: #003894;
        //font-weight: bold;
        //font-size: 16px;
        //text-align: center;
        &.backBtn {
            //padding: 0;
            //border: 0;
            //display: inline;
            //color: #003894;
            //background: none;
            //text-align: left;
            //text-decoration: underline;
        }
    }
    .btn_to {
        //display: none;
        &.is_complete {
            //display: block;
        }
    }
}//.form_control

.content_path.mod_office_search {
    padding-bottom: 0;
}
.box.mod_contactpage.mod_office_search {
    padding-top: 60px;
}




//  ::::::::   ::::::::  ::::    ::: ::::::::::: :::      :::::::: :::::::::::      ::::::::::: ::::::::  :::::::::
// :+:    :+: :+:    :+: :+:+:   :+:     :+:   :+: :+:   :+:    :+:    :+:              :+:    :+:    :+: :+:    :+:
// +:+        +:+    +:+ :+:+:+  +:+     +:+  +:+   +:+  +:+           +:+              +:+    +:+    +:+ +:+    +:+
// +#+        +#+    +:+ +#+ +:+ +#+     +#+ +#++:++#++: +#+           +#+              +#+    +#+    +:+ +#++:++#+
// +#+        +#+    +#+ +#+  +#+#+#     +#+ +#+     +#+ +#+           +#+              +#+    +#+    +#+ +#+
// #+#    #+# #+#    #+# #+#   #+#+#     #+# #+#     #+# #+#    #+#    #+#              #+#    #+#    #+# #+#
//  ########   ########  ###    ####     ### ###     ###  ########     ###              ###     ########  ###
.inquiry_list {
    .inq-cat {
        margin-bottom: 60px;
        display: block;
        // flex-direction: row;
        // justify-content: space-between;
        .cat-head {
            margin-bottom: 20px;
            width: 100%;
            // font-weight: bold;
            font-size: 24px;
        }
        .cat-body {
            width: 100%;
        }
        .tel_list {
            .item {
                margin-bottom: 30px;
                padding: 20px 0;
                display: block;
                // position: relative;
                // flex-direction: row;
                // justify-content: space-between;
                &::before {
                    // box-sizing: border-box;
                    // border-left: solid 24px #003894;
                    // display: block;
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    // width: 100%;
                    // height: 1px;
                    // content: '';
                    // background: #ddd;
                }
                .detail {
                    margin-bottom: 20px;
                    width: 100%;
                    h3 {
                        margin-bottom: 5px;
                        // font-weight: bold;
                        font-size: 16px;
                    }// h3
                    p {
                        font-size: 10px;
                    }// p
                }//.detail
                .phone_no {
                    width: 100%;
                    text-align: center;
                    a {
                        //text-decoration: none;
                        pointer-events: all;
                        span {
                            //display: block;
                            //color: #003894;
                            font-size: 12px;
                        }// span
                        em {
                            //display: block;
                            //color: #003894;
                            font-size: 30px;
                            &::before {
                                margin-right: 5px;
                                display: inline-block;
                                width: 28px;
                                height: 28px;
                                content: '';
                                background: url(../img/common/icon-phone.svg);
                                background-size: contain;
                            }
                        }// em
                        small {
                            display: block;
                            color: #000;
                            font-size: 10px;
                        }// small
                    }// a
                }//.phone_no
            }//.item
        }//.tel_list
    }//.inq-cat
    .btn_contact {
        margin-bottom: 15px;
        width: 100%;
        height: 72px;
        a {
            display: block;
            width: 100%;
            height: 100%;
            line-height: 72px;
            color: #fff;
            background: #003894;
            font-weight: bold;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
        }
    }//.btn_contact
}//.inquiry_list
