//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

// :::::::::  :::::::::: ::::::::  :::::::::  :::    ::: ::::::::::: :::::::::::
// :+:    :+: :+:       :+:    :+: :+:    :+: :+:    :+:     :+:         :+:
// +:+    +:+ +:+       +:+        +:+    +:+ +:+    +:+     +:+         +:+
// +#++:++#:  +#++:++#  +#+        +#++:++#:  +#+    +:+     +#+         +#+
// +#+    +#+ +#+       +#+        +#+    +#+ +#+    +#+     +#+         +#+
// #+#    #+# #+#       #+#    #+# #+#    #+# #+#    #+#     #+#         #+#
// ###    ### ########## ########  ###    ###  ########  ###########     ###




.page-head.mod_recruit_top {
    //margin-bottom: 0;
    //width: 100%;
    height: 410px;
    //background: center bottom no-repeat url(../img/recruit/recruit_top-mv.jpg);
    //background-size: cover;
    .outer {
        //margin: 0 auto;
        width: 100%;
    }
    .inner {
        padding-top: 110px;
        //justify-content: flex-end;
    }
    .head_lead {
        font-size: 12px;
    }
    & + .content_body {
        //margin-top: 0;
    }
}//.page-head.mod_recruit_top

.mod_recruit_lnav {
    //margin-top: -88px;
    ul {
        //display: flex;
        //justify-content: space-between;
        li {
            //width: 33%;
            //height: 88px;
            line-height: 1.7em;
            //font-weight: bold;
            //font-size: 16px;
            //text-align: center;
            a {
                //border-radius: 4px 4px 0 0;
                display: flex;
                justify-content: center;
                align-items: center;
                //width: 100%;
                //height: 100%;
                //color: #fff;
                //background: #06173c;
                //text-decoration: none;
                &:hover {
                    //color: #06173c;
                    //background: #fff;
                }
            }// a
            &.is_current {
                a {
                    //color: #06173c;
                    //background: #fff;

                    //pointer-events: none;
                }
            }
        }// li
    }// ul
}//.mod_recruit_lnav

.mod_newgrads_top {
    //margin-bottom: 120px;
    //padding-top: 70px;
    h2 {
        //margin-bottom: 40px;
        //padding-bottom: 40px;
        //border-bottom: solid 1px #b8bfce;
        //font-size: 30px;
    }
    .menu {
        //display: flex;
        //flex-direction: row;
        //flex-wrap: wrap;
        //justify-content: space-between;
        .item {
            width: 100%;
            &:first-child {
                width: 100%;
            }
            img {
                margin-bottom: 24px;
                width: 100%;
            }
            a {
                //color: #003894;
                //font-weight: bold;
                //font-size: 16px;
                //text-decoration: none;
                &:hover {
                    //text-decoration: underline;
                }
            }// a
            .sublist {
                margin-top: 24px;
                padding-top: 24px;
                border-top: solid 1px #b8bfce;
                li {
                    //margin-bottom: 24px;
                    //padding-bottom: 24px;
                    //border-bottom: solid 1px #b8bfce;
                }
            }
        }//.item
    }//.menu
}//.mod_newgrads_top


.recruit_list {
    //margin: 60px 0;
    //border-top: solid 1px #b8bfce;
    .item {
        //padding: 24px 0;
        //border-bottom: solid 1px #b8bfce;
        //display: flex;
        flex-wrap: wrap;
        //flex-direction: row;
        //justify-content: space-between;
        .image {
            width: 30%;
            img {
                width: 100%;
            }
        }//.image
        .detail {
            width: 65%;
            .title {
                //margin-bottom: 5px;
                //color: #003894;
                //font-weight: bold;
                font-size: 14px;
                .type {
                    display: block;
                    //border: solid 1px #b1cfff;
                    //border-radius: 4px;
                    //width: 100px;
                    //background-color: #b1cfff;
                    //font-size: 10px;
                    //text-align: center;
                    &.mod_parttime {
                        //background-color: #fff;
                    }
                }//.type
            }//.title
            .data {
                //display: flex;
                //flex-direction: row;
                //justify-content: flex-start;
                dl {
                    //width: 230px;
                    dt {
                        //color: #003894;
                        //font-weight: normal;
                        //font-size: 10px;
                    }// dt
                    dd {
                        font-size: 12px;
                    }// dd
                }// dl
            }//.data
            .tags {
                //margin-bottom: 10px;
                li {
                    //padding: 0 5px;
                    //border: solid 1px #f2f3f8;
                    //border-radius: 4px;
                    //display: inline-block;
                    //font-size: 10px;
                }// li
            }//.tags
            .date {
                margin-top: 5px;
                //font-size: 10px;
            }//.date
        }//.detail
        .to_detail_post {
            width: 100%;
            //display: flex;
            //flex-direction: column;
            //justify-content: center;
            a {
                //color: #003894;
                //font-weight: bold;
                //font-size: 16px;
                //text-decoration: none;
                text-align: right;
                &:hover {
                    //text-decoration: underline;
                }
            }
        }//.to_detail_post
    }
}//.recruit_list

.to_recruit_list {
    //margin: 0 auto 120px;
    //width: 272px;
    //height: 64px;
    a {
        //border: solid 1px #003894;
        //border-radius: 4px;
        //display: block;
        //width: 100%;
        //height: 100%;
        //line-height: 64px;
        //color: #003894;
        //font-weight: bold;
        //font-size: 16px;
        //text-align: center;
        //text-decoration: none;
    }
}//.to_recruit_list
