// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

//  ::::::::  :::::::::: :::::::::: ::::::::::: ::::::::  ::::::::::
// :+:    :+: :+:        :+:            :+:    :+:    :+: :+:
// +:+    +:+ +:+        +:+            +:+    +:+        +:+
// +#+    +:+ :#::+::#   :#::+::#       +#+    +#+        +#++:++#
// +#+    +#+ +#+        +#+            +#+    +#+        +#+
// #+#    #+# #+#        #+#            #+#    #+#    #+# #+#
//  ########  ###        ###        ########### ########  ##########




.mod_pickup {
    margin-bottom: 120px;
    .inner {
        padding: 25px 0;
        position: relative;
        &::after {
            box-sizing: border-box;
            border-left: solid 24px #003894;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            content: '';
            background: #ddd;
        }
    }
    .btn_office_list {
        margin-top: 24px;
        a {
            border-radius: 4px;
            display: block;
            line-height: 48px;
            color: #fff;
            background: #06173c;
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
        }
    }
}//.mod_pickup

.mod_office_concept {
    box-sizing: border-box;
    margin-bottom: 120px;
    padding: 60px 0;
    border-right: solid 60px #fff;
    background: linear-gradient(to left, #f7f7f7 0%, #f2f3f8 100%) !important;
    .inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .map {
        flex-shrink: 1;
        width: 520px;
    }
    .body {
        flex-shrink: 1;
        width: 540px;
        .heading02 {
            margin-bottom: 60px;
        }
        .details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .item {
                padding-top: 24px;
                position: relative;
                width: 45%;
                &::before {
                    box-sizing: border-box;
                    border-left: solid 24px #003894;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    content: '';
                    background: #ddd;
                }
                p {
                    font-size: 14px;
                    em {
                        margin-bottom: 30px;
                        display: block;
                        font-weight: bold;
                        font-size: 24px;
                    }
                }
            }
        }
    }
}//.mod_office_concept

.estate-list.mod_building {
    margin-bottom: 120px;
    .heading02 {
        margin-bottom: 60px;
    }
    ul {
        li {
            margin-right: 23px;
            width: 204px;
            &:nth-child(5n) {
                margin-right: 0;
            }
        }// li
    }// ul
}//.estate-list.mod_overseas

.office_data {
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
        padding-top: 15px;
        border-top: solid 1px #b8bfce;
        width: 32%;
        .location,
        .station {
            margin-bottom: 15px;
            padding-left: 2em;
            text-indent: -2em;
            img {
                display: block;
            }
        }
    }//.item
    dl {
        dt {
            color: #003894;
            font-weight: normal;
            font-size: 10px;
        }// dt
        dd {
            margin-bottom: 15px;
            font-size: 16px;
        }// dd
    }// dl
}//.office_data

.vr_image {
    margin: 0 auto 60px;
    width: 1120px;
    height: 377px;
    .ricoh-theta-tour-image,
    iframe {
        width: 1120px !important;
        height: 377px !important;
    }
}//.vr_image




//  ::::::::  ::::::::::     :::     :::::::::   ::::::::  :::    :::
// :+:    :+: :+:          :+: :+:   :+:    :+: :+:    :+: :+:    :+:
// +:+        +:+         +:+   +:+  +:+    +:+ +:+        +:+    +:+
// +#++:++#++ +#++:++#   +#++:++#++: +#++:++#:  +#+        +#++:++#++
//        +#+ +#+        +#+     +#+ +#+    +#+ +#+        +#+    +#+
// #+#    #+# #+#        #+#     #+# #+#    #+# #+#    #+# #+#    #+#
//  ########  ########## ###     ### ###    ###  ########  ###    ###
.search_lead {
    box-sizing: border-box;
    margin: -120px auto 60px;
    padding: 40px 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1280px;
    background: #f2f3f8;
    p {
        width: 50%;
        font-weight: bold;
        font-size: 24px;
    }
}//.search_lead
.search_localmenu {
    margin-bottom: 80px;
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            width: 24%;
            height: 80px;
            line-height: 80px;
            font-weight: bold;
            font-size: 18px;
            a {
                box-sizing: border-box;
                padding: 0 25px;
                border-radius: 4px;
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                height: 100%;
                color: #fff;
                background: #06173c;
                text-decoration: none;
                .mark_link {
                    position: absolute;
                    top: 24px;
                    right: 10px;
                }//.mark_link
            }// a
        }// li
    }// ul
}//.search_localmenu

.area_list {
    padding: 24px 0 0;
    display: flex;
    position: relative;
    justify-content: space-between;
    &::before {
        box-sizing: border-box;
        border-left: solid 24px #003894;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background: #ddd;
    }
    .area_heading {
        width: 297px;
        font-weight: bold;
        font-size: 30px;
    }
    .search_list {
        width: 825px;
        .item {
            margin-bottom: 24px;
            padding-top: 24px;
            border-top: solid 1px #b8bfce;
            display: flex;
            justify-content: space-between;
            &:first-child {
                padding-top: 0;
                border: 0;
            }
            &.mod_noitem {
                padding: 50px 0;
                justify-content: center;
                color: #ccc;
                font-size: 24px;
                text-align: center;
            }
            .image {
                width: 184px;
                img {
                    width: 100%;
                }
            }
            .body {
                width: 620px;
                .name {
                    color: #003894;
                    font-weight: bold;
                    font-size: 24px;
                }
                .lead {
                    color: #003894;
                    font-size: 14px;
                }
                .data {
                    padding-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    dl {
                        width: 300px;
                    }
                    dt {
                        color: #003894;
                        font-size: 10px;
                    }
                    dd {
                        margin-bottom: 10px;
                        font-size: 14px;
                    }
                }//.data
            }//.body
            .menu {
                ul {
                    text-align: right;
                    li {
                        margin-left: 30px;
                        display: inline-block;
                        a {
                            color: #003894;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }//.item
    }//.search_list
}//.area_list
