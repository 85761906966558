//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

// :::::::::  :::::::::: :::::::: ::::::::::: ::::::::  ::::    :::
// :+:    :+: :+:       :+:    :+:    :+:    :+:    :+: :+:+:   :+:
// +:+    +:+ +:+       +:+           +:+    +:+        :+:+:+  +:+
// +#+    +:+ +#++:++#  +#++:++#++    +#+    :#:        +#+ +:+ +#+
// +#+    +#+ +#+              +#+    +#+    +#+   +#+# +#+  +#+#+#
// #+#    #+# #+#       #+#    #+#    #+#    #+#    #+# #+#   #+#+#
// #########  ########## ######## ########### ########  ###    ####




.design_lead {
    //margin-bottom: 80px;
    //display: flex;
    //flex-direction: row;
    //flex-wrap: wrap;
    //justify-content: space-between;
    .body {
        margin-bottom: 30px;
        width: 100%;
        h3 {
            //margin-bottom: 24px;
            //ont-size: 30px;
        }
    }//.body
    .image {
        width: 100%;
        img {
            //width: 100%;
        }
    }
}//.design_lead

.mod_design_flow {
    margin: 0 20px 60px;
    //box-sizing: border-box;
    //margin-bottom: 120px;
    //padding: 0;
    border-left: 0;
    //background: linear-gradient(to left, #f7f7f7 0%, #f2f3f8 100%) !important;
    .inner {
        padding: 0 20px 20px;
        display: block;
        //flex-direction: row;
        //flex-wrap: wrap;
        //justify-content: space-around;
        height: auto;
    }
    .image {
        margin-bottom: 24px;
        width: 100%;
        img {
            margin-top: -15px;
            width: 100%;
        }
    }
    .body {
        //box-sizing: border-box;
        padding: 0;
        //flex-shrink: 1;
        width: 100%;
        .heading02 {
            margin-bottom: 20px;
        }
        p {
            strong {
                font-size: 18px;
            }
        }// p
    }//.body
}//.mod_design_flow

.mod_design_concept {
    .header {
        display: block;
        //flex-direction: row;
        //justify-content: space-between;
        .heading02 {
            //width: 215px;
            margin-bottom: 24px;
        }
        .note {
            width: 100%;
            //font-weight: bold;
            font-size: 24px;
        }
    }//.header
    .category {
        //padding-top: 40px;
        .cat_name {
            margin-bottom: -35px;
            //position: relative;
            //z-index: 30;
            //color: #202020;
            //font-family: $cgaramond;
            //font-weight: normal;
            font-size: 80px;

            //opacity: .3;
        }//.cat_name
        .lead {
            //text-align: right;
        }
        .image {
            margin: 0 -20px;
            display: flex;
            flex-direction: column-reverse;
            width: calc(100% + 40px);
            figcaption {
                padding: 0 20px;
                text-align: left;
            }
            img {
                margin-bottom: 5px;
                width: 100%;
            }
        }//.image
        .images {
            display: block;
            //flex-direction: row;
            //flex-wrap: wrap;
            //justify-content: space-between;
            li {
                //position: relative;
                //z-index: 29;
                margin: 0 -20px 4px;
                width: calc(100% + 40px);
                img {
                    width: 100%;
                }
                .label {
                    //box-sizing: border-box;
                    //padding: 20px 16px;
                    //display: flex;
                    //position: absolute;
                    //top: 0;
                    //left: 0;
                    //flex-direction: column;
                    //justify-content: flex-end;
                    //align-items: flex-start;
                    //width: 100%;
                    //height: 100%;
                    //color: #fff;
                    em {
                        //display: block;
                        //font-family: $cgaramond;
                        //font-weight: normal;
                        //font-size: 30px;
                    }
                    small {
                        //display: block;
                        //font-size: 10px;
                    }
                }
            }
        }//.images
        &.mod_spfix {
            display: flex;
            flex-direction: column;
            .cat_name {
                order: 1;
            }
            .lead {
                order: 3;
                text-align: left;
            }
            .images {
                order: 2;
            }
        }//&.mod_spfix
    }//.category
}//.mod_design_concept
