// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

//  ::::::::   ::::::::  ::::    ::::  :::::::::     :::     ::::    ::: :::   :::
// :+:    :+: :+:    :+: +:+:+: :+:+:+ :+:    :+:  :+: :+:   :+:+:   :+: :+:   :+:
// +:+        +:+    +:+ +:+ +:+:+ +:+ +:+    +:+ +:+   +:+  :+:+:+  +:+  +:+ +:+
// +#+        +#+    +:+ +#+  +:+  +#+ +#++:++#+ +#++:++#++: +#+ +:+ +#+   +#++:
// +#+        +#+    +#+ +#+       +#+ +#+       +#+     +#+ +#+  +#+#+#    +#+
// #+#    #+# #+#    #+# #+#       #+# #+#       #+#     #+# #+#   #+#+#    #+#
//  ########   ########  ###       ### ###       ###     ### ###    ####    ###




.company_top {
    margin-bottom: 120px;
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    li {
        margin-bottom: 10px;
        height: 180px;
        a {
            box-sizing: border-box;
            padding: 25px;
            border-radius: 4px;
            display: block;
            width: 100%;
            height: 100%;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            text-decoration: none;
        }
        &.lnv01 {
            width: 556px;
            a {
                background: center no-repeat url(../img/company/company-nav01.jpg);
            }
        }//&.lnv01
        &.lnv02 {
            width: 556px;
            a {
                background: center no-repeat url(../img/company/company-nav02.jpg);
            }
        }//&.lnv02
        &.lnv03 {
            width: 368px;
            a {
                background: center no-repeat url(../img/company/company-nav03.jpg);
            }
        }//&.lnv03
        &.lnv04 {
            width: 368px;
            a {
                background: center no-repeat url(../img/company/company-nav04.jpg);
            }
        }//&.lnv04
        &.lnv05 {
            width: 368px;
            a {
                background: center no-repeat url(../img/company/company-nav05.jpg);
            }
        }//&.lnv05
    }
}//.company_top

.corp_info {
    margin-bottom: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    table {
        width: 760px;
        tr {
            border-bottom: solid 1px #b8bfce;
            th,
            td {
                padding: 12px 0;
                line-height: 2em;
                font-size: 16px;
                text-align: left;
            }
            th {
                width: 120px;
                font-weight: normal;
            }
            td {
                // font-size: 16px;
            }
        }
        thead {
            th {
                font-weight: bold;
                font-size: 18px;
                text-align: left;
            }
        }// thead
    }// table
    .image {
        width: 300px;
        img {
            margin-bottom: 40px;
        }
    }//.image
    .btn_to_figures {
        height: 64px;
        a {
            border: solid 1px #003894;
            border-radius: 4px;
            display: block;
            width: 100%;
            height: 100%;
            line-height: 64px;
            color: #003894;
            font-weight: bold;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
        }
    }//.btn_to_figures
}//.corp_info


.mod_corp_accsess {
    box-sizing: border-box;
    padding: 0;
    border-left: solid 140px #fff;
    background: linear-gradient(to left, #f7f7f7 0%, #f2f3f8 100%) !important;
    .inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1260px;
    }
    .body {
        box-sizing: border-box;
        padding: 60px 0 60px 60px;
        flex-shrink: 1;
        width: 460px;
        .heading02 {
            margin-bottom: 60px;
        }
        p {
            margin-bottom: 50px;
            &:last-child {
                margin-bottom: 0;
            }
            small {
                display: block;
                font-size: 16px;
            }
            em {
                display: block;
                font-weight: bold;
                font-size: 24px;
            }
        }// p
    }//.body
    .map {
        position: relative;
        flex-shrink: 1;
        width: 800px;
        height: 440px;
        iframe {
            pointer-events: none;
        }
        a {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 21;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0);
            text-indent: -9999px;
            transition-duration: .1s;
            &:hover {
                background: rgba(255,255,255,.3);
            }
        }
        &::after {
            margin-top: -42px;
            margin-left: -35px;
            display: block;
            position: absolute;
            top: 58.7%;
            left: 56.5%;
            z-index: 20;
            width: 70px;
            height: 84px;
            content: '';
            background: url(../img/company/company-map_pin.png);
        }
    }//.map
}//.mod_corp_accsess

.mod_disclosure {
    padding: 120px 0;
    .inner {
        display: flex;
        justify-content: space-between;
    }
    .heading03 {
        width: 205px;
    }
    .body {
        p {
            font-size: 16px;
            em {
                display: block;
                font-weight: bold;
                font-size: 18px;
            }
            a {
                color: #202020;
            }
        }// p
    }//.body
}//.mod_disclosure
