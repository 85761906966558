//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

// :::::::::  :::::::::: :::::::: ::::::::::: :::::::::  :::::::::: ::::    :::  ::::::::  ::::::::::      ::::    ::::   ::::::::
// :+:    :+: :+:       :+:    :+:    :+:     :+:    :+: :+:        :+:+:   :+: :+:    :+: :+:             +:+:+: :+:+:+ :+:    :+:
// +:+    +:+ +:+       +:+           +:+     +:+    +:+ +:+        :+:+:+  +:+ +:+        +:+             +:+ +:+:+ +:+ +:+
// +#++:++#:  +#++:++#  +#++:++#++    +#+     +#+    +:+ +#++:++#   +#+ +:+ +#+ +#+        +#++:++#        +#+  +:+  +#+ :#:
// +#+    +#+ +#+              +#+    +#+     +#+    +#+ +#+        +#+  +#+#+# +#+        +#+             +#+       +#+ +#+   +#+#
// #+#    #+# #+#       #+#    #+#    #+#     #+#    #+# #+#        #+#   #+#+# #+#    #+# #+#             #+#       #+# #+#    #+#
// ###    ### ########## ######## ########### #########  ########## ###    ####  ########  ##########      ###       ###  ########
.residence_mg_lnav {
    margin-bottom: 60px;
    h3 {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
    }
    ul {
        width: 100%;
    }
    li {
        margin-bottom: 10px;
        width: 100%;
        a {
            box-sizing: border-box;
            border-radius: 4px;
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            color: #fff;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            font-weight: bold;
            font-size: 18px;
            text-decoration: none;
            transition-duration: .1s;
            .label {
                box-sizing: border-box;
                padding: 20px 18% 20px 20px;
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                line-height: 1.4em;
                background: linear-gradient(to bottom, rgba(4,24,51,1) 0%,rgba(4,24,51,0) 100%);
                font-weight: normal;
                font-size: 14px;
                em {
                    margin-bottom: 10px;
                    display: block;
                    flex-basis: 1px;
                    font-weight: bold;
                    font-size: 16px;
                }
                small {
                    display: block;
                    line-height: 1.5;
                    font-size: 14px;
                }
                .mark_link {
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    right: 18px;
                    transform: translateY(-50%);
                    &::after {
                        top: 11px;
                        left: 13px;
                        transform: rotate(45deg);
                    }
                }
            }//.label
            &:hover {
                background-size: 110%;
            }
        }
        &.lnv01 {
            a {
                background-image: url(../img/business/residence_mg-lnav_bg01.jpg);
            }
        }//&.lnv01
        &.lnv02 {
            a {
                background-image: url(../img/business/residence_mg-lnav_bg02.jpg);
            }
        }//&.lnv02
        &.lnv03 {
            a {
                background-image: url(../img/business/residence_mg-lnav_bg03.jpg);
            }
        }//&.lnv03
    }
}//.residence_mg_lnav


.residence_mg_category {
    margin-bottom: 60px;
    .outer_header {
        position: relative;
        z-index: 10;
        .inner {
            box-sizing: border-box;
            padding-top: 25px;
            position: relative;
            height: 163px;
            &::before {
                display: block;
                position: absolute;
                top: 0;
                right: auto;
                left: -20px;
                z-index: 9;
                width: 100%;
                height: 100%;
                content: '';
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            .heading {
                position: relative;
                z-index: 10;
                em {
                    display: block;
                    color: rgba(242,243,248,.2);
                    font-family: $cgaramond;
                    font-weight: normal;
                    font-size: 36px;
                }
                small {
                    display: block;
                    color: #fff;
                    font-weight: bold;
                    font-size: 24px;
                }
            }//.heading
        }//.inner
    }//.outer_header
    &#nv01 {
        .outer_header .inner::before {
            background-image: url(../img/business/residence_mg-header_bg01_sp.jpg);
        }
    }
    &#nv02 {
        .outer_header .inner::before {
            background-image: url(../img/business/residence_mg-header_bg02_sp.jpg);
        }
    }
    &#nv03 {
        .outer_header .inner::before {
            background-image: url(../img/business/residence_mg-header_bg03_sp.jpg);
        }
    }
    & > .inner {
        margin: -36px 0 0 auto;
        padding: 0;
        border: solid 1px #b8bfce;
        border-radius: 4px;
        position: relative;
        z-index: 11;
        width: calc(100% - 20px);
        overflow: hidden;
        // transform: translateX(40px);
        .heading02 {
            margin-bottom: 25px;
            padding-left: 20px;
        }
    }//& > .inner
    .pre_content {
        padding: 20px;
        background: #f2f3f8;
    }
    .main_content {
        padding: 40px 20px 20px;
        background: #fff;
        .content {
            &:first-child {
                margin-bottom: 40px;
                padding-bottom: 40px;
                border-bottom: solid 1px #b8bfce;
            }
        }//.content
        .estate_slider {
            margin-right: -20px;
            padding-bottom: 5px;
            position: relative;
            overflow: visible;
            &:after {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 50;
                width: 20px;
                height: 100%;
                content: '';
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&0+0,0.84+50,1+100 */
                background:    -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,.84) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,.84) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background:         linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,.84) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            }
            .item {
                margin: 0 5px;
                padding: 0;
                figure {
                    margin-bottom: 5px;
                    border-radius: 4px;
                    overflow: hidden;
                }
                .item_title {
                    em {
                        font-size: 14px;
                    }
                }
            }
            .slick-prev {
                z-index: 50;
                background: linear-gradient(to left, rgba(184,191,206,0) 0%,rgba(184,191,206,1) 30%,rgba(184,191,206,1) 100%) !important;
            }
            .slick-next {
                z-index: 50;
                background: linear-gradient(to right, rgba(184,191,206,0) 0%,rgba(184,191,206,1) 30%,rgba(184,191,206,1) 100%) !important;
            }
        }
    }//.main_content
    .achieve_count {
        position: relative;
        .heading02 {
            position: relative;
            z-index: 11;
        }
        ul {
            display: flex;
            position: relative;
            z-index: 11;
            justify-content: space-between;
            li {
                width: 47%;
                .label {
                    margin-bottom: 5px;
                    padding-left: 10px;
                    border-left: solid 4px #003894;
                    display: block;
                    font-size: 12px;
                }
                em {
                    display: block;
                    width: 100%;
                    color: #003894;
                    font-weight: bold;
                    font-size: 36px;
                    text-align: right;
                }
                small {
                    display: block;
                    width: 100%;
                    font-size: 10px;
                    text-align: right;
                }
            }
        }
    }//.achieve_count
    .flow_list {
        ol {
            li {
                margin-top: 40px;
                display: flex;
                position: relative;
                flex-direction: row;
                justify-content: flex-start;
                &::before {
                    display: block;
                    position: absolute;
                    top: -40px;
                    left: 37px;
                    width: 2px;
                    height: 41px;
                    content: '';
                    background: #003894;
                }
                &:first-child {
                    margin-top: 0;
                    &::before {
                        display: none;
                    }
                }
                span {
                    box-sizing: border-box;
                    margin-right: 10px;
                    border: solid 1px #003894;
                    border-radius: 50%;
                    display: block;
                    flex-shrink: 0;
                    width: 74px;
                    height: 74px;
                    line-height: 68px;
                    color: #003894;
                    background: #fff;
                    font-family: $cgaramond;
                    font-size: 18px;
                    text-align: center;
                }// span
                em {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    font-weight: bold;
                    font-size: 16px;
                }
            }// li
        }
    }//.flow_list
    .service_list {
        width: 100%;
        li {
            padding-top: 20px;
            position: relative;
            width: 100%;
            &::before {
                box-sizing: border-box;
                border-left: solid 24px #003894;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                content: '';
                background: #ddd;
            }
            &:last-of-type {
                span {
                    margin-bottom: 0;
                }
            }
            em {
                margin-bottom: 10px;
                display: block;
                font-weight: bold;
                font-size: 18px;
            }
            span {
                margin-bottom: 24px;
                display: block;
                font-size: 14px;
                &.space-b {
                    margin-bottom: 10px;
                }
            }
            a {
                margin-bottom: 24px;
                display: block;
                color: #003894;
                font-weight: bold;
                font-size: 14px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }// li
    }//.service_list
    .achieve_list {
        ul {
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                margin-bottom: 15px;
                width: 48.5%;
                img {
                    margin-bottom: 5px;
                    display: block;
                    width: 100%;
                }
                .text {
                    display: block;
                    line-height: 1.5;
                    font-weight: bold;
                    font-size: 14px;
                }
            }// li
        }// ul
        .other_achieve {
            h4 {
                margin-bottom: 10px;
                padding-left: 10px;
                border-left: solid 4px #003894;
                font-weight: normal;
                font-size: 12px;
            }
            p {
                font-weight: bold;
                font-size: 18px;
            }
        }//.other_achieve
    }//.achieve_list
}//.residence_mg_category


.residence_mg {
    .slick-dots {
        margin-left: -20px;
        li {
            margin: 0;
            width: 16px;
            button {
                padding: 5px 0;
                width: 16px;
                &:before {
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 8px;
                    height: 8px;
                    background: #003894;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
            &.slick-active {
                button {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .estate_slider {
        .slide_item {
            margin: 0 5px;
            border-radius: 0;
        }
        .photo {
            img {
                border-radius: 0;
            }
        }
        .detail {
            .name {
                em {
                    font-size: 16px;
                }
            }
            .data {
                dd {
                    font-size: 12px;
                }
            }
            .to_detail {
                font-size: 14px;
            }
        }
    }
}
