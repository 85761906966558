@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
    //background: #fff url('./ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face {
    //font-family: 'slick';
    //font-style: normal;
    //font-weight: normal;

    //src: url('./fonts/slick.eot');
    //src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
    padding: 0;
    border: none;
    display: block;
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    line-height: 0;
    color: transparent;
    background: transparent;
    font-size: 0;
    cursor: pointer;
    outline: none;
            transform: translate(0, -50%);

    -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    background: transparent;
    outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    line-height: 1;
    color: white;
    font-family: 'slick';
    font-size: 20px;

    opacity: .75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    content: '←';
}
[dir='rtl'] .slick-prev:before {
    content: '→';
}

.slick-next {
    right: -25px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
.slick-next:before {
    content: '→';
}
[dir='rtl'] .slick-next:before {
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    margin: 0;
    padding: 0;
    display: block;
    position: absolute;
    bottom: -25px;
    width: 100%;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    margin: 0 5px;
    padding: 0;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.slick-dots li button {
    padding: 5px;
    border: 0;
    display: block;
    width: 20px;
    height: 20px;
    line-height: 0;
    color: transparent;
    background: transparent;
    font-size: 0;
    cursor: pointer;
    outline: none;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    line-height: 20px;
    content: '•';
    color: black;
    font-family: 'slick';
    font-size: 6px;
    text-align: center;

    opacity: .25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    color: black;

    opacity: .75;
}
