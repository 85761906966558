// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

// :::::::::  :::::::::: :::::::: ::::::::::: ::::::::  ::::    :::
// :+:    :+: :+:       :+:    :+:    :+:    :+:    :+: :+:+:   :+:
// +:+    +:+ +:+       +:+           +:+    +:+        :+:+:+  +:+
// +#+    +:+ +#++:++#  +#++:++#++    +#+    :#:        +#+ +:+ +#+
// +#+    +#+ +#+              +#+    +#+    +#+   +#+# +#+  +#+#+#
// #+#    #+# #+#       #+#    #+#    #+#    #+#    #+# #+#   #+#+#
// #########  ########## ######## ########### ########  ###    ####




.design_lead {
    margin-bottom: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .body {
        width: 630px;
        h3 {
            margin-bottom: 24px;
            font-size: 30px;
        }
    }//.body
    .image {
        width: 390px;
        img {
            width: 100%;
        }
    }
}//.design_lead

.mod_design_flow {
    box-sizing: border-box;
    margin-bottom: 120px;
    padding: 0;
    border-left: solid 140px #fff;
    background: linear-gradient(to left, #f7f7f7 0%, #f2f3f8 100%) !important;
    .inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        height: 380px;
    }
    .image {
        margin-top: -10px;
        width: 400px;
    }
    .body {
        box-sizing: border-box;
        padding: 60px 0 60px 60px;
        flex-shrink: 1;
        width: 460px;
        .heading02 {
            margin-bottom: 40px;
        }
        p {
            strong {
                font-size: 24px;
            }
        }// p
    }//.body
}//.mod_design_flow

.mod_design_concept {
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .heading02 {
            width: 215px;
        }
        .note {
            width: 905px;
            font-weight: bold;
            font-size: 30px;
        }
    }//.header
    .category {
        padding-top: 40px;
        .cat_name {
            margin-bottom: -83px;
            position: relative;
            z-index: 30;
            color: #202020;
            font-family: $cgaramond;
            font-weight: normal;
            font-size: 120px;

            opacity: .3;
        }//.cat_name
        .lead {
            text-align: right;
        }
        .images {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                position: relative;
                z-index: 29;
                width: 33%;
                img {
                    width: 100%;
                }
                .label {
                    box-sizing: border-box;
                    padding: 20px 16px;
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    em {
                        display: block;
                        font-family: $cgaramond;
                        font-weight: normal;
                        font-size: 30px;
                    }
                    small {
                        display: block;
                        font-size: 10px;
                    }
                }
            }
        }//.images
    }//.category
}//.mod_design_concept
