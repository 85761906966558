.mainvisual {
    margin-bottom: 25px;
}

.detail .inner {
    padding-bottom: 30px;
    overflow: hidden;
}

.mainvisual-text {
    margin: 10px 0;
    line-height: 1.5;
    color: #0086d1;
    font-style: oblique;
    text-align: center;

    font-feature-settings: 'palt';
}

.mainvisual .js-slider .text-wrap {
    margin-top: 10px;
    line-height: 1.57;
    font-size: 87.5%;
}

.js-slider-pc-none .slick-list li,
.js-slider .slick-list li {
    padding: 0 8px;
}

.slick-dots {
    margin: 0;
    bottom: -30px;
    line-height: 1;
    font-size: 0;
    text-align: center;
    letter-spacing: -.4px;
}

.slick-dots li {
    display: inline-block;
    letter-spacing: normal;
}

.slick-dots button {
    padding: 0;
    border: 0 none;
    position: relative;
    width: 20px;
    height: 35px;
    overflow: hidden;
    background: none;
    text-indent: -9999px;
}

.js-slider-main .slick-dots button:after {
    margin: auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: '';
    background: #fff;
}
.js-slider-sub .slick-dots button:after {
    margin: auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: '';
    background: #f3f3f3;
}

.slick-dots li button {
    padding: 5px;
    border: 0;
    display: block;
    width: 20px;
    height: 30px;
    line-height: 0;
    color: transparent;
    background: transparent;
    font-size: 0;
    cursor: pointer;
    outline: none;
}

.slick-dots .slick-active button:after {
    border-radius: 0;
    width: 18px;
    height: 26px;
    background: url(/img/wordpress/logo.png) 0 0 no-repeat;
    background-size: auto 24px;
}

.mainvisual {
    margin-bottom: 25px;
    position: relative;
}
.mainvisual .js-slider-sub {
    margin: 0 auto;
    width: 70%;
}
.mainvisual .js-slider-sub .slick-list {
    overflow: visible;
}
.mainvisual .js-slider-sub li.slick-slide {
    /* width: 950px; */
}
.mainvisual .js-slider-sub li.slick-slide .mainvisual-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: row-reverse;
    align-items: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
}
.mainvisual .js-slider-sub li.slick-slide figure {
    width: 100%;
}
.mainvisual .js-slider-sub .slick-prev,
.mainvisual .js-slider-sub .slick-next {
    width: 15%;
    height: 102%;
    background: #fff;

    opacity: .6;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
}
.js-slider-sub {
    position: relative !important;
}
.js-slider-sub .slick-list {
    padding-bottom: 0;
    overflow: hidden;
}
.js-slider-sub .slick-arrow {
    display: none !important;
}
.js-slider-sub .slick-dots {
    margin: 0 auto;
    position: absolute;
    right: 0;
    left: 0;
    height: 65px;
}
.js-slider-sub .slick-slide img {
    display: inline-block;
    max-width: 100%;
    max-height: 640px;
}
.js-slider-sub .slick-slide figure {
    text-align: center;
}
.mainvisual-text {
    margin: 10px 0;
    width: 460px;
    line-height: 1.5;
    color: #0086d1;
    font-style: oblique;
    text-align: center;

    font-feature-settings: 'palt';
}
.mainvisual .mainvisual-box img {
    margin: 0 auto;
    width: 100%;
}
.js-slider .slick-list li {
    padding: 0 20px;
}
.js-slider .slick-prev,
.js-slider .slick-next {
    width: calc((100vw - 640px) / 2);
    height: 449px;
}
.js-slider .slick-prev:after {
    right: 75px;
}
.js-slider .slick-next:after {
    left: 75px;
}
.slick-slider {
    position: static;
}
.slick-slider div {
    transition: none;
}
.slick-slide {
            transition: opacity .3s ease;

    -webkit-transition: opacity .3s ease;
}
/* .slick-slide:not(.slick-center) {
  opacity: .4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
} */
.slick-list {
    position: static;
    overflow: visible;
}
.slick-prev,
.slick-next {
    border: 0 none;
    top: 0;
    z-index: 1;
    width: 97px;
    height: 100%;
    overflow: hidden;
    background: none;
    font-size: 0;
    text-indent: -9999px;
    cursor: pointer;
}
.slick-prev:after,
.slick-next:after {
    top: 50%;
            transform: translateY(-50%);

    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
}
.slick-prev {
    padding: 0;
    padding-left: 46px;
    position: relative;
    position: absolute;
    top: 50%;
    left: 0;
}
.slick-prev:after {
    margin: auto;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4%;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    content: '';
            transform: rotate(135deg);

    -webkit-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
}
.slick-prev:after {
    right: 47px;
    left: auto;
}
.slick-prev:before {
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    right: 42px;
    bottom: 0;
    width: 40px;
    height: 50px;
    background: #01627a;
}
.slick-next:after
.slick-prev:before {
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    right: 30px;
    bottom: 0;
    width: 40px;
    height: 50px;
    background: #01627a;
}
.slick-next {
    padding: 0;
    padding-right: 46px;
    position: relative;
    position: absolute;
    top: 50%;
    right: 0;
}
.slick-next:after {
    margin: auto;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    display: block;
    position: absolute;
    top: 0;
    right: 4%;
    bottom: 0;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    content: '';
            transform: rotate(-45deg);

    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
}
.slick-next:before {
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 43px;
    width: 40px;
    height: 50px;
    background: #01627a;
}
.slick-next:after {
    right: auto;
    left: 47px;
}
/* 1件の場合はスライド非表示 */
.slick-dots > li:first-child:last-child {
    display: none;
}

@media screen and (min-width: 768px), print {
    .mainvisual {
        margin-bottom: 25px;
        position: relative;
    }
    .mainvisual .js-slider-main {
        margin: 0 auto;
        width: 70%;
    }
    .mainvisual .js-slider-main .slick-list {
        overflow: visible;
    }
    .mainvisual .js-slider-main li.slick-slide {
        /* width: 950px; */
    }
    .mainvisual .js-slider-main li.slick-slide .mainvisual-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
            flex-direction: row-reverse;
        align-items: center;

        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
    }
    .mainvisual .mainvisual-box img {
        width: 100%;
    }
    .mainvisual .js-slider-main li.slick-slide figure {
        width: 100%;
    }
    .mainvisual .js-slider-main .slick-prev,
    .mainvisual .js-slider-main .slick-next {
        width: 15%;
        height: 102%;
        background: #fff;

        opacity: .4;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)';
    }
    .mainvisual-text {
        margin: 10px 0;
        width: 460px;
        line-height: 1.5;
        color: #0086d1;
        font-style: oblique;
        text-align: center;

        font-feature-settings: 'palt';
    }
    .js-slider {
        margin: 0 auto;
        width: 680px;
    }
    .slick-dots li button {
        height: 35px;
    }
    .js-slider .slick-list li {
        padding: 0 20px;
    }
    .js-slider .slick-prev,
    .js-slider .slick-next {
        width: calc((100vw - 640px) / 2);
        height: 449px;
    }
    .js-slider .slick-prev:after {
        right: 75px;
    }
    .js-slider .slick-next:after {
        left: 75px;
    }
    .slick-slider {
        position: static;
    }
    .slick-slider div {
        transition: none;
    }
    .slick-slide {
                transition: opacity .3s ease;

        -webkit-transition: opacity .3s ease;
    }
    /* .slick-slide:not(.slick-center) {
    opacity: .4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  } */
    .slick-list {
        position: static;
        overflow: visible;
    }
    .slick-prev,
    .slick-next {
        border: 0 none;
        top: 0;
        z-index: 1;
        width: 97px;
        height: 100%;
        overflow: hidden;
        background: none;
        font-size: 0;
        text-indent: -9999px;
        cursor: pointer;
    }
    .slick-prev:after,
    .slick-next:after {
        top: 50%;
                transform: translateY(-50%);

        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
    }
    .slick-prev {
        padding: 0;
        padding-left: 46px;
        position: relative;
        position: absolute;
        top: 50%;
        left: 0;
    }
    .slick-prev:after {
        margin: auto;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 4%;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        content: '';
                transform: rotate(135deg);

        -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
    }
    .slick-prev:after {
        right: 10px;
        left: auto;
    }
    .slick-prev:before {
        margin: auto;
        display: block;
        position: absolute;
        top: 0;
        right: 7px;
        bottom: 0;
        width: 40px;
        height: 50px;
        background: #01627a;
    }
    .slick-next:after
    .slick-prev:before {
        margin: auto;
        display: block;
        position: absolute;
        top: 0;
        right: 7px;
        bottom: 0;
        width: 40px;
        height: 50px;
        background: #01627a;
    }
    .slick-next {
        padding: 0;
        padding-right: 46px;
        position: relative;
        position: absolute;
        top: 50%;
        right: 0;
    }
    .slick-next:after {
        margin: auto;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        display: block;
        position: absolute;
        top: 0;
        right: 4%;
        bottom: 0;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        content: '';
                transform: rotate(-45deg);

        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
        opacity: unset;
    }
    .slick-next:before {
        margin: auto;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 7px;
        width: 40px;
        height: 50px;
        background: #01627a;

        opacity: unset;
    }
    .slick-next:after {
        right: auto;
        left: 10px;
    }
    .slick-dots {
        margin: 5px 0 0;
        max-width: 70%;
    }
    .slick-dots button {
        margin: 0 4px;
        cursor: pointer;
    }
    .slick-dots button:hover {
                transition: opacity .3s ease;

        opacity: .7;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
        -webkit-transition: opacity .3s ease;
    }
    .slick-dots button:after {
        width: 11px;
        height: 11px;
    }
    .slick-dots .slick-active button:hover {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    .slick-dots .slick-active button:after {
        width: 26px;
        height: 26px;
        background-size: 16px auto;
    }
}
