//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

//  ::::::::  :::     ::: :::::::::: :::::::::   ::::::::  ::::::::::     :::      ::::::::
// :+:    :+: :+:     :+: :+:        :+:    :+: :+:    :+: :+:          :+: :+:   :+:    :+:
// +:+    +:+ +:+     +:+ +:+        +:+    +:+ +:+        +:+         +:+   +:+  +:+
// +#+    +:+ +#+     +:+ +#++:++#   +#++:++#:  +#++:++#++ +#++:++#   +#++:++#++: +#++:++#++
// +#+    +#+  +#+   +#+  +#+        +#+    +#+        +#+ +#+        +#+     +#+        +#+
// #+#    #+#   #+#+#+#   #+#        #+#    #+# #+#    #+# #+#        #+#     #+# #+#    #+#
//  ########      ###     ########## ###    ###  ########  ########## ###     ###  ########




.page-head.mod_overseas {
    //width: 100%;
    height: 440px;
    //background: center no-repeat url(../img/business/overseas-mv.jpg);
    //background-size: cover;
    .outer {
        //margin: 0 auto;
        width: 100%;
    }
    .inner {
        padding-top: 0;
    }
    & + .content_body {
        margin-top: -200px;
    }
}


.overseas_lead {
    margin-bottom: 60px;
    //position: relative;
    margin-left: 20px;
    height: auto;
    //color: #fff;
    .inner {
        padding: 20px;
        &::before {
            //display: block;
            //position: absolute;
            //top: 0;
            //left: 0;
            width: 100%;
            //height: 100%;
            //content: '';
            //background: 30% center no-repeat url(../img/business/overseas-lead_bg.jpg);
            background-size: 100% 100%;
        }
        .body {
            //display: flex;
            //position: relative;
            //flex-direction: row;
            //flex-wrap: wrap;
            //justify-content: space-between;
            h3 {
                margin-bottom: 24px;
                //flex-shrink: 1;
                width: 100%;
                //font-family: $cgaramond;
                //font-weight: normal;
                font-size: 30px;
            }
            .text {
                //flex-shrink: 1;
                width: 100%;
                line-height: 1.7em;
                font-size: 14px;
                small {
                    margin-top: 24px;
                    //display: block;
                    //line-height: 1.4em;
                    //font-size: 10px;
                }
            }
        }//.body
    }//.inner
    &.mod_ofxh {
        overflow-x: visible;
    }
}//.overseas_lead

.overseas_description {
    margin-bottom: 60px;
    //display: flex;
    //flex-direction: row;
    //flex-wrap: wrap;
    //justify-content: space-between;
    .body {
        margin-bottom: 40px;
        //display: flex;
        //flex-direction: column;
        //justify-content: flex-end;
        width: 100%;
        p {
            line-height: 1.7em;
            font-size: 14px;
        }
        .btn {
            margin-top: 30px;
            width: 100%;
            //height: 64px;
            a {
                //border: solid 1px #003894;
                //border-radius: 4px;
                //display: block;
                //width: 100%;
                //height: 100%;
                //line-height: 64px;
                //color: #003894;
                //font-weight: bold;
                //font-size: 16px;
                //text-align: center;
                //text-decoration: none;
            }
        }
    }//.body
    .image {
        //flex-shrink: 1;
        margin: 0 -20px;
        width: calc(100% + 40px);
        img {
            width: 100%;
        }
    }
    .heading02 {
        margin-bottom: 24px;
        font-size: 36px;
        small {
            //font: 16px;
        }
    }
}//.overseas_description

.estate-list.mod_overseas {
    .heading02 {
        margin-bottom: 24px;
    }
    ul {
        li {
            margin-right: 0;
            width: 100%;
            &:nth-child(3n) {
                //margin-right: 0;
            }
        }// li
    }// ul
}//.estate-list.mod_overseas

.slideshow {
    .fv-img {
        //margin-bottom: 40px;
        //display: flex;
        //flex-direction: row;
        //flex-wrap: wrap;
        width: 100%;
        height: auto;
        //background: #f2f3f8;
        .slide {
            //margin-bottom: 5px;
            //flex-shrink: 1;
            width: 100%;
            height: 55vw;
            overflow: hidden;
            .slide-view {
                //width: 100%;
                li {
                    //width: 100%;
                    img {
                        width: 100%;
                        height: 54vw;
                        object-fit: contain;
                    }
                }// li
            }//.slide-view
        }//.slide
        .thumbs {
            //box-sizing: border-box;
            padding: 10px;
            //flex-shrink: 1;
            width: 100%;
            height: 300px;
            //overflow: scroll !important;
            .slide-nav {
                //margin-left: -1.5%;
                //width: 100%;
                li {
                    //padding: 0 1%;
                    //width: 108px;
                    //height: 108px;
                    img {
                        //border-radius: 4px;
                        //width: 108px;
                        //height: 108px;
                        //object-fit: cover;
                    }
                }
            }//.slide-nav
            .slick-list {
                ////overflow: scroll !important;
            }
            .slick-track {
                //display: flex !important;
                //flex-wrap: wrap;
                //justify-content: flex-start;
                //width: 345px !important;
                //height: auto;
                //transform: translate3d(0,0,0) !important;
                .slick-slide {
                    //margin-bottom: 6px;
                    //padding: 0 3px !important;
                    //width: 108px !important;
                    //height: 108px !important;

                    //opacity: .5;
                    &.slick-current {
                        //opacity: 1;
                        img {
                            //border: solid 2px #003894;
                        }
                    }
                }
            }
        }//.thumbs
        .slick-prev,
        .slick-next {
            //display: none !important;
        }
    }//.fv-img
}//.slideshow
