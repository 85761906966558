// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

// :::    ::: :::::::::  :::::::::      :::     ::::    :::      :::::::::  :::::::::: :::     :::
// :+:    :+: :+:    :+: :+:    :+:   :+: :+:   :+:+:   :+:      :+:    :+: :+:        :+:     :+:
// +:+    +:+ +:+    +:+ +:+    +:+  +:+   +:+  :+:+:+  +:+      +:+    +:+ +:+        +:+     +:+
// +#+    +:+ +#++:++#:  +#++:++#+  +#++:++#++: +#+ +:+ +#+      +#+    +:+ +#++:++#   +#+     +:+
// +#+    +#+ +#+    +#+ +#+    +#+ +#+     +#+ +#+  +#+#+#      +#+    +#+ +#+         +#+   +#+
// #+#    #+# #+#    #+# #+#    #+# #+#     #+# #+#   #+#+#      #+#    #+# #+#          #+#+#+#
//  ########  ###    ### #########  ###     ### ###    ####      #########  ##########     ###




.urban_lead {
    margin-bottom: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .body {
        flex-shrink: 1;
        width: 640px;
        h3 {
            margin-bottom: 20px;
            line-height: 36px;
            font-size: 30px;
        }
        p {
            line-height: 2em;
            font-size: 16px;
        }
    }//.body
    .image {
        width: 361px;
    }//.image
}//.urban_lead


.achievement {
    margin-bottom: 120px;
    padding: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    background: #f7f7f7;
    .history_list {
        width: 685px;
        .sup_list {
            .sup_item {
                .year {
                    top: 10px;
                    font-size: 14px;
                }//.year
            }//.sup_item
        }//.sup_list
        .sub_list {
            li {
                border-top: 0;
                .month {
                    margin-right: 20px;
                    display: inline-block;
                    width: 35px;
                    text-align: right;
                }//.month
            }// li
        }//.sub_list
    }//.history_list
}//.achievement

.urban_solution {
    margin-bottom: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .heading02 {
        width: 295px;
    }//.heading02
    .list {
        border-bottom: solid 1px #b8bfce;
        width: 825px;
        .item {
            margin-top: 60px;
            padding-top: 60px;
            border-top: solid 1px #b8bfce;
            &:first-child {
                margin-top: 0;
                padding-top: 0;
                border: 0;
            }
            &:last-child {
                padding-bottom: 60px;
            }
            h4 {
                margin-bottom: 60px;
                .label {
                    margin-bottom: 20px;
                    padding-left: 10px;
                    border-left: solid 4px #003894;
                    display: block;
                    font-weight: normal;
                    font-size: 14px;
                }
                em {
                    display: block;
                    font-size: 24px;
                }
            }// h4
            .case_list {
                margin-top: 60px;
                padding: 10px 0;
                width: 100%;
                background: #f7f7f7;
                tr {
                    border-bottom: solid 1px #b8bfce;
                    th,
                    td {
                        padding: 24px;
                    }
                }// tr
                thead {
                    tr {
                        border-top: solid 1px #b8bfce;
                        th {
                            color: #003894;
                            font-family: $cgaramond;
                            font-size: 24px;
                            text-align: center;
                        }
                    }
                }// thead
                tbody {
                    tr {
                        th {
                            font-weight: bold;
                            font-size: 18px;
                            .cat {
                                margin-top: 20px;
                                border-radius: 24px;
                                display: block;
                                width: 112px;
                                line-height: 28px;
                                color: #003894;
                                background: #fff;
                                font-weight: normal;
                                font-size: 14px;
                                text-align: center;
                            }
                        }// th
                        td {
                            font-size: 14px;
                            em {
                                display: none;
                            }
                        }
                    }// tr
                }// tbody
            }//.case_list
            &.mod_col2 {
                display: flex;
                flex-direction: row;
                .header {
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 340px;
                    h4 {
                        margin-bottom: 0;
                    }
                    &::after {
                        box-sizing: border-box;
                        border-top: solid 20px transparent;
                        border-right: solid 40px transparent;
                        border-bottom: solid 20px transparent;
                        border-left: solid 40px #003894;
                        display: block;
                        position: absolute;
                        right: -10px;
                        bottom: 90px;
                        content: '';
                    }
                }//.header
                figcaption {
                    color: #202020;
                    font-family: $cgaramond;
                    font-size: 24px;
                }
                .after {
                    width: 485px;
                }//.after
            }//.mod_col2
        }//.item
    }//.list
}//.urban_solution

.land_app {
    .heading {
        padding: 80px 0;
        color: #fff;
        background: center no-repeat url(../img/business/urban-landapp_head.jpg);
        background-size: cover;
        h2 {
            margin-bottom: 20px;
            font-size: 30px;
        }
        p {
            font-size: 18px;
        }
    }//.heading
    & > .inner {
        padding-top: 60px;
    }
    .list {
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        li {
            padding-top: 24px;
            position: relative;
            width: 250px;
            &::before {
                box-sizing: border-box;
                border-left: solid 24px #003894;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                content: '';
                background: #ddd;
            }
            em {
                margin-bottom: 30px;
                display: block;
                font-weight: bold;
                font-size: 24px;
            }
            span {
                line-height: 1.7;
                font-size: 14px;
            }
        }// li
    }//.list
    .catch {
        margin-bottom: 60px;
        img {
            margin-bottom: 40px;
        }
        strong {
            margin-bottom: 30px;
            display: block;
            color: #003894;
            font-weight: bold;
            font-size: 36px;
            text-align: center;
            text-align: center;
        }
        .btn {
            margin: 0 auto;
            display: block;
            width: 518px;
            height: 72px;
            a {
                border-radius: 4px;
                display: block;
                line-height: 72px;
                color: #fff;
                background: #003894;
                font-weight: bold;
                font-size: 16px;
                text-align: center;
                text-decoration: none;
            }
        }//.btn
    }//.catch
}//.land_app

.poroperty_info {
    padding: 30px 0;
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &::before,
    &::after {
        box-sizing: border-box;
        border-left: solid 24px #003894;
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background: #ddd;
    }
    &::before {
        top: 0;
    }
    &::after {
        bottom: 0;
    }
    h3 {
        flex-shrink: 1;
        width: 24px;
        line-height: 1.1em;
        color: #003894;
        font-size: 24px;
    }
    .item {
        width: 488px;
        h4 {
            margin-bottom: 30px;
            font-weight: bold;
            font-size: 30px;
        }
        p {
            margin-bottom: 30px;
            font-size: 16px;
        }
        .conditions {
            padding: 21px 24px;
            background: #f7f7f7;
            h5 {
                margin-bottom: 15px;
                color: #003894;
                font-size: 18px;
            }
            ul {
                li {
                    margin-bottom: 8px;
                    font-size: 14px;
                    span {
                        margin-right: 10px;
                        border-radius: 22px;
                        display: inline-block;
                        width: 112px;
                        line-height: 22px;
                        color: #003894;
                        background: #fff;
                        text-align: center;
                    }
                }
            }
        }//.conditions
    }//.item
}//.poroperty_info
