// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

//  ::::::::  :::     ::: :::::::::: :::::::::   ::::::::  ::::::::::     :::      ::::::::
// :+:    :+: :+:     :+: :+:        :+:    :+: :+:    :+: :+:          :+: :+:   :+:    :+:
// +:+    +:+ +:+     +:+ +:+        +:+    +:+ +:+        +:+         +:+   +:+  +:+
// +#+    +:+ +#+     +:+ +#++:++#   +#++:++#:  +#++:++#++ +#++:++#   +#++:++#++: +#++:++#++
// +#+    +#+  +#+   +#+  +#+        +#+    +#+        +#+ +#+        +#+     +#+        +#+
// #+#    #+#   #+#+#+#   #+#        #+#    #+# #+#    #+# #+#        #+#     #+# #+#    #+#
//  ########      ###     ########## ###    ###  ########  ########## ###     ###  ########




.page-head.mod_overseas {
    width: 100%;
    height: 768px;
    background: center no-repeat url(../img/business/overseas-mv.jpg);
    background-size: cover;
    .outer {
        margin: 0 auto;
        width: 1280px;
    }
    .inner {
        padding-top: 100px;
    }
    & + .content_body {
        margin-top: -360px;
    }
}


.overseas_lead {
    position: relative;
    height: 356px;
    color: #fff;
    .inner {
        padding: 55px 0 55px 60px;
        &::before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 200%;
            height: 100%;
            content: '';
            background: 30% center no-repeat url(../img/business/overseas-lead_bg.jpg);
            background-size: cover;
        }
        .body {
            display: flex;
            position: relative;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            h3 {
                flex-shrink: 1;
                width: 370px;
                font-family: $cgaramond;
                font-weight: normal;
                font-size: 48px;
            }
            .text {
                flex-shrink: 1;
                width: 608px;
                line-height: 1.9em;
                font-size: 16px;
                small {
                    display: block;
                    line-height: 1.4em;
                    font-size: 10px;
                }
            }
        }//.body
    }//.inner
}//.overseas_lead

.overseas_description {
    margin-bottom: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .body {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 512px;
        p {
            line-height: 2em;
            font-size: 16px;
        }
        .btn {
            margin-top: 60px;
            width: 417px;
            height: 64px;
            a {
                border: solid 1px #003894;
                border-radius: 4px;
                display: block;
                width: 100%;
                height: 100%;
                line-height: 64px;
                color: #003894;
                font-weight: bold;
                font-size: 16px;
                text-align: center;
                text-decoration: none;
            }
        }
    }//.body
    .image {
        flex-shrink: 1;
        width: 548px;
    }
    .heading02 {
        margin-bottom: 40px;
        font-size: 48px;
        small {
            font: 16px;
        }
    }
}//.overseas_description

.estate-list.mod_overseas {
    .heading02 {
        margin-bottom: 60px;
    }
    ul {
        li {
            margin-right: 23px;
            width: 357px;
            &:nth-child(3n) {
                margin-right: 0;
            }
        }// li
    }// ul
}//.estate-list.mod_overseas

.slideshow {
    .fv-img {
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 1120px;
        height: 452px;
        background: #f2f3f8;

        .slide {
            margin-bottom: 5px;
            flex-shrink: 1;
            width: 748px;
            height: 452px;
            .slide-view {
                width: 100%;
                li {
                    width: 100%;
                    img {
                        width: 748px;
                        height: 452px;
                        object-fit: contain;
                    }
                }// li
            }//.slide-view
        }//.slide
        .thumbs {
            box-sizing: border-box;
            padding: 14px;
            flex-shrink: 1;
            width: 370px;
            height: 452px;
            overflow: scroll !important;
            .slide-nav {
                margin-left: -1.5%;
                width: 100%;
                li {
                    padding: 0 1%;
                    width: 108px;
                    height: 108px;
                    img {
                        border-radius: 4px;
                        width: 108px;
                        height: 108px;
                        object-fit: cover;
                    }
                }
            }//.slide-nav
            .slick-list {
                //overflow: scroll !important;
            }
            .slick-track {
                display: flex !important;
                flex-wrap: wrap;
                justify-content: flex-start;
                width: 345px !important;
                height: auto;
                transform: translate3d(0,0,0) !important;
                .slick-slide {
                    margin-bottom: 6px;
                    padding: 0 3px !important;
                    width: 108px !important;
                    height: 108px !important;

                    opacity: .5;
                    &.slick-current {
                        opacity: 1;
                        img {
                            border: solid 2px #003894;
                        }
                    }
                }
            }
        }//.thumbs
        .slick-prev,
        .slick-next {
            display: none !important;
        }
    }//.fv-img
}//.slideshow
