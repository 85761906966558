//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

//  ::::::::   ::::::::  ::::    ::::  :::::::::     :::     ::::    ::: :::   :::
// :+:    :+: :+:    :+: +:+:+: :+:+:+ :+:    :+:  :+: :+:   :+:+:   :+: :+:   :+:
// +:+        +:+    +:+ +:+ +:+:+ +:+ +:+    +:+ +:+   +:+  :+:+:+  +:+  +:+ +:+
// +#+        +#+    +:+ +#+  +:+  +#+ +#++:++#+ +#++:++#++: +#+ +:+ +#+   +#++:
// +#+        +#+    +#+ +#+       +#+ +#+       +#+     +#+ +#+  +#+#+#    +#+
// #+#    #+# #+#    #+# #+#       #+# #+#       #+#     #+# #+#   #+#+#    #+#
//  ########   ########  ###       ### ###       ###     ### ###    ####    ###




.company_top {
    margin-bottom: 60px;
    ul {
        //display: flex;
        //flex-direction: row;
        //flex-wrap: wrap;
        //justify-content: space-between;
    }
    li {
        //margin-bottom: 10px;
        //height: 180px;
        a {
            //box-sizing: border-box;
            //padding: 25px;
            //border-radius: 4px;
            //display: block;
            //width: 100%;
            //height: 100%;
            //color: #fff;
            //font-weight: bold;
            //font-size: 18px;
            //text-decoration: none;
        }
        &.lnv01 {
            width: 100%;
            a {
                //background: center no-repeat url(../img/company/company-nav01.jpg);
                background-size: cover;
            }
        }//&.lnv01
        &.lnv02 {
            width: 100%;
            a {
                //background: center no-repeat url(../img/company/company-nav02.jpg);
                background-size: cover;
            }
        }//&.lnv02
        &.lnv03 {
            width: 100%;
            a {
                //background: center no-repeat url(../img/company/company-nav03.jpg);
                background-size: cover;
            }
        }//&.lnv03
        &.lnv04 {
            width: 100%;
            a {
                //background: center no-repeat url(../img/company/company-nav04.jpg);
                background-size: cover;
            }
        }//&.lnv05
        &.lnv05 {
            width: 100%;
            a {
                //background: center no-repeat url(../img/company/company-nav05.jpg);
                background-size: cover;
            }
        }//&.lnv0
    }
}//.company_top

.corp_info {
    margin-bottom: 60px;
    display: block;
    //flex-direction: row;
    //justify-content: space-between;
    table {
        width: 100%;
        tr {
            //border-bottom: solid 1px #b8bfce;
            th,
            td {
                //padding: 12px 0;
                //line-height: 2em;
                font-size: 12px;
                //text-align: left;
            }
            th {
                width: 90px;
                //font-weight: normal;
            }
            td {
                //// font-size: 16px;
            }
        }
        thead {
            th {
                //font-weight: bold;
                font-size: 14px;
                //text-align: left;
            }
        }// thead
    }// table
    .image {
        padding-top: 40px;
        width: 100%;
        text-align: center;
        img {
            margin-bottom: 40px;
        }
    }//.image
    .btn_to_figures {
        //height: 64px;
        a {
            //border: solid 1px #003894;
            //border-radius: 4px;
            //display: block;
            //width: 100%;
            //height: 100%;
            //line-height: 64px;
            //color: #003894;
            //font-weight: bold;
            //font-size: 16px;
            //text-align: center;
            //text-decoration: none;
        }
    }//.btn_to_figures
}//.corp_info


.mod_corp_accsess {
    //box-sizing: border-box;
    padding: 0 0 20px;
    border-left: 0;
    //background: linear-gradient(to left, #f7f7f7 0%, #f2f3f8 100%) !important;
    .inner {
        //display: flex;
        //flex-direction: row;
        //flex-wrap: wrap;
        //justify-content: space-between;
        width: 100%;
    }
    .body {
        //box-sizing: border-box;
        padding: 20px 0;
        //flex-shrink: 1;
        width: 100%;
        .heading02 {
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 20px;
            &:last-child {
                //margin-bottom: 0;
            }
            small {
                //display: block;
                font-size: 14px;
            }
            em {
                //display: block;
                //font-weight: bold;
                font-size: 18px;
            }
        }// p
    }//.body
    .map {
        //position: relative;
        //flex-shrink: 1;
        width: 100%;
        height: 60vw;
        iframe {
            //pointer-events: none;
            width: 100%;
            height: 100%;
        }
        a {
            //display: block;
            //position: absolute;
            //top: 0;
            //left: 0;
            //z-index: 21;
            //width: 100%;
            //height: 100%;
            //background: rgba(255,255,255,0);
            //text-indent: -9999px;
            //transition-duration: .1s;
            &:hover {
                //background: rgba(255,255,255,.3);
            }
        }
        &::after {
            display: none;
            //margin-top: -42px;
            //margin-left: -35px;
            //display: block;
            //position: absolute;
            //top: 56%;
            //left: 58.5%;
            //z-index: 20;
            // width: 35px;
            // height: 42px;
            //content: '';
            //background: url(../img/company/company-map_pin.png);
            // background-size: cover;
        }
    }//.map
}//.mod_corp_accsess

.mod_disclosure {
    padding: 60px 0;
    .inner {
        display: block;
        //justify-content: space-between;
    }
    .heading03 {
        //width: 205px;
        margin-bottom: 20px;
    }
    .body {
        p {
            font-size: 12px;
            em {
                //display: block;
                //font-weight: bold;
                font-size: 14px;
            }
            a {
                font-size: 14px;
                //color: #202020;
            }
        }// p
    }//.body
}//.mod_disclosure
