// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

// :::::::::  :::::::::: :::::::: ::::::::::: :::::::::  :::::::::: ::::    :::  ::::::::  ::::::::::      ::::    ::::   ::::::::
// :+:    :+: :+:       :+:    :+:    :+:     :+:    :+: :+:        :+:+:   :+: :+:    :+: :+:             +:+:+: :+:+:+ :+:    :+:
// +:+    +:+ +:+       +:+           +:+     +:+    +:+ +:+        :+:+:+  +:+ +:+        +:+             +:+ +:+:+ +:+ +:+
// +#++:++#:  +#++:++#  +#++:++#++    +#+     +#+    +:+ +#++:++#   +#+ +:+ +#+ +#+        +#++:++#        +#+  +:+  +#+ :#:
// +#+    +#+ +#+              +#+    +#+     +#+    +#+ +#+        +#+  +#+#+# +#+        +#+             +#+       +#+ +#+   +#+#
// #+#    #+# #+#       #+#    #+#    #+#     #+#    #+# #+#        #+#   #+#+# #+#    #+# #+#             #+#       #+# #+#    #+#
// ###    ### ########## ######## ########### #########  ########## ###    ####  ########  ##########      ###       ###  ########
.residence_mg_lnav {
    margin-bottom: 120px;
    h3 {
        margin-bottom: 60px;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
    }
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    li {
        margin-bottom: 10px;
        width: 33%;
        height: 180px;
        a {
            box-sizing: border-box;
            border-radius: 4px;
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            color: #fff;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            font-weight: bold;
            font-size: 18px;
            text-decoration: none;
            transition-duration: .1s;
            .label {
                box-sizing: border-box;
                padding: 24px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                height: 100%;
                line-height: 1.4em;
                background: linear-gradient(to bottom, rgba(4,24,51,1) 0%,rgba(4,24,51,0) 100%);
                font-weight: normal;
                font-size: 14px;
                em {
                    margin-bottom: 10px;
                    display: block;
                    flex-basis: 1px;
                    font-weight: bold;
                    font-size: 18px;
                }
                small {
                    display: block;

                    flex-grow: 3;
                }
            }//.label
            &:hover {
                background-size: 110%;
            }
        }
        &.lnv01 {
            a {
                background-image: url(../img/business/residence_mg-lnav_bg01.jpg);
            }
        }//&.lnv01
        &.lnv02 {
            a {
                background-image: url(../img/business/residence_mg-lnav_bg02.jpg);
            }
        }//&.lnv02
        &.lnv03 {
            a {
                background-image: url(../img/business/residence_mg-lnav_bg03.jpg);
            }
        }//&.lnv03
    }
}//.residence_mg_lnav


.residence_mg_category {
    margin-bottom: 114px;
    .outer_header {
        position: relative;
        z-index: 10;
        .inner {
            box-sizing: border-box;
            padding-top: 40px;
            position: relative;
            height: 336px;
            &::before {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 9;
                width: 150%;
                height: 100%;
                content: '';
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            .heading {
                position: relative;
                z-index: 10;
                em {
                    display: block;
                    color: rgba(242,243,248,.2);
                    font-family: $cgaramond;
                    font-weight: normal;
                    font-size: 80px;
                }
                small {
                    display: block;
                    color: #fff;
                    font-weight: bold;
                    font-size: 30px;
                }
            }//.heading
        }//.inner
    }//.outer_header
    &#nv01 {
        .outer_header .inner::before {
            background-image: url(../img/business/residence_mg-header_bg01.jpg);
        }
    }
    &#nv02 {
        .outer_header .inner::before {
            background-image: url(../img/business/residence_mg-header_bg02.jpg);
        }
    }
    &#nv03 {
        .outer_header .inner::before {
            background-image: url(../img/business/residence_mg-header_bg03.jpg);
        }
    }
    & > .inner {
        margin-top: -115px;
        border: solid 1px #b8bfce;
        border-radius: 4px;
        position: relative;
        z-index: 11;
        width: 1200px;
        overflow: hidden;
        transform: translateX(40px);
        .heading02 {
            margin-bottom: 60px;
        }
    }//& > .inner
    .pre_content {
        padding: 60px;
        padding-right: 140px;
        background: #f2f3f8;
    }
    .main_content {
        padding: 60px;
        padding-right: 140px;
        background: #fff;
        .content {
            &:first-child {
                margin-bottom: 60px;
                padding-bottom: 60px;
                border-bottom: solid 1px #b8bfce;
            }
        }//.content
        .estate_slider {
            width: 90%;
        }
    }//.main_content
    .achieve_count {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        .heading02 {
            position: relative;
            z-index: 11;
            width: 250px;
        }
        ul {
            display: flex;
            position: relative;
            z-index: 11;
            flex-direction: row;
            justify-content: flex-start;
            width: 760px;
            li {
                margin-right: 60px;
                .label {
                    margin-bottom: 20px;
                    padding-left: 10px;
                    border-left: solid 4px #003894;
                    display: block;
                    font-size: 14px;
                }
                em {
                    color: #003894;
                    font-weight: bold;
                    font-size: 40px;
                }
                small {
                    display: block;
                    font-size: 10px;
                    text-align: right;
                }
            }
        }
    }//.achieve_count
    .flow_list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        .heading02 {
            width: 260px;
        }
        ol {
            li {
                margin-top: 40px;
                display: flex;
                position: relative;
                flex-direction: row;
                justify-content: flex-start;
                &::before {
                    display: block;
                    position: absolute;
                    top: -40px;
                    left: 45px;
                    width: 2px;
                    height: 41px;
                    content: '';
                    background: #003894;
                }
                &:first-child {
                    margin-top: 0;
                    &::before {
                        display: none;
                    }
                }
                span {
                    box-sizing: border-box;
                    margin-right: 16px;
                    border: solid 1px #003894;
                    border-radius: 90px;
                    display: block;
                    width: 90px;
                    height: 90px;
                    line-height: 90px;
                    color: #003894;
                    background: #fff;
                    font-family: $cgaramond;
                    font-size: 24px;
                    text-align: center;
                }// span
                em {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 80%;
                    font-weight: bold;
                    font-size: 18px;
                }
            }// li
        }
    }//.flow_list
    .service_list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        li {
            padding-top: 24px;
            position: relative;
            width: 22%;
            &::before {
                box-sizing: border-box;
                border-left: solid 24px #003894;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                content: '';
                background: #ddd;
            }
            em {
                margin-bottom: 24px;
                display: block;
                font-weight: bold;
                font-size: 24px;
            }
            span {
                margin-bottom: 24px;
                display: block;
                font-size: 14px;
            }
            a {
                display: block;
                color: #003894;
                font-weight: bold;
                font-size: 14px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }// li
    }//.service_list
    .achieve_list {
        ul {
            margin-bottom: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            li {
                width: 23%;
                img {
                    margin-bottom: 5px;
                    display: block;
                    width: 100%;
                }
                .text {
                    font-weight: bold;
                    font-size: 14px;
                }
            }// li
        }// ul
        .other_achieve {
            h4 {
                margin-bottom: 16px;
                padding-left: 16px;
                border-left: solid 4px #003894;
                font-weight: normal;
                font-size: 14px;
            }
            p {
                font-weight: bold;
                font-size: 18px;
            }
        }//.other_achieve
    }//.achieve_list
}//.residence_mg_category
