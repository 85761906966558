//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

// ::::    ::: :::::::::: :::       :::  ::::::::
// :+:+:   :+: :+:        :+:       :+: :+:    :+:
// :+:+:+  +:+ +:+        +:+       +:+ +:+
// +#+ +:+ +#+ +#++:++#   +#+  +:+  +#+ +#++:++#++
// +#+  +#+#+# +#+        +#+ +#+#+ +#+        +#+
// #+#   #+#+# #+#         #+#+# #+#+#  #+#    #+#
// ###    #### ##########   ###   ###    ########




// :::::::::      :::      ::::::::  ::::::::::
// :+:    :+:   :+: :+:   :+:    :+: :+:
// +:+    +:+  +:+   +:+  +:+        +:+
// +#++:++#+  +#++:++#++: +#++:++#++ +#++:++#
// +#+    +#+ +#+     +#+        +#+ +#+
// #+#    #+# #+#     #+# #+#    #+# #+#
// #########  ###     ###  ########  ##########
.box {
    &.mod_news {
        .inner {
            //display: flex;
            //flex-direction: row;
            //flex-flow: wrap;
            //justify-content: space-between;
        }
        .news-main {
            width: 100%;
        }//.news-main
        .news-sub {
            margin-top: 60px;
            width: 100%;
        }//.news-sub
    }//&.mod_news
}//.box

.heading-side {
    //margin-bottom: 24px;
    //font-size: 24px;
    small {
        //display: block;
        //color: #b8bfce;
        //font-family: $cgaramond;
        //font-size: 16px;
    }
}//.heading-side


.entry_list {
    //border-bottom: solid 1px #f2f3f8;
    li {
        //border-top: solid 1px #f2f3f8;
        //font-size: 14px;
        a {
            //box-sizing: border-box;
            //padding: 15px 0 15px 1.2em;
            //display: block;
            //color: #202020;
            //text-indent: -1.2em;
            //text-decoration: none;

            &::before {
                //margin-right: 10px;
                //border-right: solid 2px #003894;
                //border-bottom: solid 2px #003894;
                //display: inline-block;
                //width: 5px;
                //height: 5px;
                //content: '';
                //transform: rotate(-45deg);
            }//&::before
            &:hover {
                //color: #fff;
                //background: #003894;

                &::before {
                    //border-right: solid 2px #fff;
                    //border-bottom: solid 2px #fff;
                }//&::before
            }//&:hover
        }// a
    }// li
}//.entry_list

.recent_posts {
    //margin-top: 45px;
    .entry_list {
        li {
            a {
                //padding: 10px 0 10px 1.2em;
            }// a
        }// li
    }//.entry_list
}//.recent_posts

.plugin_note {
    //margin-top: 40px;
    p {
        //font-size: 10px;
        a {
            //margin-top: 10px;
            //display: block;
        }
    }
}//.plugin_note




// ::::    ::: :::::::::: :::       :::  ::::::::       :::        ::::::::::: :::::::: :::::::::::
// :+:+:   :+: :+:        :+:       :+: :+:    :+:      :+:            :+:    :+:    :+:    :+:
// :+:+:+  +:+ +:+        +:+       +:+ +:+             +:+            +:+    +:+           +:+
// +#+ +:+ +#+ +#++:++#   +#+  +:+  +#+ +#++:++#++      +#+            +#+    +#++:++#++    +#+
// +#+  +#+#+# +#+        +#+ +#+#+ +#+        +#+      +#+            +#+           +#+    +#+
// #+#   #+#+# #+#         #+#+# #+#+#  #+#    #+#      #+#            #+#    #+#    #+#    #+#
// ###    #### ##########   ###   ###    ########       ########## ########### ########     ###
.news-list {
    //margin-bottom: 40px;
    //border-bottom: solid 1px #b8bfce;
    li {
        //border-top: solid 1px #b8bfce;
        position: relative;
        a {
            padding: 5px 0;
            padding-left: 100px;
            //display: block;
            //color: #202020;
            font-size: 14px;
            text-indent: 0;
            //text-decoration: none;

            &::before {
                position: absolute;
                top: 10px;
                left: 0;
                //margin-right: 10px;
                //border-right: solid 2px #003894;
                //border-bottom: solid 2px #003894;
                //display: inline-block;
                //width: 5px;
                //height: 5px;
                //content: '';
                //transform: rotate(-45deg);
            }//&::before
            .date {
                position: absolute;
                top: 5px;
                left: 15px;
            }
            &:hover {
                //text-decoration: underline;
            }
        }// a
    }// li
}//.news-list




// ::::    ::: :::::::::: :::       :::  ::::::::       :::::::::  :::::::::: ::::::::::: :::     ::::::::::: :::
// :+:+:   :+: :+:        :+:       :+: :+:    :+:      :+:    :+: :+:            :+:   :+: :+:       :+:     :+:
// :+:+:+  +:+ +:+        +:+       +:+ +:+             +:+    +:+ +:+            +:+  +:+   +:+      +:+     +:+
// +#+ +:+ +#+ +#++:++#   +#+  +:+  +#+ +#++:++#++      +#+    +:+ +#++:++#       +#+ +#++:++#++:     +#+     +#+
// +#+  +#+#+# +#+        +#+ +#+#+ +#+        +#+      +#+    +#+ +#+            +#+ +#+     +#+     +#+     +#+
// #+#   #+#+# #+#         #+#+# #+#+#  #+#    #+#      #+#    #+# #+#            #+# #+#     #+#     #+#     #+#
// ###    #### ##########   ###   ###    ########       #########  ##########     ### ###     ### ########### ##########
.news-body {
    .heading-title {
        margin-bottom: 10px;
        //font-weight: bold;
        font-size: 22px;
    }//.heading-title
    .post_date {
        margin-bottom: 40px;
        //font-size: 16px;
    }//.post_date
    p {
        //margin-bottom: 2em;
        //font-size: 16px;
    }
    img {
        width: 100%;
    }
}//.news-body

.btn-back_list {
    //margin: 60px auto 0;
    //width: 303px;
    //height: 64px;
    //line-height: 64px;
    //text-align: center;
    a {
        //border: solid 1px #003894;
        //border-radius: 4px;
        //display: block;
        //color: #003894;
        //background: #fff;
        //font-weight: bold;
        //font-size: 16px;
        //text-decoration: none;
        &:hover {
            //color: #fff;
            //background: #003894;
            .mark_link.mod_blue {
                //border: solid 1px #fff;
                &::after {
                    //border-right: solid 2px #fff;
                    //border-bottom: solid 2px #fff;
                }
            }//.mark_link.mod_blue
        }//&:hover
    }// a
}//.btn-back_list
