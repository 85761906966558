//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

//  :::::::: ::::::::::: ::::::::::: :::::::::: ::::    ::::      :::     :::::::::
// :+:    :+:    :+:         :+:     :+:        +:+:+: :+:+:+   :+: :+:   :+:    :+:
// +:+           +:+         +:+     +:+        +:+ +:+:+ +:+  +:+   +:+  +:+    +:+
// +#++:++#++    +#+         +#+     +#++:++#   +#+  +:+  +#+ +#++:++#++: +#++:++#+
//        +#+    +#+         +#+     +#+        +#+       +#+ +#+     +#+ +#+
// #+#    #+#    #+#         #+#     #+#        #+#       #+# #+#     #+# #+#
//  ######## ###########     ###     ########## ###       ### ###     ### ###




.sitemap {
    .list {
        //margin-top: 30px;
        //padding-top: 40px;
        //border-top: solid 1px #b8bfce;
        &:first-child {
            //margin-top: 0;
            //padding-top: 0;
            //border-top: 0;
        }
        a {
            //color: #202020;
            //text-decoration: none;
            &:hover {
                //text-decoration: underline;
            }
        }
        h3 {
            margin-bottom: 20px;
            //font-weight: bold;
            font-size: 18px;
        }
        .category {
            margin-bottom: 20px;
            width: 100%;
            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                li {
                    width: 50%;
                    //margin-bottom: 17px;
                    //line-height: 16px;
                    font-size: 14px;
                    a {
                        &::before {
                            //margin-right: 10px;
                            //border-right: solid 2px #003894;
                            //border-bottom: solid 2px #003894;
                            //display: inline-block;
                            //width: 5px;
                            //height: 5px;
                            //content: '';
                            //transform: rotate(-45deg);
                        }
                    }// a
                }// li
            }// ul
        }//.category
        &.mod_categories {
            display: block;
            //flex-direction: row;
            //flex-wrap: wrap;
        }//&.mod_categories
        &.mod_singleline {
            h3 {
                //margin-right: 40px;
            }
            .category {
                //display: flex;
                //flex-direction: row;
                //flex-flow: wrap;
                //width: 100%;
                ul {
                    //padding-top: 5px;
                    width: 100%;
                    li {
                        margin-right: 0;
                        //display: inline-block;
                    }
                }
            }//.category
        }//&.mod_singleline
    }//.list
}//.sitemap
