//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

// :::::::::  :::        :::::::::   ::::::::       ::::    ::::   ::::::::
// :+:    :+: :+:        :+:    :+: :+:    :+:      +:+:+: :+:+:+ :+:    :+:
// +:+    +:+ +:+        +:+    +:+ +:+             +:+ +:+:+ +:+ +:+
// +#++:++#+  +#+        +#+    +:+ :#:             +#+  +:+  +#+ :#:
// +#+    +#+ +#+        +#+    +#+ +#+   +#+#      +#+       +#+ +#+   +#+#
// #+#    #+# #+#        #+#    #+# #+#    #+#      #+#       #+# #+#    #+#
// #########  ########## #########   ########       ###       ###  ########




.bldg_mg_lead {
    padding: 0 0 60px;
    font-size: 14px;
}//.bldg_mg_lead

.bldg_mg_system {
    margin-bottom: 60px;
    .heading02 {
        margin-bottom: 40px;
    }
    .division {
        //margin-bottom: 60px;
        //padding: 24px 0 0;
        display: block;
        //position: relative;
        //flex-direction: row;
        //justify-content: space-between;
        &::before {
            //box-sizing: border-box;
            //border-left: solid 24px #003894;
            //display: block;
            //position: absolute;
            //top: 0;
            //left: 0;
            //width: 100%;
            //height: 1px;
            //content: '';
            //background: #ddd;
        }
        .body {
            margin-bottom: 20px;
            width: 100%;
            font-size: 14px;
            .heading {
                //margin-bottom: 25px;
                //font-weight: bold;
                font-size: 18px;
            }
        }//.body
        .image {
            width: 100%;
            img {
                //width: 100%;
            }
        }
    }//.division
    .techteam_list {
        margin-top: 24px;
        li {
            margin-bottom: 20px;
            //display: flex;
            //flex-direction: row;
            //justify-content: space-between;
            .image {
                width: 15%;
                img {
                    //width: 100%;
                }
            }//.image
            .text {
                //display: block;
                width: 80%;
                em {
                    //margin-bottom: 5px;
                    //display: block;
                    //color: #003894;
                    //font-weight: bold;
                    font-size: 16px;
                }
                small {
                    //display: block;
                    //line-height: 1.7em;
                    font-size: 12px;
                }
            }
        }
    }//.techteam_list
}//.bldg_mg_system

.management_record {
    margin-bottom: 60px;
    margin-left: 20px;

    //overflow-x: hidden;
    .inner {
        //box-sizing: border-box;
        padding: 24px;
        display: block;
        //position: relative;
        //flex-direction: row;
        //justify-content: flex-start;
        &::after {
            //display: block;
            //position: absolute;
            //top: 0;
            //left: 0;
            //z-index: 10;
            //width: 300%;
            //height: 100%;
            //content: '';
            //background: #f2f3f8;
        }
    }//.inner
    .heading02 {
        //position: relative;
        //z-index: 11;
        //width: 250px;
        margin-bottom: 24px;
    }
    ul {
        display: block;
        //position: relative;
        //z-index: 11;
        //flex-direction: row;
        //justify-content: space-between;
        width: 100%;
        li {
            margin-bottom: 10px;
            width: 100%;
            .label {
                margin-bottom: 0;
                //padding-left: 10px;
                //border-left: solid 4px #003894;
                //display: block;
                //font-size: 14px;
            }
            em {
                display: block;
                //color: #003894;
                //font-weight: bold;
                font-size: 36px;
                text-align: right;
            }
            small {
                //display: block;
                //font-size: 10px;
                //text-align: right;
            }
        }
    }
}//.management_record

.repair_case {
    .box_head {
        display: block;
        //flex-direction: row;
        //justify-content: flex-start;
        .heading02 {
            margin-right: 0;
            margin-bottom: 30px;
        }
        .description {
            width: 100%;
            em {
                margin-bottom: 24px;
                //display: block;
                //font-weight: bold;
                font-size: 24px;
            }
            span {
                //display: block;
                line-height: 1.4em;
                font-size: 14px;
            }
        }//.description
    }//.box_head
}//.repair_case

.case_list {
    margin: 30px 0;
    //display: flex;
    //flex-direction: row;
    //flex-wrap: wrap;
    //justify-content: flex-start;
    .item {
        margin-bottom: 30px;
        //padding-top: 24px;
        //position: relative;
        width: 100%;
        &:nth-child(2n) {
            margin-left: 0;
        }
        h4 {
            //margin-bottom: 24px;
            font-size: 18px;
        }
        .image {
            //margin: 0;
            img {
                //width: 100%;
            }
        }//.image
        &::before {
            //box-sizing: border-box;
            //border-left: solid 24px #003894;
            //display: block;
            //position: absolute;
            //top: 0;
            //left: 0;
            //width: 100%;
            //height: 1px;
            //content: '';
            //background: #ddd;
        }
    }//.item
}//.case_list

.btn_recruitment {
    margin: 0 20px;
    width: auto;
    height: auto;
    a {
        //justify-content: flex-start;
        //width: 100%;
        //height: 100%;
        //color: #fff;
        //background: #06173c;
        //text-decoration: none;
        border-radius: 4px;
        //display: flex;
        flex-direction: column;
        overflow: hidden;
        .body {
            //box-sizing: border-box;
            padding: 24px;
            order: 2;
            width: 100%;
            p {
                //margin-bottom: 40px;
                //font-size: 14px;
                em {
                    //display: block;
                    //font-weight: bold;
                    font-size: 18px;
                }
            }// p
            .to_recruit {
                //display: block;
                font-size: 14px;
            }
        }//.body
        .image {
            order: 1;
            width: 100%;
            img {
                width: 100%;
            }
        }//.image
    }// a
}//.btn_recruitment
