//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########
.heading-residence {
    margin-bottom: 30px;
    color: #003894;
    font-family: Cormorant Garamond,serif;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    letter-spacing: 5px;
    img {
        margin: 0 auto 10px;
        display: block;
        width: auto;
        height: 72px;
    }
    small {
        display: block;
        font-family: Noto Sans JP,Hiragino Kaku Gothic ProN,'ヒラギノ角ゴ ProN W3',Meiryo,'メイリオ','游ゴシック体',YuGothic,'游ゴシック',Yu Gothic,sans-serif,-apple-system;
        font-size: 14px;
        letter-spacing: normal;
    }
}
.heading-residence02 {
    margin-bottom: 15px;
    line-height: 1.5;
    color: #003894;
    font-weight: bold;
    font-size: 24px;
}

.residence {
    .heading {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 18px;
    }
    .heading02 {
        margin-bottom: 20px;
        font-size: 36px;
        small {
            margin-top: 5px;
            font-family: Noto Sans JP,Hiragino Kaku Gothic ProN,'ヒラギノ角ゴ ProN W3',Meiryo,'メイリオ','游ゴシック体',YuGothic,'游ゴシック',Yu Gothic,sans-serif,-apple-system;
        }
    }
    .estate-list {
        &.mod_building {
            .heading02 {
                margin-bottom: 20px;
                font-size: 36px;
                small {
                    margin-top: 5px;
                    font-family: Noto Sans JP,Hiragino Kaku Gothic ProN,'ヒラギノ角ゴ ProN W3',Meiryo,'メイリオ','游ゴシック体',YuGothic,'游ゴシック',Yu Gothic,sans-serif,-apple-system;
                }
            }
        }
    }
    .description {
        line-height: 1.5;
        font-size: 14px;
    }
    &-leading {
        margin: 0 -7px 50px;
        line-height: 2.0;
        font-size: 16px;
        text-align: center;
    }
    &-img-right {
        margin: 0 -20px 20px 0;
        width: calc(100vw - 20px);
    }
    &-img-left {
        margin: 0 0 20px -20px;
        width: calc(100vw - 20px);
    }
    &-flex {
        display: block;
    }
    &-btn {
        flex-shrink: 0;
        &.space-t {
            margin-top: 20px;
        }
        a {
            border: solid 1px #003894;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 64px;
            color: #003894;
            background: #fff;
            font-weight: 700;
            text-decoration: none;
            &.two-line {
                height: 67px;
            }
            .sub {
                display: block;
                font-weight: normal;
                font-size: 10px;
            }
        }
    }
    &-define-list {
        margin-top: 40px;
        dt {
            margin-bottom: 10px;
            padding-left: 10px;
            border-left: solid 4px #003894;
            font-weight: normal;
            font-size: 12px;
        }
        dd {
            margin-bottom: 35px;
            font-size: 14px;
            .heading {
                margin-bottom: 10px;
                font-size: 18px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &-sup {
        font-size: 10px;
    }


    &-location {
        margin-bottom: 40px;
        position: relative;
        &:after {
            display: block;
            position: absolute;
            top: 60px;
            right: 0;
            z-index: -1;
            width: calc(100vw - 20px);
            height: 390px;
            content: '';
            background: #f2f3f8;
        }
    }
    .estate-list {
        ul {
            li {
                figure {
                    border-radius: 4px;
                    overflow: hidden;
                }
                .item_title {
                    em {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    &-quality {
        margin-bottom: 40px;
        position: relative;
        &:after {
            display: block;
            position: absolute;
            top: 60px;
            left: 0;
            z-index: -1;
            width: calc(100vw - 20px);
            height: 390px;
            content: '';
            background: #f2f3f8;
        }
        .heading,
        .heading02 {
            margin-left: 20px;
        }
        .description {
            margin-left: 20px;
        }
    }
    &-facility {
        margin-bottom: 40px;
        &-inner {
            margin-bottom: 40px;
            position: relative;
            &.space-b {
                margin-bottom: 5px;
            }
        }
        &-heading {
            margin-bottom: 15px;
            color: #003894;
            font-family: Cormorant Garamond,serif;
            font-size: 24px;
        }
        &-first {
            margin-bottom: 15px;
            img {
                margin-bottom: 5px;
                display: block;
                width: 100%;
            }
            h5 {
                margin-bottom: 3px;
                font-weight: bold;
                font-size: 14px;
            }
            p {
                font-size: 12px;
            }
        }
        &-third {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            img {
                display: block;
                flex-shrink: 0;
                width: 48.9%;
            }
        }
        &-list {
            margin-bottom: 5px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                margin-bottom: 10px;
                width: 48.9%;
            }
            &-thumb {
                margin-bottom: 5px;
                img {
                    display: block;
                    width: 100%;
                }
            }
            h5 {
                font-weight: bold;
                font-size: 14px;
            }
        }
        &-list02 {
            width: 48.9%;
            li {
                margin-bottom: 10px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            h5 {
                margin-bottom: 1px;
                font-weight: bold;
                font-size: 14px;
            }
            p {
                font-size: 12px;
            }
        }
        &-list03 {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                margin-bottom: 10px;
                width: 48.9%;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            &-thumb {
                margin-bottom: 5px;
                img {
                    display: block;
                    width: 100%;
                }
            }
            h5 {
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
    &-service {
        margin-bottom: 60px;
        position: relative;
        &:after {
            display: block;
            position: absolute;
            top: 60px;
            right: 0;
            z-index: -1;
            width: calc(100vw - 20px);
            height: 400px;
            content: '';
            background: #f2f3f8;
        }
    }
    &-support {
        margin-bottom: 0;
        padding: 40px 0;
        border-top: solid 1px #b8bfce;
        border-bottom: solid 1px #b8bfce;
    }
}
