@charset 'UTF-8';
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500&display=swap');

@import '_partial/_reset';
@import './_partial/_slick-theme';
@import './_partial/_slick';
@import './_partial/_slick-org';
$cgaramond: 'Cormorant Garamond', serif;

body {
    color: #202020;
    background: #fff;
    font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', sans-serif, -apple-system;

    -webkit-text-size-adjust: none;
}




// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        :::::::::: ::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:       :+:    :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+       +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#  +#++:++#++
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+              +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#       #+#    #+#
// ###         ########        ########     ###        ###    ########## ########## ########
@import './_partial/_common';
@import './_partial/_frontpage';
@import './_partial/_business';
@import './_partial/_building_mg';
@import './_partial/_design';
@import './_partial/_office_building';
@import './_partial/_overseas_reb';
@import './_partial/_urban_dev';
@import './_partial/_company';
@import './_partial/_history';
@import './_partial/_message';
@import './_partial/_organization';
@import './_partial/_news';
@import './_partial/_recruit';
@import './_partial/_sitemap';
@import './_partial/_contact';

@media screen and (min-width: 769px), print {
    @import './_partial/_mediation';
    @import './_partial/_residence';
    @import './_partial/_residence_mg';
    @import './_partial/recruit_detail';
}

.spv {
    display: none !important;
}




//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        :::::::::: ::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:       :+:    :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+       +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#  +#++:++#++
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+              +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#       #+#    #+#
//  ########  ###              ########     ###        ###    ########## ########## ########
@media screen and (max-width:768px) {
    @import './_partial/_common_sp';
    @import './_partial/_frontpage_sp';
    @import './_partial/_business_sp';
    @import './_partial/_building_mg_sp';
    @import './_partial/_design_sp';
    @import './_partial/_mediation_sp';
    @import './_partial/_office_building_sp';
    @import './_partial/_overseas_reb_sp';
    @import './_partial/_residence_sp';
    @import './_partial/_urban_dev_sp';
    @import './_partial/_company_sp';
    @import './_partial/_history_sp';
    @import './_partial/_message_sp';
    @import './_partial/_organization_sp';
    @import './_partial/_news_sp';
    @import './_partial/_recruit_sp';
    @import './_partial/_sitemap_sp';
    @import './_partial/_contact_sp';
    @import './_partial/_residence_mg_sp';
    @import './_partial/_recruit_detail_sp';
    .pcv {
        display: none !important;
    }
    .spv {
        display: block !important;
    }
}// screen and (max-width:768px)




// ::::    :::     :::     :::::::::  :::::::::   ::::::::  :::       :::
// :+:+:   :+:   :+: :+:   :+:    :+: :+:    :+: :+:    :+: :+:       :+:
// :+:+:+  +:+  +:+   +:+  +:+    +:+ +:+    +:+ +:+    +:+ +:+       +:+
// +#+ +:+ +#+ +#++:++#++: +#++:++#:  +#++:++#:  +#+    +:+ +#+  +:+  +#+
// +#+  +#+#+# +#+     +#+ +#+    +#+ +#+    +#+ +#+    +#+ +#+ +#+#+ +#+
// #+#   #+#+# #+#     #+# #+#    #+# #+#    #+# #+#    #+#  #+#+# #+#+#
// ###    #### ###     ### ###    ### ###    ###  ########    ###   ###
@media screen and (max-width:320px) {
    .cb-box {
        margin-top: -220px;
        margin-left: -140px;
        padding: 20px;
        width: 280px;
        height: 400px;
        min-height: 0;
    }//.cb-box
}//@media screen and (max-width:320px)
@media screen and (max-width:375px) {
    .cb-box {
        margin-top: -280px;
        margin-left: -140px;
        padding: 20px;
        width: 280px;
        height: 520px;
        min-height: 0;
    }//.cb-box
}//@media screen and (max-width:375px)
@media screen and (max-height: 650px) {
    .page-head.mod_mv {
        .inner {
            height: auto;
            .heading {
                font-size: 22px;
                small {
                    font-size: 13px;
                }
            }
        }//.inner
        .head_lead {
            font-size: 10px;
        }//.head_lead
    }//.page-head.mod_mv
    .mv_menu {
        .mv_menu_inner {
            ul {
                li {
                    font-size: 11px;
                }
            }
        }
    }//.mv_menu
}//@media screen and (max-height: 650px)
