// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########
.heading-residence {
    margin-bottom: 60px;
    color: #003894;
    font-family: Cormorant Garamond,serif;
    font-weight: 400;
    font-size: 48px;
    text-align: center;
    letter-spacing: 10px;
    img {
        margin: 0 auto 30px;
        display: block;
        width: auto;
        height: 100px;
    }
    small {
        display: block;
        font-family: Noto Sans JP,Hiragino Kaku Gothic ProN,'ヒラギノ角ゴ ProN W3',Meiryo,'メイリオ','游ゴシック体',YuGothic,'游ゴシック',Yu Gothic,sans-serif,-apple-system;
        font-size: 16px;
        letter-spacing: normal;
    }
}
.heading-residence02 {
    margin-bottom: 25px;
    line-height: 1.5;
    color: #003894;
    font-weight: bold;
    font-size: 36px;
}

.residence {
    .heading {
        margin-bottom: 25px;
        font-weight: 700;
        font-size: 24px;
    }
    .heading02 {
        margin-bottom: 40px;
        font-size: 48px;
        small {
            margin-top: 15px;
            font-family: Noto Sans JP,Hiragino Kaku Gothic ProN,'ヒラギノ角ゴ ProN W3',Meiryo,'メイリオ','游ゴシック体',YuGothic,'游ゴシック',Yu Gothic,sans-serif,-apple-system;
        }
    }
    .estate-list {
        &.mod_building {
            .heading02 {
                margin-bottom: 40px;
                font-size: 48px;
                small {
                    margin-top: 15px;
                    font-family: Noto Sans JP,Hiragino Kaku Gothic ProN,'ヒラギノ角ゴ ProN W3',Meiryo,'メイリオ','游ゴシック体',YuGothic,'游ゴシック',Yu Gothic,sans-serif,-apple-system;
                }
            }
        }
    }
    .description {
        line-height: 2.0;
    }
    &-leading {
        margin-bottom: 115px;
        line-height: 2.0;
        font-size: 18px;
        text-align: center;
    }
    &-img-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 624px;
    }
    &-img-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 624px;
    }
    &-flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &-left {
            width: 340px;
        }
        &-right {
            width: 725px;
        }
        &-left02 {
            width: 662px;
        }
        &-right02 {
            width: 435px;
        }
    }
    &-btn {
        flex-shrink: 0;
        &.space-t {
            margin-top: 35px;
        }
        a {
            border: solid 1px #003894;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 420px;
            height: 64px;
            color: #003894;
            background: #fff;
            font-weight: 700;
            text-decoration: none;
            &.two-line {
                width: 336px;
                height: 71px;
            }
            .sub {
                display: block;
                font-weight: normal;
                font-size: 10px;
            }
        }
    }
    &-define-list {
        dt {
            margin-bottom: 15px;
            padding-left: 20px;
            border-left: solid 4px #003894;
            font-weight: normal;
            font-size: 14px;
        }
        dd {
            margin-bottom: 40px;
            .heading {
                margin-bottom: 15px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &-sup {
        font-size: 10px;
        text-align: right;
    }


    &-location {
        margin-bottom: 120px;
        padding-top: 175px;
        position: relative;
        &:after {
            display: block;
            position: absolute;
            top: 115px;
            right: 140px;
            z-index: -1;
            width: 300%;
            height: 356px;
            content: '';
            background: #f2f3f8;
        }
        .description {
            width: 624px;
        }
    }
    &-quality {
        margin-bottom: 120px;
        padding-top: 175px;
        position: relative;
        &:after {
            display: block;
            position: absolute;
            top: 115px;
            left: 140px;
            z-index: -1;
            width: 300%;
            height: 356px;
            content: '';
            background: #f2f3f8;
        }
        .heading,
        .heading02 {
            margin-left: 690px;
        }
        .description {
            margin-left: 496px;
        }
    }
    &-facility {
        margin-bottom: 120px;
        &-inner {
            margin-bottom: 60px;
            position: relative;
            &.space-b {
                margin-bottom: 50px;
            }
        }
        &-heading {
            border: solid 1px #003894;
            border-radius: 50%;
            display: flex;
            position: absolute;
            top: -60px;
            right: -9px;
            z-index: -1;
            justify-content: center;
            align-items: center;
            width: 240px;
            height: 240px;
            color: #003894;
            background: #fff;
            font-family: Cormorant Garamond,serif;
            font-size: 36px;
            &.comfort {
                top: 180px;
                right: auto;
                left: 0;
            }
        }
        &-first {
            margin-bottom: 17px;
            img {
                margin-bottom: 7px;
                display: block;
                width: 100%;
            }
            h5 {
                margin-bottom: 3px;
                font-weight: bold;
                font-size: 14px;
            }
            p {
                font-size: 10px;
            }
        }
        &-third {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            img {
                display: block;
                width: 222px;
            }
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            li {
                margin-right: 16px;
                width: 210px;
                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
            &-thumb {
                margin-bottom: 7px;
                img {
                    display: block;
                    width: 100%;
                }
            }
            h5 {
                font-weight: bold;
                font-size: 14px;
            }
        }
        &-list02 {
            width: 204px;
            li {
                margin-bottom: 23px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            h5 {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 14px;
            }
            p {
                font-size: 10px;
            }
        }
        &-list03 {
            padding-left: 220px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            li {
                margin: 0 16px 15px 0;
                width: 213px;
                &:nth-of-type(4n) {
                    margin-right: 0;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            &-thumb {
                margin-bottom: 7px;
                img {
                    display: block;
                    width: 100%;
                }
            }
            h5 {
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
    &-service {
        margin-bottom: 60px;
        padding-top: 175px;
        position: relative;
        &:after {
            display: block;
            position: absolute;
            top: 115px;
            right: 140px;
            z-index: -1;
            width: 300%;
            height: 356px;
            content: '';
            background: #f2f3f8;
        }
        .description {
            width: 624px;
        }
    }
    &-support {
        margin-bottom: 0;
        padding: 60px 0;
        border-top: solid 1px #b8bfce;
        border-bottom: solid 1px #b8bfce;
    }
}
