// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

// ::::    ::::  :::::::::: ::::::::: :::::::::::     ::: ::::::::::: ::::::::::: ::::::::  ::::    :::
// +:+:+: :+:+:+ :+:        :+:    :+:    :+:       :+: :+:   :+:         :+:    :+:    :+: :+:+:   :+:
// +:+ +:+:+ +:+ +:+        +:+    +:+    +:+      +:+   +:+  +:+         +:+    +:+    +:+ :+:+:+  +:+
// +#+  +:+  +#+ +#++:++#   +#+    +:+    +#+     +#++:++#++: +#+         +#+    +#+    +:+ +#+ +:+ +#+
// +#+       +#+ +#+        +#+    +#+    +#+     +#+     +#+ +#+         +#+    +#+    +#+ +#+  +#+#+#
// #+#       #+# #+#        #+#    #+#    #+#     #+#     #+# #+#         #+#    #+#    #+# #+#   #+#+#
// ###       ### ########## ######### ########### ###     ### ###     ########### ########  ###    ####




.mediation_lnav {
    margin-bottom: 100px;
    .lead {
        margin-bottom: 60px;
        font-size: 16px;
        text-align: center;
    }
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            width: 24.5%;
            a {
                box-sizing: border-box;
                padding: 24px;
                border-radius: 4px;
                display: block;
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                color: #fff;
                background: #06173c;
                font-weight: bold;
                text-decoration: none;
                &:after {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 7px;
                    content: '';
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00012c+0,001697+36,0065d7+81,0060d1+90,4d54c2+100 */
                    background: #00012c; /* Old browsers */
                    background:    -moz-linear-gradient(left,  #00012c 0%, #001697 36%, #0065d7 81%, #0060d1 90%, #4d54c2 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left,  #00012c 0%,#001697 36%,#0065d7 81%,#0060d1 90%,#4d54c2 100%); /* Chrome10-25,Safari5.1-6 */
                    background:         linear-gradient(to right,  #00012c 0%,#001697 36%,#0065d7 81%,#0060d1 90%,#4d54c2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00012c', endColorstr='#4d54c2',GradientType=1 ); /* IE6-9 */
                }
                em {
                    display: block;
                    font-size: 18px;
                }
                small {
                    display: block;
                    color: #b8bfce;
                    font-family: $cgaramond;
                    font-size: 16px;
                }
                i.mark_link {
                    position: absolute;
                    top: 33%;
                    right: 10px;
                }
            }// a
        }// li
    }//ul
}//.mediation_lnav

.mediation_sell {
    img: 100%;
    .sell_image {
        margin-bottom: 40px;
        img {
            margin: 0 auto;
            display: block;
        }
    }//.sell_image
}//.mediation_sell

.mediation_lease {
    .cat_label {
        margin-bottom: 15px;
        padding-left: 10px;
        border-left: solid 4px #003894;
        display: block;
        font-weight: normal;
        font-size: 14px;
    }
    .lease_info {
        box-sizing: border-box;
        margin-bottom: 60px;
        padding: 40px 0 0;
        border-top: solid 1px #b8bfce;
        border-bottom: solid 1px #b8bfce;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .heading {
            margin-right: 60px;
            width: 350px;
            h3 {
                margin-bottom: 24px;
                color: #003894;
                font-size: 36px;
            }
        }//.heading
        .body {
            width: 705px;
        }//.body
        .lease_case_list {
            li {
                margin-bottom: 40px;
                display: flex;
                flex-direction: row;
                .image {
                    margin-right: 24px;
                    width: 215px;
                    img {
                        width: 100%;
                    }
                }//.image
                .text {
                    width: 460px;
                    em {
                        font-weight: bold;
                        font-size: 24px;
                    }
                }//.text
            }// li
        }//.lease_case_list
    }//.lease_info
    .review_list {
        margin-bottom: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .item {
            box-sizing: border-box;
            padding: 40px;
            width: 48%;
            background-color: #f7f7f7;
            em {
                margin-bottom: 24px;
                display: block;
                line-height: 1.3em;
                font-weight: bold;
                font-size: 24px;
            }
            .detail {
                font-weight: normal;
                font-size: 16px;
            }
        }
    }//.review_list
}//.mediation_lease

.mod_mediation {
    margin-bottom: 120px;
    .mediation_cat_head {
        margin-bottom: 60px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }//.mediation_cat_head
    .heading02 {
        margin-right: 60px;
    }//.heading
    .note {
        color: #202020;
        font-weight: bold;
        font-size: 30px;
    }//.note
    .to_list {
        display: block;
        font-size: 16px;
        text-align: right;
        a {
            color: #003894;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}//.mod_mediation

.btn_cv_satei {
    p {
        margin-bottom: 24px;
        color: #003894;
        font-weight: bold;
        font-size: 36px;
        text-align: center;
    }
    .btn_detail {
        margin: 0 auto;
        width: 520px;
        height: 72px;
        a {
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #fff;
            background: #003894;
            text-decoration: none;
        }
    }//.btn_detail
}//.btn_cv_satei

.contract_records {
    margin-bottom: -120px;
    padding: 120px 0;
    background: rgba(184,191,206,1);
    .mediation_cat_head {
        .heading02 {
            border-left: solid 1px #fff;
            width: 520px;
            color: #fff;
            small {
                color: #fff;
            }
        }
        .note {
            font-size: 24px;
        }
    }
    .estate_slider {
        background: rgba(184,191,206,1);
        .item {
            figure {
                background: transparent;
                img {
                    width: 262px;
                    height: 360px;
                }
            }
        }
        .slick-prev {
            z-index: 50;
            background: linear-gradient(to left, rgba(184,191,206,0) 0%,rgba(184,191,206,1) 30%,rgba(184,191,206,1) 100%) !important;
        }
        .slick-next {
            z-index: 50;
            background: linear-gradient(to right, rgba(184,191,206,0) 0%,rgba(184,191,206,1) 30%,rgba(184,191,206,1) 100%) !important;
        }
    }//.estate_slider
}//.contract_records
