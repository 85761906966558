// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

// :::    ::: ::::::::::: :::::::: ::::::::::: ::::::::  :::::::::  :::   :::
// :+:    :+:     :+:    :+:    :+:    :+:    :+:    :+: :+:    :+: :+:   :+:
// +:+    +:+     +:+    +:+           +:+    +:+    +:+ +:+    +:+  +:+ +:+
// +#++:++#++     +#+    +#++:++#++    +#+    +#+    +:+ +#++:++#:    +#++:
// +#+    +#+     +#+           +#+    +#+    +#+    +#+ +#+    +#+    +#+
// #+#    #+#     #+#    #+#    #+#    #+#    #+#    #+# #+#    #+#    #+#
// ###    ### ########### ########     ###     ########  ###    ###    ###




.history_list {
    padding-left: 20px;
    .sup_list {
        padding-left: 30px;
        border-left: solid 2px #003894;
        position: relative;
        &::after {
            border-right: solid 2px #003894;
            border-bottom: solid 2px #003894;
            display: block;
            position: absolute;
            bottom: 0;
            left: -5px;
            width: 7px;
            height: 7px;
            content: '';
            transform: rotate(45deg);
        }
        .sup_item {
            padding-left: 100px;
            border-top: solid 1px #b8bfce;
            position: relative;
            &::before {
                box-sizing: border-box;
                border: solid 1px #003894;
                border-radius: 20px;
                display: block;
                position: absolute;
                top: 10px;
                left: -41px;
                width: 20px;
                height: 20px;
                content: '';
                background: #fff;
            }
            .year {
                padding-left: 5px;
                position: absolute;
                top: 7px;
                left: 0;
                color: #003894;
                font-size: 18px;
            }//.year
            &:last-child {
                border-bottom: solid 1px #b8bfce;
            }
        }//.sup_item
    }//.sup_list
    .sub_list {
        li {
            padding: 10px 0;
            border-top: solid 1px #b8bfce;
            font-size: 14px;
            &::after {
                display: block;
                clear: both;
                height: 0;
                visibility: hidden;
                content: '.';
            }
            &:first-child {
                border: 0;
            }
            .category {
                margin: -3px 5px -3px 0;
                padding: 4px 10px;
                border-radius: 14px;
                display: inline-block;
                width: 120px;
                color: #003894;
                background: #f2f3f8;
                text-align: center;
            }//.category
            .photo {
                float: right;
                img {
                    border-radius: 4px;
                }
            }//.photo
        }// li
    }//.sub_list
}//.history_list

.histories {
    .history_list {
        margin: 0 auto;
        width: 820px;
    }//.history_list
}//.histories
