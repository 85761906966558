// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

// :::::::::  :::        :::::::::   ::::::::       ::::    ::::   ::::::::
// :+:    :+: :+:        :+:    :+: :+:    :+:      +:+:+: :+:+:+ :+:    :+:
// +:+    +:+ +:+        +:+    +:+ +:+             +:+ +:+:+ +:+ +:+
// +#++:++#+  +#+        +#+    +:+ :#:             +#+  +:+  +#+ :#:
// +#+    +#+ +#+        +#+    +#+ +#+   +#+#      +#+       +#+ +#+   +#+#
// #+#    #+# #+#        #+#    #+# #+#    #+#      #+#       #+# #+#    #+#
// #########  ########## #########   ########       ###       ###  ########




.bldg_mg_lead {
    padding: 0 0 120px;
    font-size: 16px;
}//.bldg_mg_lead

.bldg_mg_system {
    margin-bottom: 120px;
    .heading02 {
        margin-bottom: 60px;
    }
    .division {
        margin-bottom: 60px;
        padding: 24px 0 0;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &::before {
            box-sizing: border-box;
            border-left: solid 24px #003894;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            content: '';
            background: #ddd;
        }
        .body {
            width: 680px;
            .heading {
                margin-bottom: 25px;
                font-weight: bold;
                font-size: 24px;
            }
        }//.body
        .image {
            width: 390px;
            img {
                width: 100%;
            }
        }
    }//.division
    .techteam_list {
        li {
            margin-bottom: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .image {
                margin-right: 24px;
                width: 70px;
                img {
                    width: 100%;
                }
            }//.image
            .text {
                display: block;
                width: 580px;
                em {
                    margin-bottom: 5px;
                    display: block;
                    color: #003894;
                    font-weight: bold;
                    font-size: 18px;
                }
                small {
                    display: block;
                    line-height: 1.7em;
                    font-size: 16px;
                }
            }
        }
    }//.techteam_list
}//.bldg_mg_system

.management_record {
    margin-bottom: 120px;

    overflow-x: hidden;
    .inner {
        box-sizing: border-box;
        padding: 60px;
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        &::after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 300%;
            height: 100%;
            content: '';
            background: #f2f3f8;
        }
    }//.inner
    .heading02 {
        position: relative;
        z-index: 11;
        width: 250px;
    }
    ul {
        display: flex;
        position: relative;
        z-index: 11;
        flex-direction: row;
        justify-content: space-between;
        width: 760px;
        li {
            width: 45%;
            .label {
                margin-bottom: 20px;
                padding-left: 10px;
                border-left: solid 4px #003894;
                display: block;
                font-size: 14px;
            }
            em {
                color: #003894;
                font-weight: bold;
                font-size: 40px;
            }
            small {
                display: block;
                font-size: 10px;
                text-align: right;
            }
        }
    }
}//.management_record

.repair_case {
    .box_head {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .heading02 {
            margin-right: 50px;
        }
        .description {
            width: 780px;
            em {
                display: block;
                font-weight: bold;
                font-size: 30px;
            }
            span {
                display: block;
                line-height: 1.9em;
                font-size: 16px;
            }
        }//.description
    }//.box_head
}//.repair_case

.case_list {
    margin: 60px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .item {
        margin-bottom: 60px;
        padding-top: 24px;
        position: relative;
        width: 530px;
        &:nth-child(2n) {
            margin-left: 60px;
        }
        h4 {
            margin-bottom: 24px;
            font-size: 24px;
        }
        .image {
            margin: 0;
            img {
                width: 100%;
            }
        }//.image
        &::before {
            box-sizing: border-box;
            border-left: solid 24px #003894;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            content: '';
            background: #ddd;
        }
    }//.item
}//.case_list

.btn_recruitment {
    margin: 0 auto;
    width: 950px;
    height: 180px;
    a {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        color: #fff;
        background: #06173c;
        text-decoration: none;
        .body {
            box-sizing: border-box;
            padding: 30px 24px;
            width: 446px;
            p {
                margin-bottom: 40px;
                font-size: 14px;
                em {
                    display: block;
                    font-weight: bold;
                    font-size: 24px;
                }
            }// p
            .to_recruit {
                display: block;
                font-size: 16px;
            }
        }//.body
        .image {
            width: 504px;
        }//.image
    }// a
}//.btn_recruitment
