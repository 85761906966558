//  ::::::::  :::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+        +:+    +:+      +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#++ +#++:++#+       +#++:++#++    +#+       +#++:   +#+        +#++:++#
//        +#+ +#+                    +#+    +#+        +#+    +#+        +#+
// #+#    #+# #+#             #+#    #+#    #+#        #+#    #+#        #+#
//  ########  ###              ########     ###        ###    ########## ##########

// :::    ::: ::::::::::: :::::::: ::::::::::: ::::::::  :::::::::  :::   :::
// :+:    :+:     :+:    :+:    :+:    :+:    :+:    :+: :+:    :+: :+:   :+:
// +:+    +:+     +:+    +:+           +:+    +:+    +:+ +:+    +:+  +:+ +:+
// +#++:++#++     +#+    +#++:++#++    +#+    +#+    +:+ +#++:++#:    +#++:
// +#+    +#+     +#+           +#+    +#+    +#+    +#+ +#+    +#+    +#+
// #+#    #+#     #+#    #+#    #+#    #+#    #+#    #+# #+#    #+#    #+#
// ###    ### ########### ########     ###     ########  ###    ###    ###




.history_list {
    padding-left: 0;
    .sup_list {
        padding-left: 10px;
        //border-left: solid 2px #003894;
        //position: relative;
        &::after {
            //border-right: solid 2px #003894;
            //border-bottom: solid 2px #003894;
            //display: block;
            //position: absolute;
            //bottom: 0;
            //left: -5px;
            //width: 7px;
            //height: 7px;
            //content: '';
            //transform: rotate(45deg);
        }
        .sup_item {
            padding-left: 0;
            //border-top: solid 1px #b8bfce;
            //position: relative;
            &::before {
                //box-sizing: border-box;
                //border: solid 1px #003894;
                //border-radius: 20px;
                //display: block;
                //position: absolute;
                //top: 10px;
                left: -21px;
                //width: 20px;
                //height: 20px;
                //content: '';
                //background: #fff;
            }
            .year {
                //padding-left: 5px;
                padding-top: 6px;
                display: block;
                position: static;
                //top: 7px;
                //left: 0;
                //color: #003894;
                //font-size: 18px;
            }//.year
            &:last-child {
                //border-bottom: solid 1px #b8bfce;
            }
        }//.sup_item
    }//.sup_list
    .sub_list {
        li {
            padding-left: 95px;
            position: relative;
            //padding: 10px 0;
            //border-top: solid 1px #b8bfce;
            font-size: 12px;
            &::after {
                //display: block;
                //clear: both;
                //height: 0;
                //visibility: hidden;
                //content: '.';
            }
            &:first-child {
                //border: 0;
            }
            .category {
                //margin: -3px 5px -3px 0;
                padding: 2px 5px;
                position: absolute;
                top: 12px;
                left: 0;
                width: 80px;
                //border-radius: 14px;
                //display: inline-block;
                font-size: 10px;
                //color: #003894;
                //background: #f2f3f8;
                //text-align: center;
            }//.category
            .photo {
                float: none;
                img {
                    margin: 15px auto 0;
                    width: 100%;
                    //border-radius: 4px;
                }
            }//.photo
        }// li
    }//.sub_list
}//.history_list

.histories {
    .history_list {
        //margin: 0 auto;
        width: 100%;
    }//.history_list
}//.histories
