// :::::::::   ::::::::        :::::::: ::::::::::: :::   ::: :::        ::::::::::
// :+:    :+: :+:    :+:      :+:    :+:    :+:     :+:   :+: :+:        :+:
// +:+    +:+ +:+             +:+           +:+      +:+ +:+  +:+        +:+
// +#++:++#+  +#+             +#++:++#++    +#+       +#++:   +#+        +#++:++#
// +#+        +#+                    +#+    +#+        +#+    +#+        +#+
// #+#        #+#    #+#      #+#    #+#    #+#        #+#    #+#        #+#
// ###         ########        ########     ###        ###    ########## ##########

// ::::::::::: ::::::::  :::::::::       :::::::::     :::      ::::::::  ::::::::::
//     :+:    :+:    :+: :+:    :+:      :+:    :+:  :+: :+:   :+:    :+: :+:
//     +:+    +:+    +:+ +:+    +:+      +:+    +:+ +:+   +:+  +:+        +:+
//     +#+    +#+    +:+ +#++:++#+       +#++:++#+ +#++:++#++: :#:        +#++:++#
//     +#+    +#+    +#+ +#+             +#+       +#+     +#+ +#+   +#+# +#+
//     #+#    #+#    #+# #+#             #+#       #+#     #+# #+#    #+# #+#
//     ###     ########  ###             ###       ###     ###  ########  ##########


// :::    ::: ::::::::::     :::     :::::::::  :::::::::: :::::::::
// :+:    :+: :+:          :+: :+:   :+:    :+: :+:        :+:    :+:
// +:+    +:+ +:+         +:+   +:+  +:+    +:+ +:+        +:+    +:+
// +#++:++#++ +#++:++#   +#++:++#++: +#+    +:+ +#++:++#   +#++:++#:
// +#+    +#+ +#+        +#+     +#+ +#+    +#+ +#+        +#+    +#+
// #+#    #+# #+#        #+#     #+# #+#    #+# #+#        #+#    #+#
// ###    ### ########## ###     ### #########  ########## ###    ###
.global_header {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    width: 100%;
    .header_inner {
        margin: 0 auto;
        display: flex;
        position: relative;
        z-index: 20;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1120px;
    }
}//.global_header
.page-logo {
    padding: 50px 0 0 0;
    width: 128px;
    height: 50px;
    img {
        width: 100%;
        height: 100%;

        filter: invert(100%);
    }
}//.page-logo


.page-head.mod_mv {
    box-sizing: border-box;
    // alternative styles at sub-pages
    margin: 0 auto;
    padding: 0 0 40px;
    border-right: 0;
    border-left: 0;
    width: 100vw;
    min-width: 1280px;
    height: 100vh;
    min-height: 768px !important;
    background: none;
    background-size: cover;
    .mv_fader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        .js_fader {
            width: 100%;
            height: 100%;
            .slick-list,
            .slick-track,
            .slick-slide {
                height: 100% !important;
            }
            .item {
                width: 100%;
                height: 100%;
            }
            .slide_item {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                &.mv01 {
                    background-image: url(../img/frontpage/mv01.jpg);
                }
                &.mv02 {
                    background-image: url(../img/frontpage/mv02.jpg);
                }
                &.mv03 {
                    background-image: url(../img/frontpage/mv03.jpg);
                }
                &.mv04 {
                    background-image: url(../img/frontpage/mv04.jpg);
                }
                &.mv05 {
                    background-image: url(../img/frontpage/mv05.jpg);
                }
            }//.slide_item
        }//.js_fader
    }//.mv_fader
    .inner {
        display: flex;
        position: relative;
        z-index: 15;
        flex-direction: column;
        justify-content: flex-end;
        height: calc(100vh - 420px);
        color: #fff;
        .heading {
            font-size: 48px;
            small {
                display: block;
                font-size: 24px;
            }
        }//.heading
    }//.inner
    .head_lead {
        margin-top: 30px;
        width: 53%;
        line-height: 2.7em;
        color: #fff;
        font-size: 16px;
    }
    &.mod_smallhead {
        margin-bottom: 70px;
        .inner {
            min-height: 90px;
        }
        .heading01 {
            font-size: 24px;
        }
    }
}//.page-head.mod_mv

.mv_menu {
    position: absolute;
    bottom: 0;
    z-index: 20;
    width: 100%;
    overflow: hidden;
    .mv_menu_inner {
        width: auto;
        ul {
            margin: 0 auto;
            padding: 16px 0;
            display: flex;
            position: relative;
            flex-direction: row;
            width: 1120px;
            background-color: #fff;
            &::before {
                display: block;
                position: absolute;
                top: 0;
                right: 1120px;
                width: 200%;
                height: 100%;
                content: '';
                background: #fff;
            }
            li {
                border-left: solid 1px #ddd;
                width: 25%;
                height: 126px;
                line-height: 1.2em;
                font-weight: bold;
                font-size: 12px;
                img {
                    margin-bottom: 10px;
                    border-radius: 4px;
                    width: 100%;
                }
                i.mark_link {
                    margin-right: 4px;
                }
                i.mark_outbound {
                    margin-left: 2px;
                }
                a {
                    box-sizing: border-box;
                    padding: 0 10px;
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: #003894;
                    background-color: #fff;
                    text-decoration: none;
                    .label {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }//a
            }// li
        }// ul
    }//.mv_menu_inner
}//.mv_menu




//  ::::::::   ::::::::  ::::    ::: ::::::::::: :::::::::: ::::    ::: ::::::::::: ::::::::
// :+:    :+: :+:    :+: :+:+:   :+:     :+:     :+:        :+:+:   :+:     :+:    :+:    :+:
// +:+        +:+    +:+ :+:+:+  +:+     +:+     +:+        :+:+:+  +:+     +:+    +:+
// +#+        +#+    +:+ +#+ +:+ +#+     +#+     +#++:++#   +#+ +:+ +#+     +#+    +#++:++#++
// +#+        +#+    +#+ +#+  +#+#+#     +#+     +#+        +#+  +#+#+#     +#+           +#+
// #+#    #+# #+#    #+# #+#   #+#+#     #+#     #+#        #+#   #+#+#     #+#    #+#    #+#
//  ########   ########  ###    ####     ###     ########## ###    ####     ###     ########
.mod_top_content {
    //background: #f2f3f8;
}//.mod_top_content

.top_business {
    padding: 120px 0;
    background: linear-gradient(to bottom, rgba(242,243,248,1) 0%,rgba(255,255,255,1) 100%) !important;
    .heading02 {
        margin-bottom: 60px;
    }
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    li {
        margin-bottom: 10px;
        height: 180px;
        a {
            box-sizing: border-box;
            border-radius: 4px;
            display: block;
            width: 100%;
            height: 100%;
            color: #fff;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            font-weight: bold;
            font-size: 18px;
            text-decoration: none;
            transition-duration: .1s;
            .label {
                box-sizing: border-box;
                padding: 25px;
                display: block;
                width: 100%;
                height: 100%;
                line-height: 1.4em;
                background: linear-gradient(to bottom, rgba(4,24,51,1) 0%,rgba(4,24,51,0) 100%);
                font-weight: normal;
                font-size: 14px;
                em {
                    margin-bottom: 10px;
                    display: block;
                    font-weight: bold;
                    font-size: 18px;
                }
            }//.label
            &:hover {
                background-size: 110%;
            }
        }
        &.lnv01 {
            width: 556px;
            a {
                background-image: url(../img/frontpage/our_business01.jpg);
            }
        }//&.lnv01
        &.lnv02 {
            width: 556px;
            a {
                background-image: url(../img/frontpage/our_business02.jpg);
            }
        }//&.lnv02
        &.lnv03 {
            width: 368px;
            a {
                background-image: url(../img/frontpage/our_business03.jpg);
            }
        }//&.lnv03
        &.lnv04 {
            width: 368px;
            a {
                background-image: url(../img/frontpage/our_business04.jpg);
            }
        }//&.lnv04
        &.lnv05 {
            width: 368px;
            a {
                background-image: url(../img/frontpage/our_business05.jpg);
            }
        }//&.lnv05
        &.lnv06 {
            width: 368px;
            a {
                background-image: url(../img/frontpage/our_business06.jpg);
            }
        }//&.lnv06
        &.lnv07 {
            width: 368px;
            a {
                background-image: url(../img/frontpage/our_business07.jpg);
            }
        }//&.lnv07
        &.lnv08 {
            width: 368px;
            a {
                background-image: url(../img/frontpage/our_business08.jpg);
            }
        }//&.lnv08
    }
}//.top_business

.poroperty_info.mod_top_pinfo {
    margin-bottom: 120px;
    padding: 30px 0;
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &::before,
    &::after {
        box-sizing: border-box;
        border-left: solid 24px #003894;
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background: #ddd;
    }
    &::before {
        top: 0;
    }
    &::after {
        bottom: 0;
    }
    .topinfo_list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: auto;
    }
    .item_topinfo {
        box-sizing: border-box;
        padding-left: 60px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 290px;
        }//.body
        .heading {
            margin-bottom: 10px;
            .cat {
                margin-bottom: 20px;
                display: block;
                color: #ccc;
                font-family: $cgaramond;
            }
            em {
                display: block;
                font-weight: bold;
                font-size: 16px;
            }
            small {
                display: block;
                font-size: 10px;
            }
        }//.heading
        .description {
            line-height: 1.3em;
            font-size: 14px;

            flex-grow: 3;
        }
        .to_detail {
            margin-top: auto;
            width: 100%;

            flex-grow: 1;
            a {
                color: #003894;
                font-weight: bold;
                font-size: 16px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .image {
            width: 184px;
            img {
                width: 100%;
            }
        }
    }//.item_topinfo
}//.poroperty_info.mod_top_pinfo

.top_category {
    margin-bottom: 60px;
    .category_title {
        color: #003894;
        font-family: $cgaramond;
        font-weight: normal;
        font-size: 80px;
    }
    .to_category_top {
        display: flex;
        justify-content: flex-start;
        a {
            border: solid 1px #003894;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 320px;
            height: 64px;
            color: #003894;
            background: #fff;
            font-weight: bold;
            text-decoration: none;
        }//a
    }
    &:nth-of-type(2) {
        .category_title {
            text-align: right;
        }
        .to_category_top {
            justify-content: flex-end;
        }
    }
}//.top_category

.top_news {
    box-sizing: border-box;
    margin: 0 auto 120px;
    padding: 60px 0;
    width: 1280px;
    background: #f7f7f7;
    .inner {
        box-sizing: border-box;
        padding: 0 60px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }
    .heading02 {
        width: 200px;
    }
    .body {
        width: 800px;
        .btn_newslist {
            font-weight: bold;
            font-size: 16px;
            a {
                color: #003894;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .news-list {
        border-bottom: solid 1px #fff;
        li {
            border-top: solid 1px #fff;
            a {
                padding: 16px 0;
            }
        }
    }
}//.top_news

.top_bannerlinks {
    ul {
        text-align: center;
        li {
            margin: 0 5px;
            display: inline-block;
            width: 300px;
            img {
                border-radius: 4px;
                width: 100%;
            }
            a {
                &:hover {
                    opacity: .8;
                }
            }
        }
    }
}//.top_bannerlinks
